import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import { Button, Card, CardBody, CardTitle, Col, Collapse, Input, Row } from 'reactstrap';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { datePickerDisplayFormat, getDate } from 'tsx/libs/dayjs';

import Select from '~components/Select';

import { selectCareWorkerOptions } from '~care-workers/selectors/users';
import { selectClientsOptions } from '~main/selectors/clients/clients';

interface ComponentProps {
  isOpen: boolean;
  weekStart: string;
  setFilters: any;
}

interface Filters {
  client_id: number | null;
  user_id: number | null;
  suburb: string | null;
  date: string | null;
}

const Search: React.FC<ComponentProps> = ({ isOpen, weekStart, setFilters }) => {
  const [formValues, setFormValues] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });

  const workerOptions = useAppSelector(selectCareWorkerOptions);
  const clientOptions = useAppSelector(selectClientsOptions);
  const weekStartFormat = dayjs(weekStart);
  const weekEndFormat = dayjs(weekStart).add(6, 'day');

  const onSearch = () => {
    setFilters(formValues);
  };
  // Handle non-date form changes.
  const handleChange = (name: string, value: string | number | null) => {
    if (!value) {
      setFormValues({ ...formValues, [name]: null });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // Handle Date form change.
  const handleDateChange = (value: Dayjs | null) => {
    if (value === null) {
      setFormValues({ ...formValues, date: null });
    } else {
      setFormValues({ ...formValues, date: getDate(value) });
    }
  };

  return (
    <Collapse isOpen={isOpen}>
      <Card className="m-1">
        <CardBody>
          <CardTitle tag="h6" className="fw-bold">
            Search Unallocated Appointments
          </CardTitle>

          <div className="mb-2">
            <Select
              label="Client:"
              isClearable
              id={`client_id`}
              name={`client_id`}
              options={clientOptions}
              classNamePrefix={'form-input-select'}
              placeholder={'Client Name'}
              onChange={handleChange}
            />
            <Select
              label="Repeat Worker:"
              isClearable
              id={`user_id`}
              name={`user_id`}
              options={workerOptions}
              classNamePrefix={'form-input-select'}
              placeholder={'Repeat Worker'}
              onChange={handleChange}
            />
            <p className="my-1">Suburb:</p>
            <Input
              type="text"
              placeholder="Enter suburb"
              value={formValues.suburb || ''}
              onChange={(e) => handleChange('suburb', e.target.value)}
            />
            <p className="my-1">Date:</p>
            <DesktopDatePicker
              showDaysOutsideCurrentMonth
              format={datePickerDisplayFormat}
              minDate={weekStartFormat}
              maxDate={weekEndFormat}
              name="search-date"
              defaultValue={weekStartFormat}
              value={dayjs(formValues.date)}
              onChange={handleDateChange}
              slotProps={{ field: { clearable: true }, textField: { size: 'small' } }}
            />
          </div>
          <Row className="d-flex-inline">
            <Col>
              <Button size="sm" onClick={onSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};

export default Search;
