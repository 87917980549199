import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { LocationInfo } from '~types/dashboard';

import { LoadingState } from '~libs/reduxUtils';

import { getAll, getHoldPeriods, getLocations, getOne, typePrefix } from '~main/actions/clients';

interface ClientRow {
  id: number;
  full_name: string;
  non_chargeable: boolean;
  description: string;
  preferences: { gender?: number; yes?: Array<number>; no?: Array<number> };
  default_package: any;
}

interface ClientHoldPeriod {
  date_start: string;
  date_end: string | null;
  date_end_2: string;
  id: number;
  company_id: number;
  client_id: number;
  user_id: number;
  is_care_required: boolean;
  notes: string;
  is_disabled: boolean;
  type: number;
}

interface ClientState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<ClientRow>;
  holdPeriods: Array<ClientHoldPeriod> | null;
  locations: Array<LocationInfo>;
  row?: ClientRow;
}

const initialState: ClientState = {
  loading: 'idle',
  error: null,
  holdPeriods: [],
  locations: [],
  rows: [],
};

// Main slice, connecting API actions to redux state.
export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.locations = action.payload.data;
    });
    builder.addCase(getHoldPeriods.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.holdPeriods = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export default clientsSlice.reducer;
