import React from 'react';

import { useStorage } from '~libs/localstorage';

import { User } from '~main/reducers/login';

interface ComponentProps {
  user?: User;
}

const WelcomeMessage: React.FC<ComponentProps> = ({ user }) => {
  const { first_name, surname, company } = user ?? {};

  // eslint-disable-next-line
  const [visited, _] = useStorage('dashboard-visited', false);

  let message = `Welcome ${first_name} ${surname}`;
  if (company) message += ` from ${company.name}`;

  if (!visited) return <div className="m-2 lead">{message}</div>;

  return <></>;
};

export default WelcomeMessage;
