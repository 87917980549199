import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import { getTP1Urls } from '~constants/routes-tp1';

import { pluralize } from '~libs/stringarly';

import Button from '~components/Button';
import Icon, { faExclamationTriangle } from '~components/Icon';

import { getProspectStatistics } from '~dashboard/actions/clientProspects';

import { selectClientProspectStatistics } from '~dashboard/selectors/prospects';
import { selectAuthenticatedUser, selectCompanyDefaults, selectCompanySettings } from '~main/selectors/login';

export const ProspectStatistics: React.FC = () => {
  const dispatch = useAppDispatch();
  const stats = useAppSelector(selectClientProspectStatistics);
  const user = useAppSelector(selectAuthenticatedUser);
  const settings = useAppSelector(selectCompanySettings);
  const companyDefaults = useAppSelector(selectCompanyDefaults);

  const { uncontacted_prospects_count, unassigned_count, current_prospects_count } = stats;

  useEffect(() => {
    dispatch(getProspectStatistics());
  }, [dispatch]);

  const {
    pluralNoun: uncontactedPluralNoun,
    verbHas: uncontactedVerbHas,
    proNoun: uncontactedProNoun,
  } = pluralize(uncontacted_prospects_count, 'client');
  const { pluralNoun: unassignedPluralNoun, verbBe: unassignedVerbBe } = pluralize(unassigned_count, 'client');
  const { pluralNoun: currentProspersPluralNoun, verbBe: currentProspersVerbBe } = pluralize(
    current_prospects_count,
    'client',
  );

  const {
    PROSPECTS: { Uncontacted, StatusByCM },
  } = getTP1Urls(user, settings, companyDefaults);

  const statisticsContent = [
    {
      count: uncontacted_prospects_count,
      description: `${uncontacted_prospects_count} Prospective ${uncontactedPluralNoun} ${uncontactedVerbHas} not been contacted in 7 days or more`,
      buttonLabel: `Click here to view ${uncontactedProNoun}`,
      buttonHref: Uncontacted,
    },
    {
      count: unassigned_count,
      description: `There ${unassignedVerbBe} ${unassigned_count} unassigned Prospective ${unassignedPluralNoun}`,
      buttonLabel: null,
      buttonHref: null,
    },
    {
      count: current_prospects_count,
      description: `There ${currentProspersVerbBe} ${current_prospects_count} Prospective ${currentProspersPluralNoun}`,
      buttonLabel: `Click here to view ${uncontactedProNoun}`,
      buttonHref: StatusByCM,
    },
  ];

  if (!uncontacted_prospects_count && !unassigned_count && !current_prospects_count) {
    return <></>;
  }
  return (
    <div className="prospect-statistics">
      <div className="row statistics-wrapper">
        <div className="card">
          <div className="card-header">Prospect Statistics</div>
          <div className="card-body">
            <div className="card-icon">
              <Icon icon={faExclamationTriangle} size="2x" />
            </div>
            <div className="statistics-content">
              {statisticsContent.map(
                ({ count, description, buttonLabel, buttonHref }, index) =>
                  count > 0 && (
                    <div className="statistic-item" key={index}>
                      {description}
                      {buttonLabel && (
                        <Button href={buttonHref} tag="a">
                          {buttonLabel}
                        </Button>
                      )}
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
