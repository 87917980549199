import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

// Selectors, performing common selection tasks for this slice.
export const selectAppointmentCancellationReasons = ({ appointments }: RootState) =>
  appointments.cancellationReasons.rows;
export const selectLookupAppointmentCancellationReasons = createSelector(selectAppointmentCancellationReasons, (rows) =>
  rows.map(({ name, ...row }) => ({
    id: name,
    name: `${row.package !== undefined ? `${row.package.toString().toUpperCase()} - ` : ''}${name}`,
  })),
);
