import { buildSlice, LoadingState } from '~libs/reduxUtils';

import { getAll, getSnapshot, typePrefix } from '~dashboard/actions/snapshots';

interface Snapshot {
  value: number | null;
  loading: boolean;
  error: string | null;
}

interface SnapshotRow {
  id: string;
  [key: string]: any;
}

interface State {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<SnapshotRow>;
  row?: SnapshotRow;
  snapshotsData: Record<string, Snapshot>;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: '' },
  snapshotsData: {},
};

const slice = buildSlice<State, SnapshotRow>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getSnapshot.pending, (state, action) => {
      const id = action.meta.arg;
      if (!state.snapshotsData[id]) {
        state.snapshotsData[id] = { value: null, loading: true, error: null };
      } else {
        state.snapshotsData[id].loading = true;
        state.snapshotsData[id].error = null;
      }
    });
    builder.addCase(getSnapshot.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { id, value } = action.payload.data;
      state.snapshotsData[id] = { value, loading: false, error: null };
    });
    builder.addCase(getSnapshot.rejected, (state, action) => {
      const id = action.meta.arg;
      state.snapshotsData[id] = {
        value: null,
        loading: false,
        error: action.error.message || 'Snapshot loading failed',
      };
    });
  },
});

export const { clear, revertLayout, setLayout } = slice.actions;
export default slice.reducer;
