import React from 'react';
import { useAppSelector } from 'hooks';

import { HeaderCellProps, Worker } from '~weekly-planner/lib/common';

import { BiddingCard, TotalCard, WorkerCard } from '~weekly-planner/components/Card';
import { WorkerAlerts, WorkerQualifications } from '~weekly-planner/components/Common';

import { selectFocusedAppointment, selectOptions } from '~weekly-planner/selectors';

const WorkerCell: React.FC<HeaderCellProps> = ({ data, date, style }) => {
  const worker = data as Worker;
  const { workerQualifications, totalSimple, totalAppointments, totalPredicted, totalDistance } =
    useAppSelector(selectOptions);
  const focused = useAppSelector(selectFocusedAppointment);
  const { workerAwardAlerts } = useAppSelector(selectOptions);

  const { isBiddingView } = focused || {};
  const events = Object.values(worker.events).flat(1);

  if (!date) return <></>;

  const renderTotals = () => {
    if (events.length === 0) return <></>;
    return (
      <div className="total-row">
        {totalSimple && <TotalCard type="simple" events={events} col={2} direction="right" />}
        {totalAppointments && <TotalCard type="appointments" events={events} col={2} direction="right" />}
        {totalPredicted && <TotalCard type="predicted" events={events} col={2} direction="right" />}
        {totalDistance && <TotalCard type="distance" events={events} col={2} direction="right" />}
      </div>
    );
  };

  const { id, qualifications } = worker;
  return (
    <div className="worker-cell-parent">
      <div className="worker-cell" style={style}>
        <div className="d-flex flex-column justify-content-between w-100">
          <div>
            <div className="px-2 mb-2">
              <WorkerCard worker={worker} date={date} />
            </div>
            {workerQualifications && <WorkerQualifications qualifications={qualifications} isCondensed />}
          </div>
          <div>
            {workerAwardAlerts && <WorkerAlerts worker={worker} events={events} />}
            <div>{renderTotals()}</div>
          </div>
        </div>
        {isBiddingView && <BiddingCard workerId={id} />}
      </div>
    </div>
  );
};

export default WorkerCell;
