import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import ContainerHeader from '~components/ContainerHeader';

import CareWorkerMap from './CareWorkerMap';

const MapWrapper: React.FC = () => {
  const mapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '';

  return (
    <APIProvider apiKey={mapsKey} onLoad={() => console.log('Maps API has loaded.')}>
      <ContainerHeader>Care Workers Map - Current Location</ContainerHeader>
      <CareWorkerMap />
    </APIProvider>
  );
};

export default MapWrapper;
