import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Alert, Collapse } from 'reactstrap';
import { InputProps } from 'tsx/components/FormFields';
import Icon, { faTriangleExclamation } from 'tsx/components/Icon';

import { selectUserByID } from '~care-workers/selectors/users';

function FormInputWorkerQualificationCheck({ value = {}, id }: InputProps) {
  const { qualification_level: required_qualifications, user_id } = value;
  const user = useAppSelector((state) => selectUserByID(state, parseInt(user_id)));
  const [isQualified, setIsQualified] = useState(true);

  useEffect(() => {
    // If user and date selected, check for leave requests in relevant period
    let qualified: boolean[] = [];
    if (required_qualifications instanceof Array) {
      qualified =
        required_qualifications
          ?.map((required_id: string) => !user?.qualifications?.includes(required_id))
          .filter(Boolean) ?? [];
    }

    // If all qualifications met, set qualified
    setIsQualified((qualified && qualified?.length === 0) ?? false);
  }, [required_qualifications, user]);

  const isOpen = !isQualified && !isNaN(parseInt(user_id));
  return (
    <Collapse isOpen={isOpen}>
      <Alert
        color="danger"
        className="d-flex align-items-center justify-content-center"
        data-id={id}
        data-cy={`${id}-alert`}
      >
        <Icon className="me-2" size="3x" icon={faTriangleExclamation} />
        <div className="fw-bold">This worker is not qualified for this appointment</div>
      </Alert>
    </Collapse>
  );
}

export default FormInputWorkerQualificationCheck;
