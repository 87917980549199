import React from 'react';
import { useAppSelector } from 'hooks';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';

import { Row } from '~libs/reduxUtils';

import { InputProps } from '~components/FormFields';

type FieldOptions = Array<{ id: string | number; name: string }> | Row[];

interface ComponentProps extends InputProps {
  isFlexible?: boolean;
}

const Radio: React.FC<ComponentProps> = ({
  id,
  value,
  disabled = false,
  options,
  selectorOptions,
  onChange,
  setFieldValue,
  isFlexible = true,
}) => {
  let fieldOptions: FieldOptions = [];
  if (selectorOptions) fieldOptions = useAppSelector(selectorOptions);
  else if (options) fieldOptions = options;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFieldValue && setFieldValue(id, value);
    onChange && onChange(id, value);
  };

  const radioOptions = fieldOptions.map(({ id: optionId, name }, index) => (
    <Label key={index} check className="me-3">
      <Input
        type="radio"
        name={`${id}-${name}`}
        value={optionId}
        checked={value === optionId.toString()}
        onChange={handleChange}
        disabled={disabled}
        className="me-1"
      />
      {name}
    </Label>
  ));

  const className = classNames('d-flex', {
    'flex-wrap': isFlexible,
    'align-items-center': isFlexible,
    'flex-column': !isFlexible,
  });

  return (
    <div className={className} data-cy={`${id}-radio`}>
      {radioOptions}
    </div>
  );
};

export default Radio;
