import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SortingState } from '@tanstack/react-table';
import Button from 'tsx/components/Button';
import ContainerHeader from 'tsx/components/ContainerHeader';
import Icon, { faEnvelope, faPlus, faUsers } from 'tsx/components/Icon';
import TableView from 'tsx/components/TableView';
import columns from 'tsx/features/care-workers/forms/userList';
import { setTitle } from 'tsx/features/main/actions/login';

import { getAll } from '~care-workers/actions';

import { clearUsers } from '~care-workers/reducers/users';

import { selectUsers, selectUsersLoading, selectUsersTotal } from '~care-workers/selectors/users';

const Users: React.FC = () => {
  const dispatch = useAppDispatch();
  const [displayDeleted, setDisplayDeleted] = useState<boolean>(false);

  const initialSortState: SortingState = [
    {
      id: 'date_last_login',
      desc: true,
    },
  ];

  const [params, setParams] = useState({
    disabled: false,
    attributes: columns.map((column) => column.id),
    sort: initialSortState.map(({ id, desc }) => `${id}.${desc ? 'desc' : 'asc'}`),
    limit: 100,
  });

  useEffect(() => {
    setTitle(`Users`);
    load();

    // clean component on unmount
    return () => {
      dispatch(clearUsers());
    };
  }, []);

  const load = async (loadParams: { [key: string]: any } = {}) => {
    const newParams = {
      ...params,
      ...loadParams,
    };
    dispatch(getAll(newParams));
    setParams(newParams);
  };

  const toggleDisplayDeleted = async () => {
    await load({ disabled: !displayDeleted });
    setDisplayDeleted(!displayDeleted);
  };

  const users = useAppSelector(selectUsers);
  const usersTotal = useAppSelector(selectUsersTotal);
  const usersLoading = useAppSelector(selectUsersLoading);
  const isLoading = usersLoading === 'pending';

  return (
    <div className="p-2">
      <ContainerHeader icon={faUsers} iconSize="1x" className="info">
        User Admin
      </ContainerHeader>
      <div className="nav d-flex flex-row bg-light p-2 mt-2 mb-2">
        <Button
          disabled
          size="sm"
          color="success"
          className="ms-2"
          onClick={() => console.log('Placeholder for nav to new user')}
        >
          <Icon className="me-2" icon={faPlus} />
          Add New Care Worker
        </Button>
        <Button size="sm" color="warning" className="ms-2" onClick={() => toggleDisplayDeleted()}>
          View {displayDeleted ? 'Active' : 'Deleted'} Users
        </Button>
        <Button disabled size="sm" className="ms-2" onClick={() => console.log('Placeholder for mail merge')}>
          <Icon className="me-2" icon={faEnvelope} />
          Mail Merge
        </Button>
      </div>
      <TableView
        id="users"
        data={users as []}
        columns={columns}
        isLoading={isLoading}
        emptyIcon={faUsers}
        emptyMessage={'No user admins found'}
        allowSort={true}
        allowPagination={true}
        onLoadMore={() => load({ limit: params.limit + 50 })}
        showCount={true}
        totalCount={usersTotal}
      />
    </div>
  );
};

export default Users;
