import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~main/actions/workSites';

const initialState: CommonState<Row> = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

const slice = buildSlice<CommonState<Row>>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
  },
});

export default slice.reducer;
