import React from 'react';
import { useAppSelector } from 'hooks';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';

import { Row } from '~libs/reduxUtils';

import { InputProps } from '~components/FormFields';

type FieldOptions = Array<{ id: string | number; name: string }> | Row[];

interface ComponentProps extends InputProps {
  highlightOptions?: Array<number>;
  size?: 'sm' | 'lg';
  isFlexible?: boolean;
}

const MultiCheckbox: React.FC<ComponentProps> = ({
  id,
  value = [],
  disabled,
  colSize,
  options,
  selectorOptions,
  highlightOptions = [],
  onChange,
  size = 'lg',
  isFlexible = true,
}) => {
  const setChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const stringName = name.toString();

    const stringValue = Array.isArray(value) ? value.map((item: any) => item.toString()) : [];

    const newValue = checked ? [...stringValue, stringName] : stringValue.filter((item: any) => item !== stringName);

    onChange && onChange(id, newValue);
  };

  // Prioritise selectorOptions over options when building fieldOptions
  let fieldOptions: FieldOptions = [];
  if (selectorOptions) fieldOptions = useAppSelector(selectorOptions);
  else if (options) fieldOptions = options;

  const sizeMultiplier = size === 'sm' ? 2 : 1;

  // Determine number of checkboxes based on colSize
  let groupColSize: number;
  switch (colSize) {
    case 1:
    case 2:
    case 3: {
      groupColSize = 12;
      break;
    }
    case 4:
    case 5:
    case 6: {
      groupColSize = 6 * sizeMultiplier;
      break;
    }
    default: {
      groupColSize = 3 * sizeMultiplier;
      break;
    }
  }

  // Ensure all values are strings for consistency when checking the checkboxes
  const values = value instanceof Array ? value.map((optionId: string | number) => optionId.toString()) : [];

  // Iterate and build fields based on options
  const fields = fieldOptions.map(({ id, name }, index) => {
    const reference = id.toString();
    const className = classNames('d-flex', {
      'm-1 p-1': isFlexible,
      'bg-info rounded': highlightOptions.includes(parseInt(reference)),
    });

    return (
      <div key={index} className={`col-${groupColSize}`}>
        <div key={index} className={className}>
          <Input
            type="checkbox"
            name={reference}
            id={reference}
            checked={values.includes(reference)}
            onChange={setChange}
            disabled={disabled}
          />
          <Label for={reference} className="ms-2 mb-0">
            {name}
          </Label>
        </div>
      </div>
    );
  });

  const className = classNames('d-flex', {
    'flex-wrap': isFlexible,
    'flex-column': !isFlexible,
  });

  return (
    <div className={className} data-cy={`${id}-multi-checkbox`}>
      {fields}
    </div>
  );
};

export default MultiCheckbox;
