import React from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { InputProps } from 'tsx/components/FormFields';
import { DateInput, ReadOnly } from 'tsx/components/FormFields/Inputs';
import { catchAllEndDate, formatDateTimeValue } from 'tsx/libs/dayjs';

function FormInputRepeatDates({ value, disabled, onChange }: InputProps) {
  let { repeat_start = '', repeat_end = '' } = value;

  repeat_start = formatDateTimeValue('date', repeat_start);
  repeat_end = formatDateTimeValue('date', repeat_end);

  if (repeat_end && repeat_end !== '') {
    if (dayjs(catchAllEndDate) < dayjs(repeat_end)) {
      repeat_end = '';
    }
  }

  const rowClassName = 'mb-1 pe-2 align-items-center';
  return (
    <>
      <Row className={rowClassName}>
        <Col>
          <DateInput
            type="date"
            name="repeat_start"
            id="repeat_start"
            value={repeat_start}
            disabled={disabled}
            onChange={onChange}
            dataCy={`repeat_start`}
          />
        </Col>
        <Col>
          {!disabled && (
            <ReadOnly
              type="readonly"
              name="readonly_repeat_start"
              id="readonly_repeat_start"
              value="Mandatory"
              className="ps-2 text-info"
              dataCy="repeat_start"
            />
          )}
        </Col>
      </Row>
      <Row className={rowClassName}>
        <Col>
          <DateInput
            type="date"
            name="repeat_end"
            id="repeat_end"
            value={repeat_end}
            disabled={disabled}
            onChange={onChange}
            dataCy="repeat_end"
          />
        </Col>
        <Col>
          {!disabled && (
            <ReadOnly
              type="readonly"
              name="readonly_repeat_end"
              id="readonly_repeat_end"
              value="Optional"
              className="ps-2 text-info"
              dataCy="repeat_end"
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default FormInputRepeatDates;
