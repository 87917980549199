import React from 'react';

interface ComponentProps {
  message?: string | null;
  className?: string;
  colour?: string;
  dataCy?: string;
}

const ErrorResponse: React.FC<ComponentProps> = ({ message = '', className, colour = 'danger', dataCy = '' }) => {
  const show = message !== '' && message !== null;

  return (
    <>
      {show && (
        <div
          data-cy={dataCy}
          className={`d-flex justify-content-center m-2 lead text-${colour} ${className !== undefined ? className : ''}`}
        >
          {message}
        </div>
      )}
    </>
  );
};

export default ErrorResponse;
