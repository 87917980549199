import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import { InputProps } from '~components/FormFields';
import Autocomplete from '~components/FormFields/Search/Autocomplete';

import { getAddress, getDetails } from '~main/actions/address/addressLookup';

import { clearAddressFields, Prediction, updateAddressFields } from '~main/reducers/address/addressLookup';

import { selectAddressLookupResults } from '~main/selectors/address/addressLookup';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const AddressLookup: React.FC<InputProps> = ({ id, placeholder, className }) => {
  const dispatch = useAppDispatch();
  const suggestions = useAppSelector(selectAddressLookupResults);
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>('');
  const selectedPlaceIdRef = useRef(selectedPlaceId);

  useEffect(() => {
    selectedPlaceIdRef.current = selectedPlaceId;

    return () => {
      dispatch(clearAddressFields());
    };
  }, [selectedPlaceId]);

  const onFetchSuggestions = useCallback(
    async (input: string) => {
      if (selectedPlaceIdRef.current) {
        setSelectedPlaceId('');
      }

      if (input.length > 3 && !selectedPlaceIdRef.current) {
        const { payload } = await dispatch(getAddress({ input: input }));

        if (payload && payload.data) {
          return payload.data.map((item: { description: string }) => item.description);
        }
      }
      return [];
    },
    [dispatch],
  );

  // only triggered when a select list option is clicked
  const handlePlaceSelected = async (_id: any, value: any) => {
    if (selectedPlaceIdRef.current) {
      setSelectedPlaceId('');
    }

    const location: Prediction | undefined = suggestions
      .filter((place) => place.description === value)
      .map((item) => ({
        place_id: item.place_id,
      }))[0];

    if (location?.place_id) {
      setSelectedPlaceId(location.place_id);
      const { payload } = await dispatch(getDetails({ place_id: location.place_id }));

      if (payload && payload.data) {
        const addrComps: AddressComponent[] = payload.data.address_components;
        const getAddressComponent = (type: string): string =>
          addrComps.find((component) => component.types.includes(type))?.long_name || '';

        const streetNumber = getAddressComponent('street_number');
        const route = getAddressComponent('route');
        const locality = getAddressComponent('locality');
        const postalCode = getAddressComponent('postal_code');
        const state =
          addrComps.find((component) => component.types.includes('administrative_area_level_1'))?.short_name || '';
        dispatch(
          updateAddressFields({
            address: `${streetNumber} ${route}`.trim(),
            suburb: locality,
            postcode: postalCode,
            state: state,
          }),
        );
      }
    }
  };

  return (
    <Autocomplete
      id={id}
      name={id}
      type="custom"
      value={selectedPlaceId}
      placeholder={placeholder}
      className={className}
      onChange={handlePlaceSelected}
      onFetchSuggestions={onFetchSuggestions}
    />
  );
};

export default AddressLookup;
