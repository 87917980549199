import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { LoadingState } from '~libs/reduxUtils';

import { getAddress, getDetails, typePrefix } from '~main/actions/address/addressLookup';

export interface Prediction {
  id?: string;
  place_id?: string;
  description?: string;
}

interface AddressFields {
  address: string;
  suburb: string;
  postcode: string;
  state: any;
}

interface AddressLookupState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<Prediction> | [];
  row: Prediction | any;
  addressFields: AddressFields;
}

const initialState: AddressLookupState = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
  addressFields: {
    address: '',
    suburb: '',
    postcode: '',
    state: '',
  },
};

export const addressLookupSlice = createSlice({
  name: 'addressLookup',
  initialState,
  reducers: {
    updateAddressFields(state, action) {
      state.addressFields = {
        ...state.addressFields,
        ...action.payload,
      };
    },
    clearAddressFields(state) {
      state.row = initialState.row;
      state.addressFields = initialState.addressFields;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddress.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getDetails.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const { clearAddressFields, updateAddressFields } = addressLookupSlice.actions;

export default addressLookupSlice.reducer;
