import axios from 'axios';

import { buildSlice, CommonState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~clients/actions/budgets';

// RELOCATE LATER
interface Budget {
  id: string; // UUID
  client?: { id: number; full_name?: string };
  start_date?: string;
  end_date?: string;
  sah_level?: number;
}

interface State extends CommonState<Budget> {
  total: number;
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  total: 0,
};

const slice = buildSlice<State, Budget>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const rows = action.payload.data;
      state.rows = rows;
      state.total = Array.isArray(rows) ? rows.length : 0;
    });
  },
});

/**
 * STUBBING BUDGETS API FOR NOW
 */
const stub: Budget[] = [
  {
    id: 'abc',
    client: { id: 1, full_name: 'Hugh Jass' },
    start_date: '01-01-2025',
    end_date: '12-31-2025',
    sah_level: 1,
  },
  {
    id: 'xyz',
    client: { id: 2, full_name: 'Ben Dover' },
    start_date: '02-01-2025',
    end_date: '12-31-2025',
    sah_level: 2,
  },
  {
    id: 'asdf',
    client: { id: 3, full_name: 'Dixie Normous' },
    start_date: '03-01-2025',
    end_date: '12-31-2025',
    sah_level: 3,
  },
];

axios.interceptors.response.use((response) => {
  if (response.config.url && response.config.url.includes('/clients/budgets')) {
    response.data = { success: true, message: 'STUB DATA', data: stub };
  }
  return response;
});

// default actions
export const { clear, updateParams } = slice.actions;
export default slice.reducer;
