import { ColumnDef } from '@tanstack/react-table';
import { formatDateString } from 'tsx/libs/dayjs';

const columns: ColumnDef<any>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    cell: (info) => info.getValue(),
    enableHiding: true,
  },
  {
    id: 'client.full_name',
    accessorKey: 'client.full_name',
    header: 'Client Name',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'start_date',
    accessorKey: 'start_date',
    header: 'Start Date',
    cell: (info) => formatDateString(info.getValue()),
    sortUndefined: 'last',
    sortingFn: 'datetime',
  },
  {
    id: 'end_date',
    accessorKey: 'end_date',
    header: 'End Date',
    cell: (info) => formatDateString(info.getValue()),
    sortUndefined: 'last',
    sortingFn: 'datetime',
  },
  {
    id: 'sah_level',
    accessorKey: 'sah_level',
    header: 'Status',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
];

export default columns;
