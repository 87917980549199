import React from 'react';
import { useAppSelector } from 'hooks';
import ReactSelect, { StylesConfig } from 'react-select';
import { RootState } from 'store';

import { Row } from '~libs/reduxUtils';

interface ComponentProps {
  type?: string;
  id: string;
  value?: any;
  label?: string;
  name: any;
  className?: string;
  classNamePrefix?: string;
  link?: string;
  colSize?: number;
  disabled?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  options?: Array<{ value: string | number | null; label: string | null }>;
  initialOption?: { value: string | number | null; label: string | null };
  selectorOptions?: (state: RootState) => Row[];
  selectorActions?: (params?: any) => any;
  selectorParams?: (values: any) => any;
  onChange?: (name: string, value: any, label: string) => any;
  ignorePlaceholder?: boolean;
  styles?: any;
  getOptionStyle?: (option: Option) => React.CSSProperties;
}

export interface Option {
  value: string | number | null;
  label: string | null;
}

const Select: React.FC<ComponentProps> = ({
  id,
  value,
  label,
  placeholder,
  ignorePlaceholder = false,
  className,
  disabled = false,
  isClearable = false,
  options,
  selectorOptions,
  onChange,
  styles,
  getOptionStyle,
}) => {
  let selectOptions: Option[] = [];
  if (options) {
    selectOptions = options.map(({ value, label, ...props }) => ({ value, label, ...props }));
  } else if (selectorOptions) {
    const rows = useAppSelector(selectorOptions);
    selectOptions = rows.map(({ value, label, ...props }) => ({ value, label, ...props }));
  }

  const customStyles: StylesConfig<Option> = {
    option: (provided, state) => ({
      ...provided,
      ...(getOptionStyle ? getOptionStyle(state.data as Option) : {}),
    }),
    ...styles,
  };

  return (
    <>
      {label && <p className="my-1">{label}</p>}
      <ReactSelect
        id={id}
        name={id}
        value={selectOptions.find((option) => option.value === value)}
        isDisabled={disabled}
        isClearable={isClearable}
        options={selectOptions}
        className={className}
        classNamePrefix={'form-input-select'}
        onChange={(option: any) => onChange && onChange(id, option?.value, option?.label)}
        placeholder={!ignorePlaceholder ? (placeholder ?? '-') : undefined}
        styles={customStyles}
      />
    </>
  );
};

export default Select;
