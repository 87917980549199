import { apiWorkSites } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'companies/worksites';

const config = [{ actionType: 'getAll', apiPath: apiWorkSites, method: get, actionParams: { limit: 0 } }];

export const { getAll } = createThunks(typePrefix, config);
