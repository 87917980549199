import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Icon, { faXmark, IconProp, SizeProp } from '~components/Icon';

type ComponentProps = {
  className?: string;
  color?: string;
  textColor?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  title?: string;
  messages?: string[] | ReactNode[];
  onClear?: () => void;
  dataCy?: string;
};

const ContainerBanner: React.FC<ComponentProps> = ({
  className,
  color,
  textColor,
  icon,
  iconSize = '1x',
  title,
  messages = [],
  onClear,
  dataCy = '',
}) => {
  const body = messages.map((message, index) => <div key={index}>{message}</div>);

  const bannerClass = classNames('p-3', {
    [`${className}`]: className !== undefined,
    [`bg-${color}`]: color !== undefined,
    [`text-${textColor}`]: textColor !== undefined,
  });

  return (
    <div className={bannerClass} data-cy={dataCy}>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center align-self-center">
          {icon && <Icon size={iconSize} icon={icon} className="me-3" />}
          <div>
            <div className="lead mb-2">{title}</div>
            <div>{body}</div>
          </div>
        </div>
        {onClear && <Icon size={iconSize} icon={faXmark} className="me-3" onClick={onClear} />}
      </div>
    </div>
  );
};

export default ContainerBanner;
