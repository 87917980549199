import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectClients = ({ main }: RootState) => main.clients.rows;
export const selectLocations = ({ main }: RootState) => main.clients.locations;
export const selectClientByID = createSelector([selectClients, (_: RootState, id?: number) => id], (rows, id) =>
  rows.find(({ id: rowId }) => id === rowId),
);
export const selectLookupClients = createSelector(selectClients, (rows) =>
  rows.map(({ id, full_name, non_chargeable }) => ({
    id,
    name: `${full_name}${non_chargeable ? ` - Non chargeable` : ''}`,
  })),
);
export const selectClientsOptions = createSelector(selectClients, (rows) =>
  rows.map(({ id, full_name }) => ({
    value: id,
    label: `${full_name}`,
  })),
);
