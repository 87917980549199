import React from 'react';

import Icon, {
  faBan,
  faBusinessTime,
  faCalculator,
  faCar,
  faCommentDollar,
  faFileInvoiceDollar,
  faLock,
  faPersonWalkingArrowRight,
  faRoute,
  faUserNurse,
} from '~components/Icon';

const Legend: React.FC = () => {
  const icons = [
    {
      icon: (
        <span className="fa-layers fa-fw">
          <Icon icon={faCar} size="xs" />
          <Icon icon={faBan} size="lg" />
        </span>
      ),
      title: 'Travel Exempt',
    },

    {
      icon: <Icon icon={faCommentDollar} />,
      title: 'Active Cancellation',
    },
    { icon: <Icon icon={faPersonWalkingArrowRight} />, title: 'Leave Entry' },
    { icon: <Icon icon={faFileInvoiceDollar} />, title: 'Shift Pay' },
    { icon: <Icon icon={faLock} />, title: 'Fixed Pay' },
    { icon: <Icon icon={faCalculator} />, title: 'All Totals' },
    { icon: <Icon icon={faUserNurse} />, title: 'Predicted Shift' },

    { icon: <Icon icon={faBusinessTime} />, title: 'Appointment Totals' },

    { icon: <Icon icon={faRoute} />, title: 'Total Distance' },
  ];

  return (
    <div className="legend">
      <div className="fw-bold dropdown-header legend-title">Legend</div>
      <div className="dropdown-divider" />
      {icons.map(({ icon, title }) => (
        <div key={title} className="legend-item dropdown-item">
          <span className="title">{title}</span>
          <span className="icon">{icon}</span>
        </div>
      ))}
    </div>
  );
};
export default Legend;
