import { SearchProps } from '~components/FormFields/Search/props';

import { selectLookupUsers } from '~care-workers/selectors/users';
import { selectLookupClients } from '~main/selectors/clients/clients';

export const mapSearchFields: SearchProps = {
  worker_name: {
    defaultShow: true,
    type: 'multi-lookup',
    field: 'users',
    placeholder: 'All',
    caption: 'Care Workers',
    selectorOptions: selectLookupUsers,
  },
  client_name: {
    defaultShow: true,
    type: 'multi-lookup',
    field: 'clients',
    placeholder: 'All',
    caption: 'Clients',
    selectorOptions: selectLookupClients,
  },
  date: {
    defaultShow: true,
    type: 'date',
    field: 'date',
    placeholder: 'Search by date',
    caption: 'Date',
  },
  time: {
    defaultShow: true,
    type: 'time',
    field: 'time',
    placeholder: 'Search by time',
    caption: 'Time',
  },
};

export default mapSearchFields;
