import React from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';

import Button from '~components/Button';
import { InputProps } from '~components/FormFields';
import * as FormInput from '~components/FormFields/Inputs';

const FormInputDate: React.FC<InputProps> = ({ id, value, placeholder, disabled, onChange }) => {
  return (
    <Row className="align-items-center">
      <Col>
        <FormInput.DateInput
          type="date"
          className="pe-2"
          id={id}
          name={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
      <Col sm={2}>
        {!disabled && (
          <Button
            size="sm"
            color="success"
            onClick={() => onChange && onChange(id, dayjs().format(dateInputFieldFormat))}
            dataCy={`${id}-today-button`}
          >
            Today
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default FormInputDate;
