import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import Spinner from '~components/Spinner';

interface ComponentProps {
  label: string;
  type?: string;
  value?: number | null;
  loading?: boolean | null;
  error?: string | null;
}

const Snapshot: React.FC<ComponentProps> = ({ label, value, loading, error }) => {
  return (
    <div className="snapshot-container">
      <Card>
        <CardHeader>{label || 'Snapshot'}</CardHeader>
        <CardBody>
          {loading && <Spinner size={10} loading={loading} className="m-2" />}
          {!loading && error && <div className="text-danger">Error: {error}</div>}
          {!loading && !error && <div>{value}</div>}
        </CardBody>
      </Card>
    </div>
  );
};

export default Snapshot;
