import { deriveClientAddress, deriveClientPackageName } from '~appointments/lib/derive';
import { onChangeClient, onChangeUserOrDate } from '~appointments/lib/onchange';
import { validateRepeatDates, validateRepeatFrequency } from '~appointments/lib/validate';

import { FieldProps } from '~components/FormFields';
import FormInputDate from '~appointments/components/FormInputDate';
import FormInputRepeatDates from '~appointments/components/FormInputRepeatDates';
import FormInputRepeatFrequency from '~appointments/components/FormInputRepeatFrequency';

import { getAll as getClients } from '~main/actions/clients';

import { selectLookupClients } from '~main/selectors/clients/clients';

import { commonFields } from './common';

const newFields: FieldProps = {
  ...commonFields,
  client_name: {
    type: 'select',
    field: 'client_id',
    caption: 'Client / Facility',
    deriveDescription: deriveClientAddress,
    onChange: onChangeClient,
    className: 'text-left',
    required: true,
    colSize: 4,
    order: 200,
    selectorOptions: selectLookupClients,
    selectorActions: getClients,
    manualReload: true,
  },
  client_package_name: {
    type: 'readonly',
    caption: 'Default Package',
    deriveValue: deriveClientPackageName,
    className: 'text-left',
    order: 220,
  },
};

const singleSpecific: FieldProps = {
  date: {
    type: 'custom',
    component: FormInputDate,
    caption: 'Appointment Date',
    required: true,
    colSize: 4,
    order: 120,
  },
};

const repeatSpecific: FieldProps = {
  repeat_dates: {
    type: 'custom',
    component: FormInputRepeatDates,
    dependencies: ['repeat', 'repeat_start', 'repeat_end'],
    validateOnSave: validateRepeatDates,
    onChange: onChangeUserOrDate,
    caption: 'Repeat Dates',
    labelCaption: 'Best to select Monday as the start date, in case of future day changes',
    colSize: 4,
    order: 120,
  },
  repeat_frequency: {
    type: 'custom',
    component: FormInputRepeatFrequency,
    dependencies: ['repeat', 'repeat_weekday', 'repeat_week_count', 'skip_on_public_holidays'],
    validate: validateRepeatFrequency,
    caption: 'Frequency',
    labelCaption: "You can only select 1 week day to repeat. Once added, you can select 'Create similiar repeat'",
    colSize: 4,
    order: 130,
  },
};

const appointmentNewFields: FieldProps = {
  ...newFields,
  ...singleSpecific,
};

const repeatAppointmentNewFields: FieldProps = {
  ...newFields,
  ...repeatSpecific,
};

export { appointmentNewFields, repeatAppointmentNewFields };
