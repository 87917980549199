import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

import WizardForm from '~components/WizardForm';

import addAppointmentWizardFields from '~weekly-planner/forms/appointment/addAppointmentWizard';

import { selectErrorResponse } from '~appointments/selectors/appointments';

/**
 * Props for the AppointmentWizard component.
 * @property showAppointmentWizard - Controls the visibility of the modal.
 * @property setShowAppointmentWizard - Function to toggle the visibility of the modal.
 * @property appointmentDefaults - Default values for the appointment form, including date and user ID.
 * @property onAddAppointment - Async function to handle the submission of a new appointment.
 */
interface ComponentProps {
  showAppointmentWizard: boolean;
  setShowAppointmentWizard: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentDefaults: { [key: string]: any };
  onAddAppointment: (values: any) => Promise<boolean>;
}

/**
 * AppointmentWizard component for managing the creation of a new appointment.
 *
 * @param props
 * @returns The rendered AppointmentWizard component.
 */
export const AppointmentWizard: React.FC<ComponentProps> = ({
  showAppointmentWizard,
  setShowAppointmentWizard,
  appointmentDefaults,
  onAddAppointment,
}) => {
  return (
    <Modal
      isOpen={showAppointmentWizard}
      onClosed={() => setShowAppointmentWizard(false)}
      size="xl"
      unmountOnClose
      centered
      contentClassName="wizard-modal"
    >
      <ModalHeader toggle={() => setShowAppointmentWizard(!showAppointmentWizard)} className="header">
        Add New Appointment
      </ModalHeader>
      <WizardForm
        steps={addAppointmentWizardFields}
        initialValues={appointmentDefaults}
        errorSelector={selectErrorResponse}
        onComplete={onAddAppointment}
      />
    </Modal>
  );
};

export default AppointmentWizard;
