import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import dayjs from 'dayjs';

import { weeklyPlannerDayDisplayFormat } from '~libs/dayjs';
import { Params } from '~libs/reduxUtils';

import Button from '~components/Button';
import ErrorResponse from '~components/ErrorResponse';
import Icon, { faChevronRight } from '~components/Icon';
import ModalCard from '~components/ModalCard';

import { bulkUpdate, getAll as getAllAppointments } from '~appointments/actions/appointments';

import { clear } from '~weekly-planner/reducers/unsaved';

import { selectAppointments, selectErrorResponse } from '~appointments/selectors/appointments';
import { selectLookupUsers } from '~care-workers/selectors/users';
import { selectAllChanges } from '~weekly-planner/selectors/unsaved';

interface ComponentProps {
  isOpen?: boolean;
  onClose: () => void;
}

interface Changelog {
  id: number;
  isNew: boolean;
  changes: Changes;
  builtChanges?: BuiltChange[];
}

interface Changes {
  start_time?: string;
  end_time?: string;
  date?: string;
  user_id?: number;
}

interface BuiltChange {
  label: string;
  from: string | null;
  to: string | null;
  updated: boolean;
}

const Changes: React.FC<ComponentProps> = ({ isOpen = false, onClose }: ComponentProps) => {
  const dispatch = useAppDispatch();
  const changes = useAppSelector(selectAllChanges);
  const errorMessage = useAppSelector(selectErrorResponse);
  const appointments = useAppSelector(selectAppointments);
  const users = useAppSelector(selectLookupUsers);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const hasError = errorMessage ? errorMessage.length > 0 : false;

  // Figure out if appointment is new or updating, this affects how we build the change
  const changedAppointments = Object.values(changes).map((change) => ({
    id: change.id || change.parent_repeat_id,
    isNew: !change.id,
    changes: change,
  }));

  useEffect(() => {
    if (isOpen) {
      // Search for original appointments to get pre-update info
      const changedIds = changedAppointments.map(({ id }) => id);
      dispatch(getAllAppointments({ id: changedIds }));
    }
  }, [isOpen]);

  // Compare pre & post update values, determine if changed & return
  const buildChange = (isNew: boolean, label: string, original: string, change: string) => {
    if (original === change) return { label, from: original, to: null };
    if (isNew) {
      if (label === 'Date') return { label, from: null, to: change };
      if (label === 'Time') return { label, from: null, to: original };
    }
    if (!change) return { label, from: original, to: null };
    return { label, from: original, to: change, updated: true };
  };

  const renderChangelogs = (appointments: Changelog[]) => {
    return appointments.map(({ id, isNew, builtChanges }) => (
      <div key={id} className="mb-2">
        <Row>
          <Row className="fw-bold mb-1">{isNew ? `New (From #${id})` : `Updated #${id}`}</Row>
          {builtChanges?.map((change) => renderChange(change))}
        </Row>
        <hr />
      </div>
    ));
  };

  const renderChange = ({ label, updated, from, to }: BuiltChange) => (
    <Row>
      <Col sm={2}>{label}:</Col>
      <Col sm={3} className={`${updated === true ? 'text-decoration-line-through' : ''}`}>
        {from || '-'}
      </Col>
      {to && (
        <>
          <Col sm={1} className="text-center">
            <Icon icon={faChevronRight} />
          </Col>
          <Col>{to}</Col>
        </>
      )}
    </Row>
  );

  // Build appointment pre & post update info for display.
  const builtAppointments = changedAppointments
    .map(({ id, isNew, changes }) => {
      const { start_time, end_time, date } = changes;

      const original = appointments.find((appt) => appt.id === id);
      if (!original) return;

      // Find user's name from changed ID to display with info
      const changedUser = users.find((user) => user.id === changes.user_id);

      const changeList = [
        { label: 'Client', from: original.client.full_name, to: null },
        { label: 'Service', from: original.service_type.name, to: null },
        buildChange(
          isNew,
          'Date',
          dayjs(original.date).format(weeklyPlannerDayDisplayFormat),
          dayjs(date).format(weeklyPlannerDayDisplayFormat),
        ),
        buildChange(isNew, 'Time', `${original.start_time} - ${original.end_time}`, `${start_time} - ${end_time}`),
        buildChange(isNew, 'Worker', original.user?.full_name, changedUser ? changedUser.name : ''),
      ];

      return {
        id,
        isNew,
        builtChanges: changeList,
      };
    })
    .filter(Boolean) as Changelog[];

  const onSave = async (changes: any) => {
    setIsSaving(true);
    const data: Params[] = Object.entries(changes).map(([, change]: [string, any]) => change);
    const { payload } = await dispatch(bulkUpdate(data));

    setIsSaving(false);
    if (payload?.success) {
      dispatch(clear());
      onClose();
    }
  };

  const modalProps = {
    className: 'border-0 rounded-0 mt-1 mb-1',
    headerClassName: 'rounded-0',
    bodyClassName: 'modal-body-scrollable',
  };

  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader className="bg-success text-white">Review Changes</ModalHeader>
      <ModalBody>
        <div className="mb-4 pb-2 border-2 border-bottom border-success">
          Please confirm the following changes to save...
        </div>
        <ErrorResponse message={errorMessage} />
        <ModalCard {...modalProps} header="Show changes">
          {renderChangelogs(builtAppointments)}
        </ModalCard>
      </ModalBody>
      <ModalFooter className="align-items-center flex-column">
        <div>
          <Button size="sm" disabled={isSaving || hasError} color="success" onClick={() => onSave(changes)}>
            {isSaving && <Spinner type="grow" size="sm" className="ms-4 me-4" />}
            {!isSaving && <span>Save</span>}
          </Button>
          <Button size="sm" className="ms-2 ps-4 pe-4" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Changes;
