import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import AppointmentCancellationConfirm from '~appointments/AppointmentCancellationConfirm';
import dayjs from 'dayjs';

import { AuthTags } from '~constants/authTags';

import { dateInputFieldFormat } from '~libs/dayjs';

import { ButtonProps } from '~components/Button';
import { FieldProps } from '~components/FormFields';
import Spinner from '~components/Spinner';
import AppointmentBanner from '~appointments/components/AppointmentBanner';
import AppointmentForm from '~appointments/components/AppointmentForm';

import { appointmentFields, repeatFields } from '~appointments/forms/appointment/cancellation';
import { appointmentEditFields, repeatAppointmentEditFields } from '~appointments/forms/appointment/edit';

import { getAll as getActivities } from '~appointments/actions/activities';
import {
  cancel as cancelAppointment,
  getOne as getAppointment,
  getAll as getAppointments,
} from '~appointments/actions/appointments';
import { getAll } from '~appointments/actions/cancellationReasons';
import { getAll as getEquipments } from '~main/actions/equipments';
import { setTitle } from '~main/actions/login';
import { getAll as getMedications } from '~main/actions/medications';
import { getAll as getTaskClassifications } from '~main/actions/taskClassifications';
import { getAll as getTaskTypes } from '~main/actions/taskTypes';

import { clearAppointment } from '~appointments/reducers/appointments';

import {
  selectAppointmentLoading,
  selectAppointments,
  selectCurrentAppointment,
} from '~appointments/selectors/appointments';

const AppointmentCancellation: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const authTag = AuthTags.APPOINTMENTS.CANCEL;

  const [changedValues, setChangedValues]: [any, any] = useState({});
  const [confirmOpen, setConfirmOpen]: [boolean, any] = useState(false);

  // Link record from selector to component
  const appointment = useAppSelector(selectCurrentAppointment);
  const linkedAppointments = useAppSelector(selectAppointments);
  const appointmentLoading = useAppSelector(selectAppointmentLoading);

  const isRepeat = appointment?.repeat;

  let cancellationFields: FieldProps;
  if (isRepeat) cancellationFields = repeatFields;
  else cancellationFields = appointmentFields;

  let apptEditFields: FieldProps;
  if (isRepeat) apptEditFields = repeatAppointmentEditFields;
  else apptEditFields = appointmentEditFields;

  useEffect(() => {
    setTitle(`Cancel appt`);
    // Get appointment by ID on mount if not current appointment
    dispatch(getAppointment({ id }));
    dispatch(getActivities({ parent_id: id, sort: ['date_time'] }));

    dispatch(
      getAppointments({
        repeat: false,
        date: { gte: dayjs().format(dateInputFieldFormat) },
        parent_repeat_id: id,
      }),
    );

    // Get task classifications and types
    dispatch(getTaskClassifications());
    dispatch(getTaskTypes({ disabled: false, sort: ['classification', 'name'] }));

    // Get medications
    dispatch(getMedications({ disabled: false, sort: ['name'] }));

    // Get equipments
    dispatch(getEquipments({ disabled: false, sort: ['name'] }));

    // Clean up component on unmount, clear reducer, etc.
    return () => {
      dispatch(clearAppointment());
    };
  }, []);

  useEffect(() => {
    // Get cancellation reasons based on appointment funding type
    if (appointment) {
      setTitle(`Cancel #${appointment?.reference_number ?? ''}`);
      const { package_fixed } = appointment;
      dispatch(getAll({ include_ndis: package_fixed?.funding_type === 4 }));
    }
  }, [appointment]);

  const save = async (values: any) => {
    const { payload } = await dispatch(
      cancelAppointment({
        id,
        ...values,
      }),
    );
    if (payload?.success) navigate(`/appointments/${id}`);
  };

  const onSave = (values: any) => {
    const confirmationRequired = isRepeat && linkedAppointments.length > 0;
    if (!confirmationRequired) save(values);
    else {
      setChangedValues(values);
      setConfirmOpen(true);
    }
  };

  const onConfirm = (confirmed: boolean, ids: string[], values: any) => {
    setConfirmOpen(false);

    if (confirmed)
      save({
        ...values,
        including_children: ids,
      });
  };

  const isLoading = appointmentLoading === 'pending';

  const allFields = Object.entries(apptEditFields).map(([key, props]) => {
    return {
      key,
      disabled: true,
      ...props,
    };
  });

  const buttonProps: ButtonProps = {
    authTag,
    dataCy: 'appointment-cancellation-confirm-button',
    color: 'danger',
    className: 'm-3',
    children: 'Confirm',
  };

  return (
    <div className="p-2">
      {!isLoading && (
        <>
          <AppointmentCancellationConfirm
            isOpen={confirmOpen}
            rows={linkedAppointments}
            onClose={(confirm, ids) => onConfirm(confirm, ids, changedValues)}
          />
          <AppointmentBanner appointment={appointment} isRepeat={isRepeat} isCancellation={true} />
          <div className="border border-2 rounded border-danger">
            <AppointmentForm
              authTag={authTag}
              fields={cancellationFields}
              canSave={() => true}
              save={onSave}
              buttonProps={buttonProps}
              data-cy={'appointment-cancellation-form'}
            />
          </div>
          <div className="mt-2">
            <AppointmentForm
              authTag={authTag}
              fields={allFields}
              showActivityLogs={true}
              data-cy={'appointment-edit-form'}
            />
          </div>
        </>
      )}
      {isLoading && <Spinner loading={isLoading} className="mt-5" />}
    </div>
  );
};

export default AppointmentCancellation;
