import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectAppointmentServiceTypes = ({ appointments }: RootState) => appointments.serviceTypes.rows;
export const selectAppointmentServiceTypeColours = ({ appointments }: RootState) =>
  appointments.serviceTypes.colourRows;

export const selectAppointmentServiceTypeById = createSelector(
  [selectAppointmentServiceTypes, (_: RootState, id?: number) => id],
  (rows, id) => rows.find(({ id: rowId }) => id === rowId),
);

export const selectLookupAppointmentServiceTypes = createSelector(selectAppointmentServiceTypes, (rows) =>
  rows.map(({ id, name, deleted }) => ({
    id,
    name: `${name}${deleted ? ` - DELETED` : ''}`,
  })),
);

export const selectServiceTypeColourById = createSelector(selectAppointmentServiceTypeById, (serviceType) =>
  serviceType?.colour ? `#${serviceType.colour}` : null,
);
