import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Collapse, Table } from 'reactstrap';
import dayjs from 'dayjs';

import { AppointmentStatuses } from '~constants/maps';

import { dateInputFieldFormat, formatDateTimeValue, formatTimeString } from '~libs/dayjs';

import { InputProps } from '~components/FormFields';

import { getAll } from '~appointments/actions/userAppointments';

import { selectUserAppointments } from '~appointments/selectors/userAppointments';

interface AppointmentRow {
  date?: string;
  start_time?: string;
  end_time?: string;
  client: { full_name: string; suburb: string };
  repeat?: boolean;
  repeat_frequency?: { day: string; frequency: string };
}

const FormInputWorkerAppointments: React.FC<InputProps> = ({ value = {}, id }) => {
  // Check for change
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(selectUserAppointments);
  const { date, user_id, repeat, repeat_start, repeat_weekday } = value;

  const checkDate = repeat ? repeat_start : date;
  const isOpen = !isNaN(parseInt(user_id)) && checkDate.length > 0;

  useEffect(() => {
    // If user and date selected, check for leave requests in relevant period
    if (isOpen) {
      const params: any = {
        user_id,
        status_id: {
          ne: AppointmentStatuses.CANCELLED,
        },
      };

      if (repeat) {
        params.sort = ['repeat_weekday', 'repeat_week_count'];
        params.repeat_end = {
          lt: dayjs(checkDate).format(dateInputFieldFormat),
        };
        if (repeat_weekday?.length > 0) params.repeat_weekday = repeat_weekday;
      } else {
        params.sort = ['date'];
        params.date = {
          gte: dayjs(checkDate).subtract(2, 'days').format(dateInputFieldFormat),
          lt: dayjs(checkDate).add(2, 'days').format(dateInputFieldFormat),
        };
      }

      dispatch(getAll(params));
    }
  }, [date, user_id, repeat_start, repeat_weekday]);

  const bodyRows = appointments.map(
    ({ date, start_time, end_time, client, repeat, repeat_frequency }: AppointmentRow, index) => {
      const { full_name, suburb } = client;

      let date_value = formatDateTimeValue('date', date);
      if (repeat === true && repeat_frequency) date_value = `${repeat_frequency.day}, ${repeat_frequency.frequency}`;

      const start = formatTimeString(start_time);
      const end = formatTimeString(end_time);

      return (
        <tr key={index}>
          <td className="align-middle">{date_value}</td>
          <td className="align-middle">{`${start} - ${end}`}</td>
          <td className="align-middle">{full_name}</td>
          <td className="align-middle">{suburb}</td>
        </tr>
      );
    },
  );

  const headerClassName = 'ps-2 pe-2 border-bottom';
  const isEmpty = appointments.length == 0;
  const caption = isEmpty ? 'No recent/current' : appointments.length;
  return (
    <Collapse isOpen={isOpen}>
      <div className={`ps-2 pe-2 fw-bold`} data-id={id}>
        {caption} appointment{appointments.length > 1 || 's'} found
      </div>
      {!isEmpty && (
        <Table data-cy={`${id}-table`}>
          <thead>
            <tr>
              <th className={headerClassName}>Date</th>
              <th className={headerClassName}>Time</th>
              <th className={headerClassName}>Client</th>
              <th className={headerClassName}>Suburb</th>
            </tr>
          </thead>
          <tbody>{bodyRows}</tbody>
        </Table>
      )}
    </Collapse>
  );
};

export default FormInputWorkerAppointments;
