import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import appointmentsReducers from '~appointments/reducers';
import careWorkersReducers from '~care-workers/reducers';
import clientsReducers from '~clients/reducers';
import dashboardReducers from '~dashboard/reducers';
import mainReducers from '~main/reducers';
import weeklyPlannerReducers from '~weekly-planner/reducers';

export const store = configureStore({
  reducer: {
    appointments: appointmentsReducers,
    careWorkers: careWorkersReducers,
    clients: clientsReducers,
    dashboard: dashboardReducers,
    main: mainReducers,
    weeklyPlanner: weeklyPlannerReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
