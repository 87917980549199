import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import dayjs from 'dayjs';

import {
  AvailabilityOptions,
  HighlightOptions,
  PreferenceOptions,
  QualifiedOptions,
  SortOptions,
} from '~constants/maps';

import { AssignableUser, Worker } from '~weekly-planner/lib/common';
import { buildAssignChange } from '~weekly-planner/lib/simulate';

import Accordion from '~components/Accordion';
import Button from '~components/Button';
import ConfirmDialog from '~components/ConfirmDialog';
import { MultiCheckbox, Radio, ReadOnly, Select, Switch } from '~components/FormFields/Inputs';
import Icon, { faCommentSms, faMinus, faPlus, faXmark } from '~components/Icon';
import UnallocatedCard from '~weekly-planner/components/Card/UnallocatedCard';

import { endBidding, startBidding } from '~appointments/actions/appointments';
import { getAssignableUsers } from '~weekly-planner/actions/appointments';
import { getAll as getUnallocated } from '~weekly-planner/actions/appointments/unallocated';
import { simulate } from '~weekly-planner/actions/unsaved';

import {
  clearFocusOptions,
  focusAppointment,
  focusWorker,
  mergeBiddingWorkers,
  toggleBidding,
  updateBiddingWorkers,
  updateFocusOptions,
  updateParams,
} from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedAppointment, selectFocusedWorkerId, selectFocusOptions } from '~weekly-planner/selectors';
import { selectAssignableUsers } from '~weekly-planner/selectors/appointments';
import { selectParams as selectUnallocatedParams } from '~weekly-planner/selectors/appointments/unallocated';
import { selectAllSimulatedChanges, selectFilteredSimulatedWorkers } from '~weekly-planner/selectors/simulate';
import { selectAllChangesCount } from '~weekly-planner/selectors/unsaved';

const FocusBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const workers = useAppSelector(selectFilteredSimulatedWorkers);
  const changesCount = useAppSelector(selectAllChangesCount);
  const focused = useAppSelector(selectFocusedAppointment);
  const options = useAppSelector(selectFocusOptions);
  const focusWorkerId = useAppSelector(selectFocusedWorkerId);
  const assignableUsers = useAppSelector(selectAssignableUsers);
  const data = useAppSelector(selectAllSimulatedChanges);
  const unallocatedParams = useAppSelector(selectUnallocatedParams);

  const { appointment, isBiddingView, newBidders } = focused || {};
  const { id, parent_repeat_id, date, key, bidding_user_ids = [], is_bidding } = appointment || {};
  const { availabilityFilter, qualifiedFilter, preferenceFilter, highlightType, sortBy } = options;

  const [isBidding, setIsBidding] = useState<boolean>(isBiddingView);
  const [confirmBiddingOpen, setConfirmBiddingOpen] = useState(false);
  const [cancelBiddingOpen, setCancelBiddingOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState<AssignableUser[]>([]);

  const confirmBiddingMessages = [];
  const newBidderCount = newBidders?.length ?? 0;
  const bidderCount = bidding_user_ids?.length + newBidderCount;

  if (bidderCount > 0 && is_bidding) {
    confirmBiddingMessages.push(
      `You are about to update a bidding event with ${newBidderCount} new workers (${bidderCount} total)`,
    );
  } else if (newBidderCount > 0) {
    confirmBiddingMessages.push(`You are about to create a bidding event for ${newBidderCount} workers.`);

    if (id === null) {
      confirmBiddingMessages.push(
        `Single appointment does not exist yet. Creating this event will also create (and save) this appointment.`,
      );
    }
  }

  if (changesCount !== 0) {
    confirmBiddingMessages.push(
      `You have ${changesCount} unsaved changes. We recommend saving before creating a bidding event`,
    );
  }

  useEffect(() => {
    if (!assignableUsers.length) return;

    const sortedUsers = [...assignableUsers].sort((a, b) => {
      if (a.is_available !== b.is_available) return a.is_available ? -1 : 1;
      if (a.is_qualified !== b.is_qualified) return a.is_qualified ? -1 : 1;
      return a.name.localeCompare(b.name);
    });

    const selectOptions = sortedUsers.map(({ id, name, is_qualified, is_available }) => ({
      id,
      name: `${name}${!is_qualified ? ' (NQ)' : ''}${!is_available ? ' (NA)' : ''}`,
      is_qualified,
      is_available,
    }));

    setSelectOptions(selectOptions);
  }, [assignableUsers, focusWorkerId]);

  useEffect(() => {
    if (!appointment) return;
    dispatch(getAssignableUsers({ id: appointment.key }));
  }, [appointment]);

  const getOptionStyle = (option: any) => {
    let backgroundColor = 'white';
    if (!option.is_available) backgroundColor = 'lightpink';
    else if (!option.is_qualified) backgroundColor = 'lightyellow';

    return {
      backgroundColor,
      color: 'black',
    };
  };

  const allocateWorker = () => {
    if (!focusWorkerId || !appointment) return;
    const change = buildAssignChange(data, [{ ...appointment, user: undefined, user_id: focusWorkerId }]);
    dispatch(simulate({ ...change, week_start: dayjs(appointment.date).weekday(0) }));
  };

  const handleFocusWorkerChanged = (value: any) => {
    dispatch(focusWorker(value ?? undefined));
  };

  // reset filters if focused appointment changes
  useEffect(() => {
    dispatch(clearFocusOptions({}));
  }, [focused?.appointment?.key]);

  useEffect(() => {
    dispatch(toggleBidding({ isBiddingView: isBidding }));
  }, [isBidding]);

  useEffect(() => {
    if (!focused?.appointment) return;

    let is_qualified;
    switch (qualifiedFilter) {
      case QualifiedOptions.YES: {
        is_qualified = true;
        break;
      }
      case QualifiedOptions.NO: {
        is_qualified = false;
        break;
      }
      default: {
        break;
      }
    }

    let is_available;
    switch (availabilityFilter) {
      case AvailabilityOptions.AVAILABLE: {
        is_available = true;
        break;
      }
      case AvailabilityOptions.UNAVAILABLE: {
        is_available = false;
        break;
      }
      default: {
        break;
      }
    }

    const sort_by = sortBy === SortOptions.BIDDING ? 'bidding' : undefined;

    dispatch(
      updateParams({
        focus_appointment_id: key,
        sort_by,
        filter_by: {
          focused: {
            is_available,
            is_qualified,
            preference: preferenceFilter.map((preference) => preference.toLowerCase()),
          },
        },
      }),
    );
  }, [availabilityFilter, qualifiedFilter, preferenceFilter, sortBy, focused?.appointment?.key]);

  const refreshWorkers = (isChecked: boolean) => {
    dispatch(updateBiddingWorkers({ workerIds: workers.map((worker: Worker) => worker.id), isChecked }));
  };

  const onConfirmBidding = (confirm: boolean) => {
    setConfirmBiddingOpen(false);
    if (confirm) {
      const data = {
        id,
        key,
        date,
        parent_repeat_id,
        bidding_user_ids: [...(bidding_user_ids ?? []), ...(newBidders ?? [])],
      };
      dispatch(startBidding(data));
      dispatch(mergeBiddingWorkers(null));
    }
  };

  const onCancelBidding = async (confirm: boolean) => {
    setCancelBiddingOpen(false);
    if (confirm) {
      const { payload } = await dispatch(endBidding({ id }));
      if (payload?.success) {
        dispatch(focusAppointment(null));
        dispatch(getUnallocated(unallocatedParams));
      }
    }
  };

  const onOptionChange = (name: string, value: any) => {
    dispatch(updateFocusOptions({ [name]: value }));
  };

  const renderRadioOption = (
    id: string,
    label: string,
    mapOptions: { [key: string]: string },
    value: string,
    disabled = false,
  ) => {
    const options = Object.values(mapOptions).map((option) => ({ id: option, name: option }));
    return (
      <>
        <div className="col col-sm-6">{label}</div>
        <div className="col">
          <Radio
            type="radio"
            id={id}
            name={id}
            onChange={onOptionChange}
            options={options}
            disabled={disabled}
            value={options.find((option) => option.id === value)?.name}
            isFlexible={false}
          />
        </div>
      </>
    );
  };

  const renderCheckboxOption = (
    id: string,
    label: string,
    mapOptions: { [key: string]: string },
    value: string[],
    disabled = false,
  ) => {
    const options = Object.values(mapOptions).map((option) => ({ id: option, name: option }));
    return (
      <>
        <div className="col col-sm-6">{label}</div>
        <div className="col">
          <MultiCheckbox
            type="multi-checkbox"
            id={id}
            name={id}
            onChange={onOptionChange}
            options={options}
            disabled={disabled}
            value={value}
            isFlexible={false}
          />
        </div>
      </>
    );
  };

  const renderViewSection = () => {
    return (
      <div className="view-options">
        <div className="row qualified">
          {renderRadioOption('qualifiedFilter', 'Qualified', QualifiedOptions, qualifiedFilter)}
        </div>
        <div className="row availability separated">
          {renderRadioOption('availabilityFilter', 'Availability', AvailabilityOptions, availabilityFilter)}
        </div>
        <div className="row preferences separated">
          {renderCheckboxOption('preferenceFilter', 'Preferences', PreferenceOptions, preferenceFilter)}
        </div>
        <div className="row highlight separated">
          {renderRadioOption('highlightType', 'Highlight', HighlightOptions, highlightType, true)}
        </div>
        <div className="row sorting separated">{renderRadioOption('sortBy', 'Sort by', SortOptions, sortBy)}</div>
      </div>
    );
  };

  const renderBiddingSection = () => {
    return (
      <div className="view-bidding">
        <div className="row qualified">
          <div className="col col-sm-8 label">Enable Bidding</div>
          <div className="col field">
            <Switch
              type="switch"
              id="unallocated_bidding_mode"
              name="unallocated_bidding_mode"
              value={isBidding ?? ''}
              onChange={() => setIsBidding(!isBidding)}
              className="d-flex m-1"
            />
          </div>
        </div>
        {isBidding && (
          <>
            <div className="row">
              <div className="col col-sm-8 label">Selected</div>
              <div className="col field">
                <ReadOnly
                  type="readonly"
                  id="bidding-count"
                  name="bidding-count"
                  className="text-center"
                  value={newBidderCount}
                />
              </div>
            </div>
            <div className="row">
              <div className="col me-1">
                <Button size="sm" color="success" className="w-100" onClick={() => refreshWorkers(true)}>
                  <Icon className="me-2" icon={faPlus} />
                  Select All
                </Button>
              </div>
              <div className="col">
                <Button size="sm" color="warning" className="w-100" onClick={() => refreshWorkers(false)}>
                  <Icon className="me-2" icon={faMinus} />
                  Clear All
                </Button>
              </div>
            </div>
            <div className="row center">
              <Button
                size="sm"
                color="success"
                className="w-100"
                onClick={() => setConfirmBiddingOpen(true)}
                disabled={!newBidders?.length}
              >
                <Icon className="me-2" icon={faCommentSms} />
                {is_bidding ? 'Amend Bidding' : 'Start Bidding'}
              </Button>
            </div>
            {is_bidding && (
              <div className="row center">
                <Button size="sm" color="danger" className="w-100" onClick={() => setCancelBiddingOpen(true)}>
                  <Icon className="me-2" icon={faXmark} />
                  Cancel Bidding
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="focus-bar-container">
      <ConfirmDialog
        isOpen={confirmBiddingOpen}
        messages={confirmBiddingMessages}
        onClose={(confirm) => onConfirmBidding(confirm)}
      />
      <ConfirmDialog
        isOpen={cancelBiddingOpen}
        messages={[`Cancel bidding for Appointment #${id}? Awaiting response from ${bidding_user_ids?.length} workers`]}
        onClose={(confirm) => onCancelBidding(confirm)}
      />
      <div className="focus-card">
        <div className="w-100">{appointment && <UnallocatedCard appointment={appointment} showStatus={true} />}</div>
        <div
          role="button"
          className="unfocus"
          onClick={() => dispatch(focusAppointment(null))}
          title="Unfocus appointment"
        >
          <Icon icon={faXmark} size="lg" color="red" />
        </div>
      </div>
      <div className="section raw">
        <Select
          key={focusWorkerId || null}
          id={`allocate-user`}
          name={`allocate-user`}
          type="select"
          placeholder="Assign Worker"
          value={focusWorkerId}
          options={selectOptions}
          className={'form-input-multi-select'}
          getOptionStyle={getOptionStyle}
          onChange={(_id, value) => handleFocusWorkerChanged(value)}
        />
        <Button className="allocate" size="sm" color="success" disabled={!focusWorkerId} onClick={allocateWorker}>
          Allocate
        </Button>
      </div>
      <Accordion
        allowMultiple={true}
        className="sections"
        items={[
          { header: 'Bidding', body: <div>{renderBiddingSection()}</div> },
          { header: 'Options', body: <div>{renderViewSection()}</div> },
        ]}
      />
    </div>
  );
};

export default React.memo(FocusBar);
