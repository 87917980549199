import React from 'react';

import { DateInput } from '../Inputs';

interface ComponentProps {
  id: string;
  value: { [key: string]: any };
  disabled?: boolean;
  inline?: boolean;
  onChange: (name: string, value: any) => void;
}

const DateRange: React.FC<ComponentProps> = ({ id, value = {}, disabled = false, inline = true, onChange }) => {
  const { gte, lte } = value;

  const setChange = (name: string, targetValue: string) => {
    onChange(id, { ...value, [name]: targetValue });
  };

  return (
    <div className={`${inline ? 'd-inline-flex' : 'w-100 ps-3'}`}>
      <div className={`${!inline ? 'mt-1 mb-1' : ''}  d-flex align-items-center ${disabled ? 'bg-disabled' : ''}`}>
        <span className="me-2">from</span>
        <DateInput
          type="date"
          name="gte"
          id="gte"
          className="border-0 m-1"
          dataCy={id}
          value={gte}
          disabled={disabled}
          onChange={setChange}
        />
      </div>
      <div className={`${!inline ? 'mt-1 mb-1' : ''} d-flex align-items-center`}>
        <span className={`${inline ? 'ms-2 me-2' : 'me-2'}`}>to</span>
        <DateInput
          type="date"
          name="lte"
          id="lte"
          dataCy={id}
          className="border-0 m-1"
          value={lte}
          disabled={disabled}
          onChange={setChange}
        />
      </div>
    </div>
  );
};

export default DateRange;
