import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectClientPackages = ({ main }: RootState) => main.clientPackages.rows;

export const selectLookupClientPackages = createSelector(selectClientPackages, (rows) =>
  rows.map(({ id, name }) => ({
    id,
    name,
  })),
);
