import { SearchProps } from 'tsx/components/FormFields/Search';

export const searchFields: SearchProps = {
  text_search: {
    persist: true,
    type: 'text-like',
    field: ['client.full_name'],
    placeholder: 'Basic search',
  },
  client_name: {
    defaultShow: true,
    type: 'text-like',
    field: 'client.full_name',
    placeholder: 'Search client',
    caption: 'Client',
  },
  start_date: {
    defaultShow: true,
    type: 'date-range',
    field: 'start_date',
    caption: 'Start Date',
  },
  end_date: {
    type: 'date-range',
    field: 'end_date',
    caption: 'End Date',
  },
  // sah_level: {
  //   type: 'multi-lookup',
  //   field: 'sah_level',
  //   caption: 'Support at Home Level',
  //   placeholder: 'All',
  //   selectorOptions: selectSupportAtHomeLevels, //NOT IMPLEMENTED YET
  // },
};

export default searchFields;
