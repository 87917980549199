import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Collapse, Table } from 'reactstrap';

import { formatDateString } from '~libs/dayjs';

import { InputProps } from '~components/FormFields';

import { getAll } from '~main/actions/leaveRequests';

import { selectLeaveRequests } from '~main/selectors/leaveRequests';

const FormInputWorkerLeaveAvailability: React.FC<InputProps> = ({ value = {}, id }) => {
  const dispatch = useAppDispatch();
  const leaveRequests = useAppSelector(selectLeaveRequests);
  const { date, user_id, repeat, repeat_start } = value;

  const checkDate = repeat ? repeat_start : date;
  const isOpen = !isNaN(parseInt(user_id)) && checkDate.length > 0;

  useEffect(() => {
    // If user and date selected, check for leave requests in relevant period
    if (isOpen) {
      dispatch(
        getAll({
          date_end: {
            gt: checkDate,
          },
          user_id,
          status: [1, 2],
        }),
      );
    }
  }, [date, user_id, repeat_start]);

  const bodyRows = leaveRequests.map(({ date_start, date_end, reason }, index) => (
    <tr key={index}>
      <td className="align-middle">{formatDateString(date_start)}</td>
      <td className="align-middle">{formatDateString(date_end)}</td>
      <td className="align-middle">{reason?.name ?? 'Unknown reason'}</td>
    </tr>
  ));

  const headerClassName = 'ps-2 pe-2 border-bottom';
  const isEmpty = leaveRequests.length == 0;

  const caption = isEmpty ? 'No current' : leaveRequests.length;
  return (
    <Collapse isOpen={isOpen}>
      <div className={`ps-2 pe-2 fw-bold`} data-id={id}>
        {caption} leave period found
      </div>
      {!isEmpty && (
        <Table data-cy={`${id}-table`}>
          <thead>
            <tr>
              <th className={headerClassName}>Start</th>
              <th className={headerClassName}>End</th>
              <th className={headerClassName}>Reason</th>
            </tr>
          </thead>
          <tbody>{bodyRows}</tbody>
        </Table>
      )}
    </Collapse>
  );
};

export default FormInputWorkerLeaveAvailability;
