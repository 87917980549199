import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import ContainerBanner from '~components/ContainerBanner';
import { faCircleExclamation, faExclamationTriangle } from '~components/Icon';

import { getOutstanding } from '~dashboard/actions/alerts';

import { AlertStats } from '~dashboard/reducers/alerts';

import { selectAll as selectAlerts } from '~dashboard/selectors/alerts';

const AlertBanners: React.FC = () => {
  const dispatch = useAppDispatch();
  const { publish_alert, users_running_late, pending_completion }: AlertStats = useAppSelector(selectAlerts);

  useEffect(() => {
    dispatch(getOutstanding());
  }, []);

  const { days_to_publish, publish_date } = publish_alert ?? {};
  const { not_started, not_completed } = users_running_late ?? {};
  const pendingCompletionMessages = pending_completion?.map(({ date, count }) => `${date} - ${count}`) ?? [];
  return (
    <div className="dashboard-alerts">
      {publish_alert && (
        <ContainerBanner
          key="publishAlert"
          className="alert-banner"
          color="danger"
          textColor="white"
          icon={faExclamationTriangle}
          iconSize="2x"
          title="Weekly schedule needs to be published"
          messages={[`Appointments are showing for ${days_to_publish} days till: ${publish_date}`]}
        />
      )}
      {users_running_late && (
        <ContainerBanner
          key="usersRunningLate"
          className="alert-banner"
          color="info"
          icon={faCircleExclamation}
          iconSize="2x"
          title="Workers running late"
          messages={[`${not_started} have not marked START on time`, `${not_completed} have not marked COMPLETE`]}
        />
      )}
      {pending_completion && (
        <ContainerBanner
          key="pendingCompletion"
          className="alert-banner"
          color="info"
          icon={faCircleExclamation}
          iconSize="2x"
          title="Appointments pending completion"
          messages={pendingCompletionMessages}
        />
      )}
    </div>
  );
};

export default AlertBanners;
