import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';

import Icon, { faEye, faEyeSlash, faGear } from '~components/Icon';

import { toggleOption } from '~weekly-planner/reducers/appointments/conflicts';

import { selectOptions } from '~weekly-planner/selectors/appointments/conflicts';

const ConflictsViewMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { conflictTime, conflictTravel, conflictPreference } = useAppSelector(selectOptions);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any) => (
    <DropdownItem toggle={false} onClick={onClick}>
      <Icon icon={iconToggle ? faEye : faEyeSlash} className="mt-1" />
      <Label className="ms-2 mb-0">{label}</Label>
    </DropdownItem>
  );

  return (
    <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down" className="filter-menu">
      <DropdownToggle size="md" className="ms-2" type="submit" onClick={() => setIsViewOpen(!isViewOpen)}>
        <Icon className="me-2" icon={faGear} />
        Filters
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>View Options</DropdownItem>
        {renderItem(
          () => dispatch(toggleOption({ key: 'conflictTime', toggle: !conflictTime })),
          conflictTime,
          'Time Clash',
        )}
        {renderItem(
          () => dispatch(toggleOption({ key: 'conflictTravel', toggle: !conflictTravel })),
          conflictTravel,
          'Travel Clash',
        )}
        {renderItem(
          () => dispatch(toggleOption({ key: 'conflictPreference', toggle: !conflictPreference })),
          conflictPreference,
          'Preference Clash',
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ConflictsViewMenu;
