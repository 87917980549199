import { createSlice } from '@reduxjs/toolkit';

import { addCommonReducers, CommonState } from '~libs/reduxUtils';

import { getAll, getColours, getOne, typePrefix } from '~appointments/actions/serviceTypes';

interface ServiceTypeRow {
  id: number;
  name: string;
  allow_transport: boolean;
  ndis_support_item: number;
  default_qualification_ids?: Array<number>;
  deleted: boolean;
  colour?: string;
}

export interface Colour {
  name: string;
  id: number;
  deleted: string;
  colour: string;
}

interface State extends CommonState<ServiceTypeRow> {
  colourRows: Array<Colour>;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  colourRows: [],
};

// Main slice, connecting API actions to redux state.
export const serviceTypesSlice = createSlice({
  name: 'service-types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getColours.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.colourRows = action.payload.data;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });

    addCommonReducers<ServiceTypeRow>(builder, typePrefix, getOne);
  },
});

export default serviceTypesSlice.reducer;
