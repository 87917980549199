import { combineReducers } from '@reduxjs/toolkit';

import * as addressReducers from '~main/reducers/address';
import app from '~main/reducers/app';
import * as clientsReducers from '~main/reducers/clients';
import equipments from '~main/reducers/equipments';
import leaveCodes from '~main/reducers/leaveCodes';
import leaveRequests from '~main/reducers/leaveRequests';
import login from '~main/reducers/login';
import medications from '~main/reducers/medications';
import ndisSupportItems from '~main/reducers/ndisSupportItems';
import paymentTypes from '~main/reducers/paymentTypes';
import taskClassifications from '~main/reducers/taskClassifications';
import taskTypes from '~main/reducers/taskTypes';
import workSites from '~main/reducers/workSites';

export const main = {
  ...addressReducers,
  ...clientsReducers,
  app,
  equipments,
  leaveCodes,
  leaveRequests,
  login,
  medications,
  ndisSupportItems,
  paymentTypes,
  taskClassifications,
  taskTypes,
  workSites,
};

export default combineReducers(main);
