import { apiDashboard, apiSnapshots } from '~constants/api';

import { get, put } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~dashboard/actions';

export const typePrefix = `${prefix}-snapshots`;

const thunkConfig = [
  { actionType: 'getAll', apiPath: `${apiSnapshots}`, method: get },
  { actionType: 'getSnapshot', apiPath: (params: Params) => `${apiSnapshots}/${params.id}`, method: get },
];

const prefThunkConfig = [
  { actionType: 'getLayout', apiPath: `${apiDashboard}/preferences`, method: get },
  { actionType: 'upsertLayout', apiPath: `${apiDashboard}/preferences`, method: put },
];

export const { getAll, getSnapshot } = createThunks(typePrefix, thunkConfig);
export const { getLayout, upsertLayout } = createThunks(`${typePrefix}-preferences`, prefThunkConfig);
