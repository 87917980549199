import React, { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Col, Container, Row } from 'reactstrap';
import { throttle } from 'lodash';

import { AppointmentConflictTypes } from '~constants/maps';

import { getDayString } from '~libs/dayjs';

import Button from '~components/Button';
import Icon, { faEye } from '~components/Icon';
import InfiniteScroll from '~components/InfiniteScroll';
import Spinner from '~components/Spinner';
import { ConflictsViewMenu } from '~weekly-planner/components/SideBar/Conflicts';

import { getAll as getConflicts } from '~weekly-planner/actions/appointments/conflicts';

import { clear, updateParams } from '~weekly-planner/reducers/appointments/conflicts';
import { focusWorker } from '~weekly-planner/reducers/weeklyPlanner';

import {
  selectAll,
  selectCount,
  selectHasLoaded,
  selectLoading,
  selectOptions,
  selectParams,
} from '~weekly-planner/selectors/appointments/conflicts';

interface ComponentProps {
  weekStart: string;
}

const Conflicts: React.FC<ComponentProps> = React.memo(({ weekStart: wpWeekStart }) => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);
  const hasLoaded = useAppSelector(selectHasLoaded);
  const appointments = useAppSelector(selectAll);
  const { conflictTime, conflictTravel, conflictPreference } = useAppSelector(selectOptions);
  const count = useAppSelector(selectCount);

  const isLoading = loading === 'pending';
  const { limit, week_start } = params;

  const { PREFERENCE_CLASH, TIME_CLASH, TRAVEL_CLASH } = AppointmentConflictTypes;

  // Load on scroll
  const containerRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(
    throttle(async () => {
      if (!containerRef.current || isLoading) return;

      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const nearBottom = scrollHeight - (scrollTop + clientHeight) < 50;

      if (!isLoading && nearBottom && limit <= appointments.length) {
        dispatch(updateParams({ limit: limit + 30 }));
      }
    }, 200),
    [limit, dispatch, isLoading],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', onScroll, { passive: true });
    return () => container.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // Fetch data on param change
  useEffect(() => {
    if (week_start && limit) {
      dispatch(getConflicts(params));
    }
  }, [params]);

  // Update params on date change
  useEffect(() => {
    if (!hasLoaded || wpWeekStart !== week_start) {
      dispatch(updateParams({ week_start: wpWeekStart, limit: 30 }));
    }
  }, [hasLoaded, wpWeekStart, week_start]);

  // unmount & clear reducer
  useEffect(() => {
    return () => {
      dispatch(clear([]));
    };
  }, []);

  const renderResults = () => {
    return appointments
      .filter((appt) => {
        return (
          (conflictTime && appt.conflict_type === TIME_CLASH) ||
          (conflictTravel && appt.conflict_type === TRAVEL_CLASH) ||
          (conflictPreference && appt.conflict_type === PREFERENCE_CLASH)
        );
      })
      .map(({ client, user, start_time, date, conflict_type }, index) => {
        return (
          <Row key={index} className="underline">
            <Col>
              <div>
                {getDayString(date)} {start_time}
              </div>
              <div>{client.full_name}</div>
            </Col>
            <Col>{user.full_name}</Col>
            <Col>{conflict_type}</Col>
            <Col sm={1} className="text-center">
              <Button size="sm" className="bg-white text-dark border-0" onClick={() => dispatch(focusWorker(user.id))}>
                <Icon icon={faEye} />
              </Button>
            </Col>
          </Row>
        );
      });
  };

  const loader = <Spinner loading={isLoading} className="p-5" />;
  const endMessage = <p className="no-result">No conflicts found</p>;

  const loadMore = () => {
    if (!isLoading && appointments.length === limit) {
      dispatch(updateParams({ ...params, limit: limit + 30 }));
    }
  };
  return (
    <div className="card-container">
      <Container className="inner-content limited ">
        <div className="nav card-header">
          <ConflictsViewMenu />
        </div>
        <Row className=" fw-bold row underline flex-nowrap">
          <Col>Appointment</Col>
          <Col>Worker</Col>
          <Col>Conflict</Col>
          <Col sm={1} />
        </Row>
        <div id={'conflict-scrollable-area'} className="appointment-conflicts">
          <InfiniteScroll
            dataLength={appointments.length}
            loadMore={loadMore}
            hasMore={count !== appointments.length}
            loader={loader}
            endMessage={endMessage}
            scrollableTarget="conflict-scrollable-area"
          >
            {renderResults()}
          </InfiniteScroll>
        </div>
      </Container>
    </div>
  );
});

Conflicts.displayName = 'Conflicts';
export default Conflicts;
