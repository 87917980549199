import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { LocationInfo } from '~types/dashboard';
import dayjs from 'dayjs';

import { dateInputFieldFormat, timeValueFormat } from '~libs/dayjs';

import { SearchBar } from '~components/FormFields/Search';

import mapSearchFields from '~dashboard/forms/mapSearchFields';

import { getAll as getAllUsers, getLocations as getUserLocations } from '~care-workers/actions';
import { getAll as getAllClients, getLocations as getClientLocations } from '~main/actions/clients';

import { selectLocations as selectUserLocations } from '~care-workers/selectors/users';
import { selectLocations as selectClientLocations } from '~main/selectors/clients/clients';

import Marker from './Marker';
import Sidebar from './Sidebar';

interface Filters {
  date: string;
  time: string;
  users?: number[];
  clients?: number[];
}

const CareWorkerMap: React.FC = () => {
  const dispatch = useAppDispatch();
  const map = useMap();

  const clientLocations: LocationInfo[] = useAppSelector(selectClientLocations);
  const userLocations: LocationInfo[] = useAppSelector(selectUserLocations);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const defaultFilters = {
    date: dayjs().format(dateInputFieldFormat),
    time: dayjs().format(timeValueFormat),
    users: [],
    clients: [],
  };
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    dispatch(getAllClients());
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    load();
  }, [filters]);

  const load = () => {
    const { date, time, users, clients } = filters;
    dispatch(getClientLocations({ date, time, id: clients }));
    dispatch(
      getUserLocations({
        date,
        time,
        id: users,
      }),
    );
  };

  const select = (data: LocationInfo) => {
    const lat = Number(data.location?.latitude);
    const lng = Number(data.location?.longitude);
    if (!isNaN(lat) && !isNaN(lng) && map) {
      setSelectedId(data.id);
      map?.panTo({ lat, lng });
      map?.setZoom(10);
    }
  };

  const handleSearch = (values: { [key: string]: any }) => {
    const { users, clients, date, time } = values;
    const updatedFilters = {
      date: date || defaultFilters.date,
      time: time || defaultFilters.time,
      users: users && users.length > 0 ? users : defaultFilters.users,
      clients: clients && clients.length > 0 ? clients : defaultFilters.clients,
    };
    setFilters(updatedFilters);
  };

  const renderMarkers = (points: LocationInfo[]) => {
    return points.map((person: LocationInfo) => (
      <Marker key={`point-${person.id}`} data={person} openId={selectedId} />
    ));
  };

  return (
    <div>
      <SearchBar fields={mapSearchFields} onSubmit={handleSearch} initialValues={defaultFilters} />
      <div className="care-worker-map">
        <div className="section-wrapper">
          <Sidebar userList={userLocations} clientList={clientLocations} onSelect={select} />
          <div className="map-wrapper">
            <Map
              defaultCenter={{ lat: -31.6927663675751, lng: 152.9024282862787 }}
              defaultZoom={4}
              disableDefaultUI={true}
              mapId="DEMO_MAP_ID"
            >
              {renderMarkers(userLocations)}
              {renderMarkers(clientLocations)}
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareWorkerMap;
