import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

function FormInputVehicleType({ id, value, disabled, options, onChange }: InputProps) {
  const [override, setOverride] = useState(false);

  let message = 'Not specified';
  const option = options?.find(({ id }) => id === value);
  if (option) message = option.name;

  return (
    <Row className="align-items-center">
      <Col className="d-flex align-items-center">
        {override && (
          <FormInput.Select
            type="select"
            name={id}
            id={id}
            disabled={disabled}
            value={value ?? ''}
            onChange={onChange}
            options={options}
            dataCy={id}
          />
        )}
        {!override && <FormInput.ReadOnly type="readonly" name={id} id={id} value={message} dataCy={id} />}
      </Col>
      <Col>
        {!override && (
          <Button
            type="button"
            size="sm"
            color="success"
            onClick={() => setOverride(!override)}
            dataCy={`${id}-override-button`}
          >
            Override
          </Button>
        )}
      </Col>
    </Row>
  );
}

export default FormInputVehicleType;
