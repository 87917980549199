import { apiUsers } from '~constants/api';

import { get, put } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

export const typePrefix = 'alerts';

const config = [
  { actionType: 'update', apiPath: (params: Params) => `${apiUsers}/notifications/${params.id}`, method: put },
  { actionType: 'getAll', apiPath: `${apiUsers}/notifications`, method: get },
];

export const { update, getAll } = createThunks(typePrefix, config);
