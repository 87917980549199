export const AppointmentStatuses = Object.freeze({
  PENDING_CARER_APPROVAL: 1,
  CARER_REJECTED: 2,
  OFFICE_CHANGED: 3,
  CARER_CHANGED: 4,
  ACCEPTED: 5,
  STARTED: 6,
  COMPLETED: 7,
  CANCELLED: 8,
});

export const PaymentTypes = Object.freeze({
  NONE: 'None',
  EFTPOS: 'EFTPOS',
  CASH: 'Cash',
  ACCOUNT: 'Account',
});

export const QualifiedOptions = Object.freeze({
  ALL: 'All',
  YES: 'Yes',
  NO: 'No',
});

export const HighlightOptions = Object.freeze({
  TIMES: 'Times',
  DISTANCE: 'Distance',
});

export const AvailabilityOptions = Object.freeze({
  ALL: 'All',
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Not Available',
});

export const PreferenceOptions = Object.freeze({
  PREFERRED: 'Preferred',
  HISTORICAL: 'Historical',
});

export const SortOptions = Object.freeze({
  ALPHA: 'Alphabetical',
  BIDDING: 'Bidding',
});

export const WeeklyPlannerDatasetOptions = Object.freeze({
  USER: 'Users',
  CLIENT: 'Clients',
});

export const WeeklyPlannerDateRangeOptions = Object.freeze({
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
});

export const AppointmentConflictTypes = Object.freeze({
  PREFERENCE_CLASH: 'Preference Clash',
  TIME_CLASH: 'Time Clash',
  TRAVEL_CLASH: 'Travel Clash',
});
