import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { LocationInfo } from '~types/dashboard';

import { LoadingState } from '~libs/reduxUtils';
import { WorkerAvailability } from '~weekly-planner/lib/common';

import { getAll, getAvailability, getLocations, getOne, typePrefix } from '~care-workers/actions';

interface userRow {
  id: number;
  full_name?: string;
  pay_level?: { id: string; name: string };
  qualifications?: Array<string>;
  rostering_notes?: string;
}

interface userState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<userRow> | [];
  row: userRow | any;
  locations: Array<LocationInfo>;
  total?: number;
  availability: WorkerAvailability;
}

const initialState: userState = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
  locations: [],
  total: 0,
  availability: {},
};

// Main slice, connecting API actions to redux state.
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUsers(state) {
      state.rows = initialState.rows;
    },
    clearUser(state) {
      state.row = initialState.row;
    },
    clearErrors(state) {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { rows, total } = action.payload.data;
      state.rows = rows;
      state.total = total;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.locations = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    builder.addCase(getAvailability.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.availability = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const { clearUsers, clearUser, clearErrors } = usersSlice.actions;

export default usersSlice.reducer;
