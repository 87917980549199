import React from 'react';
import { Input } from 'reactstrap';
import { useFormikContext } from 'formik';

import { DateInput, MultiSelect, Select, Time } from '~components/FormFields/Inputs';
import { DateRange, SearchProp, TimeRange } from '~components/FormFields/Search';
import Icon, { faMagnifyingGlass } from '~components/Icon';

interface ComponentProps extends SearchProp {
  id: string;
  value: any;
  includeCaption?: boolean;
  className?: string;
  inline?: boolean;
  disabled?: boolean;
  onKeyDown?: (key: string) => void;
  [key: string]: any;
}

const SearchField: React.FC<ComponentProps> = ({
  id,
  value,
  type,
  disabled = false,
  includeCaption = true,
  caption,
  inline = true,
  placeholder,
  className = '',
  selectorOptions,
  options,
  onKeyDown,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  let input;
  const inputClassName = `${className}${disabled ? ' bg-disabled' : ''}`;
  switch (type) {
    case 'text-like': {
      input = (
        <div className={inputClassName}>
          <Input
            type="text"
            name={id}
            id={id}
            className="border-0"
            value={value ?? ''}
            onChange={({ target: { name, value } }) => setFieldValue(name, value)}
            onKeyDown={(event) => onKeyDown && onKeyDown(event.key)}
            placeholder={placeholder}
            disabled={disabled}
            data-cy={`${id}-input`}
          />
          <Icon className="ms-1 me-2" icon={faMagnifyingGlass} />
        </div>
      );
      break;
    }
    case 'lookup': {
      input = (
        <div className={inputClassName}>
          {includeCaption && (
            <>
              <span className="ms-2 me-1 fw-bold">{caption}</span>
              <span className="ms-1">=</span>
            </>
          )}
          <Select
            {...props}
            type="select"
            name={id}
            id={id}
            value={value ?? ''}
            className={`${!inline ? 'w-100 ps-3' : ''} border-0`}
            onChange={setFieldValue}
            selectorOptions={selectorOptions}
            options={options}
            data-cy={`${id}-lookup`}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      );
      break;
    }
    case 'multi-lookup': {
      input = (
        <div className={inputClassName} data-cy={`${id}-multiselect`}>
          {includeCaption && (
            <>
              <span className="ms-2 me-1 fw-bold">{caption}</span>
              <span className="ms-1">=</span>
            </>
          )}
          <MultiSelect
            {...props}
            type="select"
            name={id}
            id={id}
            value={value ?? ''}
            className={`${!inline ? 'w-100' : ''}`}
            selectorOptions={selectorOptions}
            options={options}
            disabled={disabled}
            data-cy={`${id}-multilookup`}
            setFieldValue={setFieldValue}
          />
        </div>
      );
      break;
    }
    case 'date-range': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <DateRange
            id={id}
            value={value}
            onChange={setFieldValue}
            disabled={disabled}
            inline={inline}
            {...props}
            data-cy={`${id}-date-range`}
          />
        </div>
      );
      break;
    }
    case 'time-range': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <TimeRange
            id={id}
            value={value}
            onChange={setFieldValue}
            onKeyDown={(key: string) => onKeyDown && onKeyDown(key)}
            disabled={disabled}
            inline={inline}
            {...props}
          />
        </div>
      );
      break;
    }
    case 'date': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <div className={`${inline ? 'd-inline-flex' : 'w-100 ps-3'}`}>
            <div
              className={`${!inline ? 'mt-1 mb-1' : ''}  d-flex align-items-center ${disabled ? 'bg-disabled' : ''}`}
            >
              <span className="me-2">on</span>
              <DateInput
                type="date"
                name={id}
                id={id}
                className="border-0 m-1"
                value={value}
                disabled={disabled}
                onChange={setFieldValue}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'time': {
      input = (
        <div className={inputClassName}>
          {includeCaption && <span className="ms-2 me-1 fw-bold">{caption}</span>}
          <div className={`${inline ? 'd-inline-flex' : 'w-100 ps-3'}`}>
            <div
              className={`${!inline ? 'mt-1 mb-1' : ''}  d-flex align-items-center ${disabled ? 'bg-disabled' : ''}`}
            >
              <span className="me-2">at</span>
              <Time
                type="time"
                name={id}
                id={id}
                className={`${disabled ? ' bg-disabled' : ''}`}
                value={value}
                onChange={setFieldValue}
                disabled={disabled}
                data-cy={`${id}-time`}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
  }

  return input;
};

export default SearchField;
