import { apiUsers } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'alerts';

const config = [{ actionType: 'getOutstanding', apiPath: `${apiUsers}/outstanding`, method: get }];

export const { getOutstanding } = createThunks(typePrefix, config);
