import {
  deriveAccountsDefinition,
  deriveAccountSettings,
  deriveClientAddress,
  deriveClientLink,
  deriveDuration,
  deriveServiceTypeDescription,
  deriveServiceTypeSelectorParams,
} from '~appointments/lib/derive';
import { onChangePackage, onChangeServiceType } from '~appointments/lib/onchange';
import {
  showClientDescription,
  showEquipment,
  showLocations,
  showMedications,
  showNDISRates,
  showPaymentAmount,
  showPaymentCollected,
  showTransport,
  showUserAvailability,
} from '~appointments/lib/show';
import { validateCareWorker, validateStartEndTimes, validateTasks } from '~appointments/lib/validate';

import { FieldProps } from '~components/FormFields';
import LabelDuration from '~components/LabelDuration';
import FormInputAccountSettings from '~appointments/components/FormInputAccountSettings';
import FormInputCareWorkers from '~appointments/components/FormInputCareWorkers';
import FormInputEquipments from '~appointments/components/FormInputEquipments';
import FormInputLocations from '~appointments/components/FormInputLocations';
import FormInputNDISRates from '~appointments/components/FormInputNDISRates';
import FormInputPackageSelector from '~appointments/components/FormInputPackageSelector';
import FormInputQualifications from '~appointments/components/FormInputQualifications';
import FormInputStartEndTimes from '~appointments/components/FormInputStartEndTimes';
import FormInputTasks from '~appointments/components/FormInputTasks';
import FormInputWorkerAppointments from '~appointments/components/FormInputWorkerAppointments';
import FormInputWorkerAvailability from '~appointments/components/FormInputWorkerAvailability';
import FormInputWorkerLeaveAvailability from '~appointments/components/FormInputWorkerLeaveAvailability';
import FormInputWorkerQualificationCheck from '~appointments/components/FormInputWorkerQualificationCheck';
import FormInputWorkerRosterNotes from '~appointments/components/FormInputWorkerRosterNotes';

import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';

import { selectCareQualificationLevels } from '~appointments/selectors/careQualificationLevels';
import { selectLocations } from '~appointments/selectors/locations';
import { selectAppointmentServiceTypes } from '~appointments/selectors/serviceTypes';
import { selectEquipments } from '~main/selectors/equipment';
import { selectMedications } from '~main/selectors/medications';
import { selectPaymentTypes } from '~main/selectors/paymentTypes';
import { selectTaskTypes } from '~main/selectors/taskTypes';

const commonFields: FieldProps = {
  id: {
    type: 'hidden',
  },
  status: {
    type: 'readonly',
    field: 'status.name',
    order: 100,
  },
  client_name: {
    type: 'readonly',
    field: 'client.full_name',
    caption: 'Client / Facility',
    deriveDescription: deriveClientAddress,
    className: 'text-left',
    deriveLink: deriveClientLink,
    required: true,
    colSize: 4,
    order: 200,
  },
  package_fixed_id: {
    type: 'custom',
    component: FormInputPackageSelector,
    dependencies: ['package_fixed_id', 'client_id', 'date'],
    onChange: onChangePackage,
    required: true,
    colSize: 6,
    order: 300,
  },
  client_description: {
    type: 'alert-info',
    field: 'client.description',
    colSize: 4,
    show: showClientDescription,
    order: 400,
  },
  location_override_id: {
    type: 'select',
    caption: 'Location',
    colSize: 4,
    description: 'Optional, defaults to client home address',
    order: 500,
    show: showLocations,
    selectorOptions: selectLocations,
  },
  service_type_id: {
    type: 'select-lazy',
    caption: 'Service Type',
    initialOption: { id: 'service_type', name: '' },
    selectorOptions: selectAppointmentServiceTypes,
    selectorActions: getServiceTypes,
    selectorParams: deriveServiceTypeSelectorParams,
    onChange: onChangeServiceType,
    deriveWarning: deriveServiceTypeDescription,
    order: 600,
    colSize: 4,
    required: true,
    manualReload: true,
  },
  locations: {
    type: 'custom',
    caption: 'Trip Points',
    component: FormInputLocations,
    dependencies: ['client_id', 'transport_pickup_id', 'transport_dropoff_id'],
    order: 700,
    colSize: 6,
    show: showTransport,
  },
  accounts_definitions: {
    type: 'custom',
    component: FormInputNDISRates,
    deriveValue: deriveAccountsDefinition,
    dependencies: ['client_id', 'service_type_id', 'accounts_definitions'],
    show: showNDISRates,
    order: 800,
  },
  qualification_level: {
    type: 'custom',
    caption: 'Qualifications Required',
    component: FormInputQualifications,
    dependencies: ['service_type_id', 'qualification_level'],
    selectorOptions: selectCareQualificationLevels,
    order: 900,
  },
  user_id: {
    type: 'custom',
    caption: 'Care Worker',
    component: FormInputCareWorkers,
    dependencies: ['client_id', 'user_id', 'date', 'id', 'repeat'],
    validateConfirm: validateCareWorker,
    colSize: 6,
    order: 910,
  },
  user_leave_availability: {
    type: 'custom',
    component: FormInputWorkerLeaveAvailability,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 920,
  },
  user_appointments: {
    type: 'custom',
    component: FormInputWorkerAppointments,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 6,
    order: 930,
  },
  user_qualification_check: {
    type: 'custom',
    component: FormInputWorkerQualificationCheck,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 940,
  },
  user_rostering_notes: {
    type: 'custom',
    component: FormInputWorkerRosterNotes,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 4,
    order: 950,
  },
  user_availability: {
    type: 'custom',
    component: FormInputWorkerAvailability,
    dependencies: ['date', 'qualification_level', 'user_id', 'repeat', 'repeat_start', 'repeat_weekday'],
    show: showUserAvailability,
    colSize: 6,
    order: 960,
  },
  start_end_time: {
    type: 'custom',
    caption: 'Start/End Times',
    colSize: 6,
    component: FormInputStartEndTimes,
    dependencies: ['start_time', 'end_time', 'ends_next_day'],
    validate: validateStartEndTimes,
    order: 1000,
  },
  duration: {
    type: 'custom',
    caption: 'Duration',
    component: LabelDuration,
    deriveValue: deriveDuration,
    className: 'fw-bold',
    colSize: 4,
    order: 1100,
  },
  payment_type: {
    type: 'radio',
    field: 'payment_type',
    selectorOptions: selectPaymentTypes,
    show: showPaymentCollected,
    caption: 'Payment Type',
    colSize: 4,
    order: 1190,
  },
  payment_amount: {
    type: 'currency',
    field: 'payment_amount',
    caption: 'Payment Collected',
    show: showPaymentAmount,
    colSize: 4,
    order: 1200,
  },
  accounts: {
    type: 'custom',
    caption: 'Accounts',
    component: FormInputAccountSettings,
    deriveValue: deriveAccountSettings,
    colSize: 6,
    order: 1300,
  },
  flexibility: {
    type: 'switch',
    caption: 'Flexible with Time',
    order: 1400,
  },
  tasks: {
    type: 'custom',
    field: 'task_ids',
    caption: 'Tasks',
    labelCaption: 'Max 45',
    colSize: 6,
    component: FormInputTasks,
    selectorOptions: selectTaskTypes,
    validate: validateTasks,
    order: 1500,
  },
  medications: {
    type: 'multi-checkbox',
    field: 'medication_ids',
    caption: 'Medications',
    colSize: 3,
    selectorOptions: selectMedications,
    show: showMedications,
    order: 1600,
  },
  equipment_details: {
    type: 'custom',
    caption: 'Equipment Details',
    colSize: 6,
    component: FormInputEquipments,
    selectorOptions: selectEquipments,
    show: showEquipment,
    order: 1700,
  },
  notes: {
    type: 'textarea',
    caption: 'Notes',
    placeholder: 'Instructions for care workers, specific to this appointment',
    order: 1800,
  },
};

export { commonFields };
