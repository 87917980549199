import { RootState } from 'store';

const WeeklyPlannerState = (state: RootState) => state.weeklyPlanner.weeklyPlanner;

export const selectLoading = (state: RootState) => WeeklyPlannerState(state).loading;
export const selectOptions = (state: RootState) => WeeklyPlannerState(state).options;
export const selectParams = (state: RootState) => WeeklyPlannerState(state).params;

export const selectDate = (state: RootState) => WeeklyPlannerState(state).date;
export const selectViewType = (state: RootState) => WeeklyPlannerState(state).viewType;
export const selectRangeType = (state: RootState) => WeeklyPlannerState(state).rangeType;

export const selectOpenAppointmentDetailsId = (state: RootState) => WeeklyPlannerState(state).openAppointmentDetailsId;
export const selectFocusedAppointment = (state: RootState) => WeeklyPlannerState(state).focused;
export const selectFocusOptions = (state: RootState) => WeeklyPlannerState(state).focusOptions;
export const selectOrderBy = (state: RootState) => WeeklyPlannerState(state).orderBy;
export const selectPublishDate = (state: RootState) => WeeklyPlannerState(state).publishDate;
export const selectPublishEstimates = (state: RootState) => WeeklyPlannerState(state).publishEstimates;
export const selectErrorResponse = (state: RootState) => WeeklyPlannerState(state).error;
export const selectFocusedWorkerId = (state: RootState) => WeeklyPlannerState(state).focusedWorkerId;
export const selectImportedAppointments = (state: RootState) => WeeklyPlannerState(state).importedAppointments;
