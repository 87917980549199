import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Input } from 'reactstrap';

import Icon, { faQuestion, faXmark } from '~components/Icon';

import { updateBiddingWorkers } from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedAppointment } from '~weekly-planner/selectors';

interface ComponentProps {
  workerId: string;
}

const BiddingCard: React.FC<ComponentProps> = ({ workerId }) => {
  const dispatch = useAppDispatch();
  const { appointment, newBidders } = useAppSelector(selectFocusedAppointment);
  const { bidding_user_ids, rejected_user_ids } = appointment || {};

  const handleCheckboxChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBiddingWorkers({ workerIds: [workerId], isChecked: checked }));
  };

  const rejected = rejected_user_ids?.includes(workerId);
  const pending = bidding_user_ids?.includes(workerId);
  const existingBidder = rejected || pending;
  const checked = newBidders?.includes(workerId) ?? false;

  const getStatus = () => {
    if (rejected) return { icon: faXmark, tooltip: 'Rejected', tag: 'rejected' };
    if (pending) return { icon: faQuestion, tooltip: 'Pending', tag: 'pending' };
    return null;
  };

  const { icon, tooltip, tag } = getStatus() ?? {};

  return (
    <div className="bidding-card">
      {existingBidder && icon && (
        <div className={`status ${tag}`} title={tooltip}>
          <Icon icon={icon} size="2x" />
        </div>
      )}
      {!existingBidder && (
        <Input
          type="checkbox"
          name={`bidding-${workerId}`}
          id={workerId}
          checked={checked}
          onChange={handleCheckboxChange}
        />
      )}
    </div>
  );
};

export default BiddingCard;
