import React, { CSSProperties } from 'react';
import { BeatLoader, GridLoader } from 'react-spinners';

export interface ComponentProps {
  size?: number;
  margin?: number;
  color?: string;
  loading: boolean;
  className?: string;
  speed?: number;
  type?: 'grid' | 'beat';
  style?: CSSProperties;
}

const Spinner: React.FC<ComponentProps> = ({
  type = 'grid',
  size = 20,
  className = '',
  speed = 1,
  style,
  ...props
}) => {
  let loader;
  switch (type) {
    case 'beat': {
      loader = <BeatLoader size={size} speedMultiplier={speed} {...props} data-cy="loading-spinner" />;
      break;
    }
    case 'grid':
    default: {
      loader = <GridLoader size={size} speedMultiplier={speed} {...props} data-cy="loading-spinner" />;
      break;
    }
  }

  return (
    <div className={`spinner d-flex justify-content-center ${className}`} style={style} data-cy="loading-spinner">
      {loader}
    </div>
  );
};

export default Spinner;
