import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Input, Row } from 'reactstrap';

import { InputProps } from '~components/FormFields';
import * as FormInput from '~components/FormFields/Inputs';
import Icon, { faChevronDown, faChevronUp } from '~components/Icon';

import { selectLeaveCodes } from '~main/selectors/leaveCodes';

function FormInputAccountSettings({ value = {}, disabled, onChange }: InputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    chargeable_travel = '',
    chargeable_travel_type = '',
    break_duration = '',
    leave_entry_id = '',
    exclude_from_payroll = '',
  } = value;

  const chargeTravelTypes = [
    { id: 'KM', name: 'KM' },
    { id: 'MINS', name: 'Mins' },
    { id: 'none', name: 'None' },
    { id: 'total', name: '$ Amount' },
  ];

  const labelSm = 4;

  return (
    <Card className="rounded-0">
      <CardHeader
        className="d-flex justify-content-between border-0"
        type={'button'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{isOpen ? 'Hide' : 'Show'} Settings</div>
        <Icon icon={isOpen ? faChevronUp : faChevronDown} className="ms-2" />
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Row className="mb-1">
            <Col sm={labelSm}>Exclude from Payroll</Col>
            <Col>
              <FormInput.Switch
                type="switch"
                disabled={disabled}
                id="exclude_from_payroll"
                name="exclude_from_payroll"
                value={exclude_from_payroll ?? ''}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col sm={labelSm}>Leave Type</Col>
            <Col>
              <FormInput.Select
                type="select"
                name="leave_entry_id"
                id="leave_entry_id"
                disabled={disabled}
                value={leave_entry_id ?? ''}
                onChange={onChange}
                selectorOptions={selectLeaveCodes}
              />
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col sm={labelSm}>Break Duration</Col>
            <Col className="d-flex align-items-center">
              <Input
                type="number"
                name="break_duration"
                id="break_duration"
                disabled={disabled}
                value={break_duration ?? ''}
                className="w-25 me-1"
                onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
                data-cy={'break_duration-input'}
              />
              <span className="ms-2">mins</span>
            </Col>
          </Row>
          <Row className="mb-1 align-items-center">
            <Col sm={labelSm}>Chargeable Travel</Col>
            <Col className="d-flex">
              <Input
                type="number"
                name="chargeable_travel"
                id="chargeable_travel"
                disabled={disabled}
                value={chargeable_travel ?? ''}
                className="w-25 me-1"
                onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
                data-cy={'chargeable_travel-input'}
              />
              <FormInput.Select
                type="select"
                name="chargeable_travel_type"
                id="chargeable_travel_type"
                disabled={disabled}
                className="w-50"
                value={chargeable_travel_type ?? ''}
                onChange={onChange}
                options={chargeTravelTypes}
              />
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default FormInputAccountSettings;
