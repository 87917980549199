import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { sendSMS } from '~care-workers/actions';

import ErrorResponse from './ErrorResponse';

interface ComponentProps {
  isOpen?: boolean;
  onClose: () => void;
  user: { id: number | string; full_name: string };
  sendAction: any;
}

const SMSDialog: React.FC<ComponentProps> = ({ isOpen = false, onClose, user, sendAction = sendSMS }) => {
  const { id, full_name } = user;
  const [smsText, setSmsText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [hasUpdated, setUpdated] = useState<boolean>(false);

  const dispatch = useDispatch();

  //Max Characters within a single SMS text / 'block'
  const smsBlock = 160;

  //Max SMS Texts / 'blocks' that can be sent in one group
  const maxBlocks = 5;

  const blocks = Math.floor(smsText.length / smsBlock) + 1;
  const characterError = blocks > maxBlocks;
  const displayCharacters = `${smsText.length}/${blocks}`;

  const checkError = () => {
    if (smsText.length < 1 || characterError || loading || !hasUpdated) return true;
    return false;
  };

  const handleToggle = () => {
    onClose();
    setSmsText('');
    setSendSuccess(false);
  };

  const onChange = (value: string) => {
    setSmsText(value);
    setUpdated(true);
    setSendSuccess(false);
  };

  const onSubmit = async () => {
    setUpdated(false);
    setLoading(true);
    const { payload, error } = await dispatch(sendAction({ id, message: smsText }));

    if (payload?.success) {
      setSendSuccess(true);
    } else {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} className="sms-dialog">
      <ModalHeader className="bg-success text-white btn-close-white" data-bs-theme="dark" toggle={handleToggle}>
        Send Message
      </ModalHeader>
      <ModalBody>
        <div>
          <ErrorResponse message={error} />
          <p>Send SMS to: {full_name}</p>

          <Input
            type="textarea"
            id={`sms-text-area`}
            value={smsText}
            onChange={({ target: { value } }) => onChange(value)}
          />
          <div className="text-end mt-2 mb-0">
            <p>
              {characterError && <small className="pe-1 text-danger fw-bold">Max Characters is 800! (5 Blocks) </small>}
              {displayCharacters}
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {sendSuccess && <p>Message successfully sent!</p>}
        <Button disabled={checkError()} onClick={onSubmit}>
          Submit
        </Button>
        <Button disabled={loading} onClick={() => onChange('')}>
          Clear
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SMSDialog;
