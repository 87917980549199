import dayjs from 'dayjs';
import { store } from 'store';

import { AuthTags } from '~constants/authTags';
import { PaymentTypes } from '~constants/maps';

export const showUserAvailability: (values: any) => boolean = ({ date, repeat, repeat_start, user_id, touched }) => {
  // Hide if user and date (or repeat_start) is not selected
  let hide = true;
  if (repeat) hide = !user_id || !repeat_start;
  else hide = !user_id || !date;
  // Show if not hide and user_id/date/repeat_start has been touched/changed
  return !hide && (touched?.user_id === true || touched?.date === true || touched?.repeat_start === true);
};

export const showNDISRates: (values: any) => boolean = ({ client_id, service_type_id }) => {
  const state = store.getState();
  const { login } = state.main;
  const { serviceTypes } = state.appointments;
  const { tags } = login ?? {};

  const authorisedToView =
    tags?.includes(AuthTags.APPOINTMENTS.UPDATE) && tags?.includes(AuthTags.APPOINTMENTS.NDIS.VIEW);

  if (!service_type_id || !client_id || !authorisedToView) return false;
  const serviceType = serviceTypes.rows.find(({ id }) => parseInt(service_type_id) === id);
  if (serviceType && serviceType.ndis_support_item > 0) return true;
  return false;
};

export const showTimeMarked: (values: any) => boolean = ({ date }) => dayjs(date) <= dayjs();

export const showAppointmentDateOrLess: (values: any) => boolean = ({ date }) => dayjs(date) <= dayjs();

export const showTransport: (values: any) => boolean = ({ service_type_id }) => {
  const state = store.getState();
  const { login } = state.main;
  const { serviceTypes } = state.appointments;

  const settings = login.settings;
  const serviceType = serviceTypes.rows.find(({ id }) => parseInt(service_type_id) === id);
  return settings?.SYSTEM_2022_ENABLE_TRANSPORT === true && serviceType?.allow_transport === true;
};

export const showLocations: (values: any) => boolean = () => {
  const state = store.getState();
  const { login } = state.main;
  const settings = login.settings;
  return settings?.SYSTEM_2022_ENABLE_LOCATIONS === true;
};

export const showClientDescription: (values: any) => boolean = () => {
  const state = store.getState();
  const { login } = state.main;
  return login?.tags?.includes(AuthTags.APPOINTMENTS.UPDATE) ?? false;
};

export const showPaymentCollected: (values: any) => boolean = () => {
  const state = store.getState();
  const { login } = state.main;
  return login?.tags?.includes(AuthTags.APPOINTMENTS.PAYMENTS.VIEW) ?? false;
};

export const showEquipment: (values: any) => boolean = () => {
  const state = store.getState();
  const { login } = state.main;
  return login?.tags?.includes(AuthTags.APPOINTMENTS.EQUIPMENT.VIEW) ?? false;
};
export const showMedications: (values: any) => boolean = () => {
  const state = store.getState();
  const { login } = state.main;
  return login?.tags?.includes(AuthTags.APPOINTMENTS.MEDICATIONS.VIEW) ?? false;
};

export const showPaymentAmount: (values: any) => boolean = ({ payment_type }) => {
  return (
    payment_type !== undefined && payment_type !== null && payment_type !== '' && payment_type !== PaymentTypes.NONE
  );
};
