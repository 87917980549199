import React, { useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';

function FormInputDistanceTravelled({ id, value = {}, disabled, onChange }: InputProps) {
  const [override, setOverride] = useState(false);
  const { distance_to_client, distance_with_client } = value;

  const labelSm = 6;

  return (
    <>
      <Row className="align-items-center" data-id="distance_to_client">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>TO Client</Col>
          <Col className="d-flex align-items-center">
            {override && (
              <>
                <Input
                  id="distance_to_client"
                  type="number"
                  name="distance_to_client"
                  disabled={disabled}
                  className="ms-2 me-2"
                  value={distance_to_client ?? ''}
                  onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
                  data-cy={`distance_to_client-input`}
                />
                <span className="ms-2">kms</span>
              </>
            )}
            {!override && (
              <FormInput.ReadOnly
                type="readonly"
                name={id}
                id={id}
                value={distance_to_client ? `${distance_to_client} kms` : 'Not entered'}
              />
            )}
          </Col>
        </Col>
        <Col>
          {!override && (
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={() => setOverride(!override)}
              data-cy={`distance_to_client-override-button`}
            >
              Override
            </Button>
          )}
        </Col>
      </Row>
      <Row className="align-items-center" data-id="distance_with_client">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>WITH Client</Col>
          <Col className="d-flex align-items-center">
            {override && (
              <>
                <Input
                  id="distance_with_client"
                  type="number"
                  name="distance_with_client"
                  disabled={disabled}
                  className="ms-2 me-2"
                  value={distance_with_client ?? ''}
                  onChange={({ target: { name, value } }) => onChange && onChange(name, value)}
                  data-cy={'distance_with_client-input'}
                />
                <span className="ms-2">kms</span>
              </>
            )}
            {!override && (
              <FormInput.ReadOnly
                type="readonly"
                name={id}
                id={id}
                value={distance_with_client ? `${distance_with_client} kms` : 'Not entered'}
              />
            )}
          </Col>
        </Col>
        <Col />
      </Row>
    </>
  );
}

export default FormInputDistanceTravelled;
