import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'hooks';
import AppointmentActivities from '~appointments/AppointmentActivities';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';

import generateYupSchema from '~libs/yupValidation';

import Button, { ButtonProps } from '~components/Button';
import { FieldProps, Fields } from '~components/FormFields';

import { selectCurrentAppointment } from '~appointments/selectors/appointments';

const AppointmentForm: React.FC<any> = ({
  authTag,
  fields,
  defaultValues = {},
  overrideValues = {},
  canSave,
  save,
  isNew,
  showActivityLogs = false,
  buttonProps = {} as ButtonProps,
}) => {
  const [invalidFields, setInvalidFields]: [string[], any] = useState([]);

  const appointment = useAppSelector(selectCurrentAppointment);

  const fieldDefinitions = useMemo(
    () => Object.entries(fields as FieldProps).map(([key, props]) => ({ key, ...props })),
    [fields],
  );

  const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T) =>
    Object.entries(values).reduce((changes, [key, value]) => {
      if (!isEqual(initialValues[key], value)) changes[key as keyof T] = value;
      return changes;
    }, {} as Partial<T>);

  const reduceFieldValues = (
    fields: any,
    fillValue = false,
    defaults: Record<string, any> = {},
    overrides: Record<string, any> = {},
  ) => {
    return Object.keys(fields).reduce((values, index) => {
      const { key, field, dependencies = [] } = fields[index];
      const id = field ?? key;

      [id, ...dependencies].forEach((id: string) => {
        const value = overrides[id];
        if (value !== undefined && value !== null) values[id] = value;
        else if (fillValue) values[id] = '';
      });

      return values;
    }, defaults);
  };

  // Send request to update record, include ID if editing.
  const onSave = async (values: any) => {
    const valid = invalidFields.length === 0;
    if (valid && save) save(values);
  };

  const initialValues = appointment
    ? { ...appointment, ...defaultValues }
    : reduceFieldValues(fieldDefinitions, true, defaultValues);
  const initialChangedValues = reduceFieldValues(fieldDefinitions, false, {}, overrideValues);

  /**
   * Dynamic generation of yup validation schema
   */
  const fieldsObject: FieldProps = fieldDefinitions.reduce((acc, field) => {
    const { key, ...fieldProps } = field;
    acc[key] = fieldProps;
    return acc;
  }, {} as FieldProps);

  const validationSchema = generateYupSchema(fieldsObject);

  const handleYupValidation = async (values: any) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setInvalidFields([]);
    } catch (err: any) {
      if (err.inner) {
        const errors = err.inner.map((e: any) => e.path);
        console.log(errors);
        setInvalidFields(errors);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues, ...initialChangedValues }}
        validationSchema={validationSchema}
        validate={handleYupValidation}
        validateOnMount={false}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSave(getChangedValues(values, initialValues));
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, setFieldTouched, touched, isSubmitting }) => {
          const hasInitialized = useRef(false);

          useEffect(() => {
            // Prevent re-triggering if already initialized to avoid overrides wiping out user inputs
            if (hasInitialized.current) {
              return;
            }

            if (fields && overrideValues && Object.keys(initialValues).length > 0) {
              const overlappingKeys = Object.keys(overrideValues).filter((key) =>
                Object.keys(initialValues).includes(key),
              );

              if (overlappingKeys.length > 0) {
                overlappingKeys.forEach((key) => {
                  const value = overrideValues[key];
                  const field = fields.find(
                    (field: { key: any; field: any }) => field.key === key || field.field === key,
                  );

                  if (field && field?.onChange) {
                    const fieldKey = field.field || field.key;

                    if (!touched[fieldKey]) {
                      setFieldValue(fieldKey, value);
                      field.onChange({ ...initialValues, ...overrideValues });
                      hasInitialized.current = true;
                    }
                  }
                });
              }
            }
          }, [overrideValues, fields, initialValues, touched, setFieldValue]);

          return (
            <Form>
              <Fields
                authTag={authTag}
                definitions={fieldDefinitions}
                row={isNew ? overrideValues : appointment}
                invalidFields={invalidFields}
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />
              <div>
                {canSave && (
                  <Button
                    size="sm"
                    disabled={isSubmitting || !canSave()}
                    type="submit"
                    {...buttonProps}
                    dataCy="appointment-submit-button"
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      {showActivityLogs && <AppointmentActivities className="p-2 ps-4 pe-4 mt-2 mb-5" />}
    </>
  );
};

export default AppointmentForm;
