import { apiClientProspects } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

import { typePrefix as prefix } from '~main/actions/clients';

export const typePrefix = `${prefix}-packages`;

const config = [
  {
    actionType: 'getProspectStatistics',
    apiPath: `${apiClientProspects}/statistics`,
    method: get,
  },
];

export const { getProspectStatistics } = createThunks(typePrefix, config);
