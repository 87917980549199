import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';

import Icon, { faEye, faEyeSlash } from '~components/Icon';

import { toggleOption } from '~weekly-planner/reducers/weeklyPlanner';

import { selectOptions } from '~weekly-planner/selectors';

const UnallocatedViewMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { unallocatedDuration, unallocatedQualifications, unallocatedServiceType, unallocatedSuburb } =
    useAppSelector(selectOptions);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const renderItem = (onClick: (value?: any) => void, iconToggle: boolean, label: any) => (
    <DropdownItem toggle={false} onClick={onClick}>
      <Icon icon={iconToggle ? faEye : faEyeSlash} className="mt-1" />
      <Label className="ms-2 mb-0">{label}</Label>
    </DropdownItem>
  );

  return (
    <Dropdown isOpen={isViewOpen} toggle={() => setIsViewOpen(!isViewOpen)} direction="down" className="filter-menu">
      <DropdownToggle size="md" className="ms-2" type="submit" onClick={() => setIsViewOpen(!isViewOpen)}>
        <Icon className="me-2" icon={faEye} />
        View Options
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>View Options</DropdownItem>
        {renderItem(
          () => dispatch(toggleOption({ key: 'unallocatedSuburb', toggle: !unallocatedSuburb })),
          unallocatedSuburb,
          'Client Suburb',
        )}
        {renderItem(
          () => dispatch(toggleOption({ key: 'unallocatedDuration', toggle: !unallocatedDuration })),
          unallocatedDuration,
          'Duration',
        )}
        {renderItem(
          () => dispatch(toggleOption({ key: 'unallocatedQualifications', toggle: !unallocatedQualifications })),
          unallocatedQualifications,
          'Qualifications',
        )}
        {renderItem(
          () => dispatch(toggleOption({ key: 'unallocatedServiceType', toggle: !unallocatedServiceType })),
          unallocatedServiceType,
          'Service Type',
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default UnallocatedViewMenu;
