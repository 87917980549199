import React, { useCallback, useEffect, useState } from 'react';
import { AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { LocationInfo } from '~types/dashboard';

import { formatDuration } from '~libs/dayjs';

import Icon, { faCalendar, faHouse, faPersonWalkingArrowRight } from '~components/Icon';

interface ComponentProps {
  data: LocationInfo;
  openId: number | null;
}

const Marker: React.FC<ComponentProps> = ({ data, openId }) => {
  const { type, id, full_name, location, appointments } = data;
  const [openWindow, setOpenWindow] = useState<number | null>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleClick = useCallback((id: number) => {
    setOpenWindow((prev) => (prev === id ? null : id));
  }, []);

  const handleClose = useCallback(() => {
    setOpenWindow(null);
  }, []);

  useEffect(() => {
    setOpenWindow(openId);
  }, [openId]);

  if (isNaN(Number(location?.latitude)) || isNaN(Number(location?.longitude))) {
    return null;
  }

  const calcLatLng = (lat: string, lng: string) => {
    if (isNaN(Number(lat)) || isNaN(Number(lng))) return;
    return { lat: Number(lat), lng: Number(lng) };
  };

  const upcomingAppointments = appointments?.filter((appt) => appt.type === 'Upcoming') || [];
  const activeAppointment = appointments?.find((appt) => appt.type === 'Active');

  if (type === 'user') {
    let colours = {
      backgroundColour: '#ffffff',
      glyphColour: '#000000',
      borderColour: '#000000',
    };

    let icon = faHouse;

    if (activeAppointment) {
      colours = {
        backgroundColour: '#ff0000',
        glyphColour: '#ffffff',
        borderColour: '#000000',
      };
      icon = faPersonWalkingArrowRight;
    } else if (upcomingAppointments.length > 0) {
      colours = {
        backgroundColour: '#07e83b',
        glyphColour: '#ffffff',
        borderColour: '#000000',
      };
      icon = faCalendar;
    }

    const renderActivitySection = () => {
      if (upcomingAppointments || activeAppointment) {
        return (
          <div>
            {activeAppointment && (
              <>
                {
                  <div>
                    <span className="title">On Job:</span>
                    <span>Client: {activeAppointment.full_name}</span>
                    <span>Status: {activeAppointment.status.name}</span>
                    <span>
                      Times: {activeAppointment.start_time} - {activeAppointment.end_time}
                    </span>
                    <span>Duration: {formatDuration(activeAppointment.duration)}</span>
                  </div>
                }
              </>
            )}
            <hr />
            {upcomingAppointments?.length > 0 && (
              <>
                <span className="title">Upcoming Appointments:</span>
                {upcomingAppointments.map((appt) => (
                  <div key={appt.id}>
                    <span>Client: {appt.full_name}</span>
                    <span>Status: {appt.status.name}</span>
                    <span>
                      Times: {appt.start_time} - {appt.end_time}
                    </span>
                    <span>Duration: {formatDuration(appt.duration)}</span>
                  </div>
                ))}
              </>
            )}
            {upcomingAppointments.length === 0 && (
              <>
                <span>No Upcoming Appointments.</span>
              </>
            )}
          </div>
        );
      }
    };

    return (
      <>
        <AdvancedMarker
          key={`user-${id}`}
          ref={markerRef}
          position={calcLatLng(location?.latitude, location?.longitude)}
          clickable={true}
          onClick={() => handleClick(id)}
          className="worker-pin"
        >
          <Pin
            background={colours.backgroundColour}
            glyphColor={colours.glyphColour}
            borderColor={colours.borderColour}
          >
            <Icon icon={icon} />
          </Pin>
        </AdvancedMarker>
        {openWindow === id && (
          <InfoWindow className="info-panel" anchor={marker} onClose={() => handleClose()}>
            <span className="title">Worker Info</span>
            <span>Worker: {full_name}</span>
            <span>Currently: {activeAppointment ? 'On Job' : 'At Home'}</span>
            {renderActivitySection()}
          </InfoWindow>
        )}
      </>
    );
  }

  if (type === 'client') {
    return (
      <>
        {upcomingAppointments &&
          upcomingAppointments.map((appt) => {
            return (
              <>
                <AdvancedMarker
                  key={`client-${id}`}
                  ref={markerRef}
                  position={calcLatLng(appt.location?.latitude, appt.location?.longitude)}
                  clickable={true}
                  onClick={() => handleClick(id)}
                >
                  <div className="point-pin upcoming" />
                </AdvancedMarker>
                {openWindow === id && (
                  <InfoWindow className="info-panel" anchor={marker} onClose={() => handleClose()}>
                    <span className="title">Upcoming Appointment</span>
                    <span>Client: {full_name}</span>
                    <span>With Worker: {appt.full_name} </span>
                    <span>
                      Times: {appt.start_time} - {appt.end_time}
                    </span>
                    <span>Duration: {formatDuration(appt.duration)}</span>
                  </InfoWindow>
                )}
              </>
            );
          })}
      </>
    );
  }
  return <></>;
};

export default Marker;
