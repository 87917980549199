import { get, set } from '~libs/localstorage';
import { buildSlice, CommonState } from '~libs/reduxUtils';
import { Appointment } from '~weekly-planner/lib/common';

import { getAll, typePrefix } from '~weekly-planner/actions/appointments/unallocated';

interface State extends CommonState<Appointment> {
  params: { [key: string]: any };
  options: { [key: string]: boolean };
  count?: number;
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  params: { limit: 30 },
  options: {
    unallocatedDuration: false,
    unallocatedQualifications: false,
    unallocatedServiceType: true,
    unallocatedSuburb: false,
    ...get('weekly-planner-unallocated-appointment-view-options'),
  },
};

const slice = buildSlice<State, Appointment>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.loading = 'fulfilled';
      state.rows = data.rows;
      state.count = data.total;
      state.hasLoaded = true;
    });
  },
  customActions: {
    toggleOption(state, action) {
      const { key, toggle } = action.payload;
      state.options[key] = toggle;
      set('weekly-planner-unallocated-appointment-view-options', state.options);
    },
    updateParams(state, action) {
      state.params = { ...state.params, ...action.payload };
    },
  },
});

export const { clear, updateParams, toggleOption } = slice.actions;

export default slice.reducer;
