import React, { PropsWithChildren } from 'react';

import Icon, { IconProp, SizeProp } from '~components/Icon';

interface ComponentProps extends PropsWithChildren {
  className?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  dataCy?: string;
}

const ContainerHeader: React.FC<ComponentProps> = ({ className, icon, iconSize = '1x', children, dataCy = '' }) => {
  return (
    <div
      className={`d-flex flex-row justify-content-between ps-3 pe-3 header${className !== undefined ? ` ${className}` : ''}`}
    >
      <div className="d-flex flex-row align-items-center">
        {icon && <Icon size={iconSize} icon={icon} className="me-2" />}
        <h3 data-cy={dataCy}>{children}</h3>
      </div>
    </div>
  );
};

export default ContainerHeader;
