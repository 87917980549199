import React from 'react';
import { Form, Formik, FormikValues } from 'formik';

import { useStorage } from '~libs/localstorage';
import { prepareSearchParam } from '~libs/searchField';

import Button from '~components/Button';
import { DropdownCheckbox, SearchField, SearchProps } from '~components/FormFields/Search';
import Icon, { faMagnifyingGlass } from '~components/Icon';

interface ComponentProps {
  fields: SearchProps;
  onSubmit: (values: { [key: string]: any }) => void;
  initialValues?: Record<string, any>;
  storageKey?: string;
}

const SearchBar: React.FC<ComponentProps> = ({ fields, initialValues = {}, onSubmit, storageKey = 'generic' }) => {
  // Initial filters are set based on the defaultShow property
  const [openFilters, setOpenFilters] = useStorage(
    storageKey,
    Object.entries(fields)
      .filter(([, { defaultShow }]) => defaultShow)
      .map(([key]) => key),
  );

  const formikInitialValues = Object.keys(fields).reduce(
    (values, key) => {
      const { field, type } = fields[key];
      if (field && !(field instanceof Array)) {
        let value = initialValues[field];
        switch (type) {
          case 'text-like': {
            if (value instanceof Object) {
              value = value.like.replace(/^%|%$/g, '');
            }
            break;
          }
          default: {
            break;
          }
        }
        values[key] = value ?? '';
      }
      return values;
    },
    {} as Record<string, any>,
  );

  // On search, prepare search values based on field type
  const submit = (values: FormikValues) => {
    let params: { [key: string]: any } = {};
    Object.entries(values).forEach(([key, value]) => {
      const definition = fields[key];
      if (!definition || value.length === 0) return;
      const { type, field } = definition;
      params = {
        ...params,
        ...prepareSearchParam(type, field, value),
      };
    });
    onSubmit(params);
  };

  const definitions = Object.entries(fields);
  const buildPills = (values: FormikValues, setFieldValue: any) =>
    definitions
      .filter(([key, { persist }]) => openFilters.includes(key) || persist)
      .map(([key, props], index) => (
        <SearchField
          key={index}
          id={key}
          value={values[key]}
          className={`search-pill d-flex align-items-center border rounded bg-white me-2 mt-2 mb-2 ${props.type}`}
          setFieldValue={setFieldValue}
          {...props}
        />
      ));

  // Build filters to display, do not include filters which cannot be removed
  const filters = definitions
    .filter(([, { persist }]) => !persist)
    .map(([key, { caption }]) => ({ key, label: caption }));

  return (
    <Formik enableReinitialize initialValues={formikInitialValues} onSubmit={(values) => submit(values)}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="search-bar d-flex justify-content-between bg-light p-2" data-cy="search-area">
            <div className="d-flex flex-wrap">{buildPills(values, setFieldValue)}</div>
            <div className="d-flex m-2 align-items-center">
              <DropdownCheckbox
                caption="Filters"
                items={filters}
                values={openFilters}
                onClick={setOpenFilters}
                dataCy="search-filter-button"
              />
              <div>
                <Button size="sm" className="ms-2" type="submit">
                  <Icon className="me-2" icon={faMagnifyingGlass} data-cy="search-go-button" />
                  Go
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchBar;
