import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Card, CardBody, CardHeader, Col, Collapse, Input, Label, Row } from 'reactstrap';

import Button from '~components/Button';
import { InputProps } from '~components/FormFields';
import * as FormInput from '~components/FormFields/Inputs';
import Icon, { faChevronDown, faChevronUp } from '~components/Icon';

import { selectTaskClassifications } from '~main/selectors/taskClassifications';

interface OpenGroups {
  [key: string]: boolean;
}

interface ClassificationRow {
  id: number;
  name?: string;
}

function FormInputTasks({ id, value = [], disabled, selectorOptions, onChange }: InputProps) {
  const [override, setOverride] = useState(false);
  const [openGroups, setOpenGroups]: [OpenGroups, any] = useState({});
  const classifications = useAppSelector(selectTaskClassifications);

  const arrayValue = Array.isArray(value) ? value : [];
  const values = value instanceof Array ? value.map((optionId: string | number) => optionId.toString()) : [];

  let options: any[] = [];
  if (selectorOptions) options = useAppSelector(selectorOptions);

  useEffect(() => {
    const newGroups = { ...openGroups };
    if (arrayValue.length > 0 && options.length > 0) {
      arrayValue.forEach((val) => {
        const groupId = options.find(({ id }) => id.toString() === val)?.classification ?? null;
        if (groupId && newGroups[groupId] === undefined) newGroups[groupId] = true;
      });
    }
    setOpenGroups(newGroups);
  }, [value, options]);

  const setChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    const numeric = parseInt(name);

    const newValue = [...arrayValue];
    const index = newValue.indexOf(numeric);
    if (index > -1) newValue.splice(index, 1);
    else newValue.push(numeric);

    onChange && onChange(id, newValue);
  };

  const toggle = (id: number) => {
    setOpenGroups({
      ...openGroups,
      [id]: !openGroups[id],
    });
  };

  const buildGroupOptions = (id: number) => {
    const groupOptions = options.filter(({ classification }) => classification === id);
    return groupOptions?.map(({ id, name }, index) => {
      const reference = id.toString();
      return (
        <div key={index} className="d-flex">
          <Input
            type="checkbox"
            name={reference}
            id={reference}
            disabled={disabled}
            checked={values?.includes(reference) ?? false}
            onChange={setChange}
            data-cy={`tasks_${reference}-checkbox`}
          />
          <Label for={reference} className="ms-2 mb-0">
            {name}
          </Label>
        </div>
      );
    });
  };

  const groups = classifications.map(({ id, name }: ClassificationRow, index) => {
    const isOpen = openGroups[id];
    const groupOptions = buildGroupOptions(id);

    if (groupOptions.length === 0) return null;
    return (
      <Card key={index} className="rounded-0">
        <CardHeader className="d-flex justify-content-between border-0" type={'button'} onClick={() => toggle(id)}>
          <div>{name}</div>
          <Icon icon={isOpen ? faChevronUp : faChevronDown} className="ms-2" />
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>{groupOptions}</CardBody>
        </Collapse>
      </Card>
    );
  });

  return (
    <>
      {!override && (
        <Row className="align-items-center">
          <Col className="d-flex align-items-center">
            {!override && (
              <FormInput.MultiReadOnly
                type="multi-readonly"
                name={id}
                id={id}
                value={arrayValue.map((val: string) => parseInt(val))}
                selectorOptions={selectorOptions}
                dataCy={id}
              />
            )}
          </Col>
          <Col sm={2}>
            {!disabled && (
              <Button
                type="button"
                size="sm"
                color="success"
                onClick={() => setOverride(!override)}
                dataCy={`${id}-override-button`}
              >
                Override
              </Button>
            )}
          </Col>
        </Row>
      )}
      {override && <>{groups}</>}
    </>
  );
}

export default FormInputTasks;
