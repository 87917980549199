import React, { CSSProperties, useEffect } from 'react';
import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';

interface ComponentProps {
  id: string;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onDragOverChange?: (isOver: boolean, current?: any) => void;
}

const DroppableArea: React.FC<ComponentProps> = ({ id, children, className, style, onDragOverChange }) => {
  const { setNodeRef, isOver, active } = useDroppable({ id });

  // Notify parent about drag-over state changes
  useEffect(() => {
    if (onDragOverChange) {
      onDragOverChange(isOver, active?.data?.current);
    }
  }, [isOver, active, onDragOverChange]);

  const combinedClassNames = classNames(className, {
    'is-dragging-over': isOver,
    'is-dragging-origin': active?.data?.current?.containerId === id,
    'draggable-container': true,
  });

  return (
    <div ref={setNodeRef} id={id} className={combinedClassNames} style={style}>
      {children}
    </div>
  );
};

export default DroppableArea;
