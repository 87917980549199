import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { LoadingState } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~main/actions/address/regions';

interface RegionRow {
  id: number;
  name?: string;
}

interface RegionState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<RegionRow>;
  row?: RegionRow;
}

const initialState: RegionState = {
  loading: 'idle',
  error: null,
  rows: [],
};

export const regionsSlice = createSlice({
  name: typePrefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export default regionsSlice.reducer;
