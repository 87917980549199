import React from 'react';
import { useAppSelector } from 'hooks';

import Accordion from '~components/Accordion';
import {
  AwardAlerts,
  Conflicts,
  MissingQualifications,
  Rejected,
  SaveLoad,
  Statistics,
  UnallocatedAppointments,
} from '~weekly-planner/components/SideBar';

import { selectParams } from '~weekly-planner/selectors';
import { selectCount as selectConflictsCount } from '~weekly-planner/selectors/appointments/conflicts';
import { selectCount as selectMissingQualificationsCount } from '~weekly-planner/selectors/appointments/missingQualifications';
import { selectCount as selectRejectedCount } from '~weekly-planner/selectors/appointments/rejected';
import { selectUnallocatedCount } from '~weekly-planner/selectors/simulate';
import { selectAllChangesCount } from '~weekly-planner/selectors/unsaved';
import { selectAwardCount } from '~weekly-planner/selectors/users';

interface ComponentProps {
  onRefresh?: () => void;
}

const SideBar: React.FC<ComponentProps> = ({ onRefresh }) => {
  const { week_start } = useAppSelector(selectParams);
  const unallocatedCount = useAppSelector(selectUnallocatedCount);
  const awardCount = useAppSelector(selectAwardCount);
  const rejectedCount = useAppSelector(selectRejectedCount);
  const missingQualificationsCount = useAppSelector(selectMissingQualificationsCount);
  const changesCount = useAppSelector(selectAllChangesCount);
  const conflictCount = useAppSelector(selectConflictsCount);

  const buildTitle = (title: string, count: number | undefined) => {
    if (count && count > 0) return `${title} (${count})`;
    return title;
  };

  const items = [
    { header: buildTitle('Save/Load', changesCount), body: <SaveLoad weekStart={week_start} onRefresh={onRefresh} /> },
    { header: buildTitle('Conflicts', conflictCount), body: <Conflicts weekStart={week_start} /> },
    { header: buildTitle('Rejected Appointments', rejectedCount), body: <Rejected weekStart={week_start} /> },
    { header: buildTitle('Award Alerts', awardCount), body: <AwardAlerts weekStart={week_start} /> },
    { header: 'Statistics', body: <Statistics weekStart={week_start} /> },
    {
      header: buildTitle('Missing Qualifications', missingQualificationsCount),
      body: <MissingQualifications weekStart={week_start} />,
    },
    { header: buildTitle('Unallocated Appointments', unallocatedCount), body: <UnallocatedAppointments /> },
  ];

  return <Accordion className="side-bar-container" items={items} />;
};

export default SideBar;
