import { ColumnDef } from '@tanstack/react-table';
import { formatDateString } from 'tsx/libs/dayjs';

import { faEdit } from '~components/Icon';

export const commonColumns: ColumnDef<any>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    cell: (info) => info.getValue(),
    enableHiding: true,
  },
  {
    id: 'reference_number',
    accessorKey: 'reference_number',
    header: 'Reference Number',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
    meta: {
      link: ({ id }: any) => `/appointments/${id}`,
      hoverIcon: faEdit,
    },
  },
  {
    id: 'client.full_name',
    accessorKey: 'client.full_name',
    header: 'Client',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'date',
    accessorKey: 'date',
    header: 'Date',
    cell: (info) => formatDateString(info.getValue()),
    sortUndefined: 'last',
    sortingFn: 'datetime',
  },
  {
    id: 'status_id',
    accessorKey: 'status.name',
    header: 'Status',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
];

export const allColumns: ColumnDef<any>[] = [
  ...commonColumns,
  {
    id: 'user.full_name',
    accessorFn: (row) => row.user?.full_name ?? '',
    header: 'Care Worker',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'client.referrer_name',
    accessorFn: (row) => row.client?.referrer_name ?? '',
    header: 'Referrer',
    cell: (info) => info.getValue(),
  },
  {
    id: 'package_fixed.name',
    accessorFn: (row) => row.package_fixed?.name ?? '',
    header: 'Package',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
  {
    id: 'service_type.name',
    accessorFn: ({ service_type }) =>
      service_type !== undefined ? `${service_type.name}${service_type.deleted ? ' - DELETED' : ''}` : '',
    header: 'Service Type',
    cell: (info) => info.getValue(),
    sortUndefined: 'last',
  },
];

export default commonColumns;
