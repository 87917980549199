import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Collapse, Input, Table } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

import { InputProps } from '~components/FormFields';

import { selectAppointmentServiceTypeById } from '~appointments/selectors/serviceTypes';
import { selectNDISSupportItems } from '~main/selectors/ndisSupportItems';

function FormInputNDISRates({ id, value = {}, onChange }: InputProps) {
  const services = [
    {
      id: 'day',
      caption: 'Weekday Daytime/Individual Code',
    },
    {
      id: 'eve',
      caption: 'Weekday Evening',
    },
    {
      id: 'night',
      caption: 'Weekday Night',
    },
    {
      id: 'saturday',
      caption: 'Saturday',
    },
    {
      id: 'sunday',
      caption: 'Sunday',
    },
    {
      id: 'ph',
      caption: 'Public Holiday',
    },
  ];
  const serviceType = useAppSelector((state) =>
    selectAppointmentServiceTypeById(state, parseInt(value.service_type_id)),
  );
  const ndisItems = useAppSelector(selectNDISSupportItems);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Look for changes within service type ID
    const { client_id } = value;

    // If NDIS Support Item, query for NDIS Support Items from API, make component visible
    if (serviceType) {
      if (serviceType.ndis_support_item > 0 && client_id) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, [value.service_type_id]);

  useEffect(() => {
    // Override any new NDIS items returned from the API
    if (Object.keys(ndisItems).length > 0) {
      const newValue: { [key: string]: string | number } = {};
      Object.entries(ndisItems).forEach(([key, value]) => (newValue[key] = value));
      onChange && onChange(id, newValue);
    }
  }, [ndisItems]);

  const initialiseValue = () => {
    let newValue: { [key: string]: string | number } = {};
    if (value instanceof Object) newValue = { ...value };
    delete newValue.client_id;
    delete newValue.service_type_id;
    return newValue;
  };

  const setChange = (target: { name: string; value: any }) => {
    const { name, value: targetValue } = target;

    const newValue = initialiseValue();
    newValue[name] = targetValue;

    onChange && onChange(id, newValue);
  };

  const buildInput = (id: string, type: InputType) => (
    <Input
      type={type}
      name={id}
      id={id}
      value={value[id] ? value[id] : ''}
      onChange={({ target: { name, value } }) => {
        setChange({ name, value });
      }}
      data-cy={`${id}-input`}
    />
  );

  const bodyRows = services.map(({ id, caption }, index) => (
    <tr key={index}>
      <td className="align-middle">{caption}</td>
      <td className="d-flex align-items-center">
        <span className="me-2">$</span>
        <span className="w-50">{buildInput(`${id}_rate`, 'number')}</span>
        <span className="ms-2">/ hour</span>
      </td>
      <td className="align-middle">{buildInput(`${id}_code`, 'text')}</td>
    </tr>
  ));

  const headerClassName = 'ps-2 pe-2 border-bottom';

  return (
    <Collapse isOpen={isOpen}>
      <Table data-cy={`${id}-table`}>
        <thead>
          <tr>
            <th className={headerClassName}>Service</th>
            <th className={headerClassName}>Rate</th>
            <th className={headerClassName}>Code</th>
          </tr>
        </thead>
        <tbody>{bodyRows}</tbody>
      </Table>
    </Collapse>
  );
}

export default FormInputNDISRates;
