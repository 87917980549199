import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { InputProps } from 'tsx/components/FormFields';
import { dateInputFieldFormat, datePickerDisplayFormat } from 'tsx/libs/dayjs';

const DateInput: React.FC<InputProps> = ({
  id,
  value,
  placeholder,
  disabled = false,
  className,
  onChange,
  name,
  dataCy = null,
}) => {
  let dateValue = null;

  if (value) dateValue = dayjs(value, dateInputFieldFormat);

  const setChange = (value: Dayjs | null) => {
    onChange && onChange(id, value?.format(dateInputFieldFormat));
  };

  const dateClass = classNames('form-control', {
    [`${className}`]: className != undefined,
  });

  let dataCyId = `${id}-date`;
  if (dataCy) dataCyId = `${dataCy}-${dataCyId}`;

  return (
    <DesktopDatePicker
      value={dateValue}
      disabled={disabled}
      label={placeholder}
      name={name}
      format={datePickerDisplayFormat}
      className={dateClass}
      slotProps={{
        field: { shouldRespectLeadingZeros: true },
        textField: {
          inputProps: { 'data-cy': dataCyId },
          size: 'small',
        },
        actionBar: { actions: ['clear', 'today'] },
      }}
      onChange={(value) => setChange(value)}
    />
  );
};

export default DateInput;
