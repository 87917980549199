import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Spinner, { ComponentProps as SpinnerProps } from './Spinner';

interface ComponentProps extends SpinnerProps, PropsWithChildren {
  bgColor?: string;
  isTransparent?: boolean;
  style?: CSSProperties;
}

const ContainerLoading: React.FC<ComponentProps> = ({
  bgColor = 'dark',
  isTransparent = true,
  loading,
  style,
  children,
  ...props
}) => {
  const overlayClass = classNames('overlay', {
    transparent: isTransparent,
    [`bg-${bgColor}`]: typeof bgColor === 'string',
  });

  return (
    <div className="container-loading" style={style}>
      {loading && (
        <>
          <Spinner color="white" loading={loading} {...props} />
          <div className={overlayClass}></div>
        </>
      )}

      {children}
    </div>
  );
};

export default ContainerLoading;
