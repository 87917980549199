import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const UsersState = (state: RootState) => state.careWorkers.users;

export const selectUsers = (state: RootState) => UsersState(state).rows;
export const selectUsersTotal = (state: RootState) => UsersState(state).total;
export const selectCurrentUser = (state: RootState) => UsersState(state).row;
export const selectLookupUsers = createSelector(selectUsers, (rows) =>
  rows.map(({ id, full_name }) => ({ id, name: `${full_name}` })),
);
export const selectLookupCareWorkers = createSelector(selectUsers, (rows) =>
  rows.map(({ id, full_name, pay_level }) => ({
    id,
    name: `${full_name} ${pay_level?.name && `(${pay_level.name})`}`,
  })),
);
export const selectCareWorkerOptions = createSelector(selectUsers, (rows) =>
  rows.map(({ id, full_name }) => ({
    value: id,
    label: full_name ?? '',
  })),
);

export const selectUserByID = createSelector([selectUsers, (_: RootState, id?: number) => id], (rows, id) =>
  rows.find(({ id: rowId }) => id === rowId),
);

export const selectLocations = (state: RootState) => UsersState(state).locations;
export const selectUserAvailability = (state: RootState) => UsersState(state).availability;
export const selectUsersLoading = (state: RootState) => UsersState(state).loading;
export const selectUsersErrorResponse = (state: RootState) => UsersState(state).error;
