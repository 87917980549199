import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

import { Params } from '~libs/reduxUtils';

import ErrorResponse from '~components/ErrorResponse';
import { DateInput } from '~components/FormFields/Inputs';
import Icon, { faChevronRight } from '~components/Icon';

import { bulkUpdate } from '~appointments/actions/appointments';
import { getPublishEstimates, publish } from '~weekly-planner/actions';

import { clear } from '~weekly-planner/reducers/unsaved';
import { updatePublishDate } from '~weekly-planner/reducers/weeklyPlanner';

import { selectErrorResponse } from '~appointments/selectors/appointments';
import { selectPublishEstimates } from '~weekly-planner/selectors';
import { selectAllChanges, selectAllChangesCount } from '~weekly-planner/selectors/unsaved';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  originalDate: string;
};

const Publish: React.FC<Props> = ({ isOpen = false, onClose, originalDate }: Props) => {
  const dispatch = useAppDispatch();
  const { users, appointments } = useAppSelector(selectPublishEstimates);
  const changes = useAppSelector(selectAllChanges);
  const changesCount = useAppSelector(selectAllChangesCount);
  const errorMessage = useAppSelector(selectErrorResponse);

  const hasError = errorMessage ? errorMessage.length > 0 : false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newDate, setNewDate] = useState<string>('');

  const dateUpdate = (id: string, value: string) => {
    setNewDate(value);
    updateEstimates(value);
  };

  const updateEstimates = async (date: string) => {
    try {
      setIsLoading(true);
      await dispatch(getPublishEstimates({ published_date: date }));
    } finally {
      setIsLoading(false);
    }
  };

  const onPublish = async () => {
    // Set dialog as publishing
    setIsLoading(true);

    // Save any unsaved changes before publishing
    if (changesCount > 0) {
      const data: Params[] = Object.entries(changes).map(([, change]: [string, any]) => change);
      const { payload } = await dispatch(bulkUpdate(data));
      if (!payload?.success) {
        console.error('error, should be returning a response message in reducer');
        setIsLoading(false);
        return;
      }
      dispatch(clear());
    }

    // Publish the date
    const { payload } = await dispatch(publish({ published_date: newDate }));
    if (payload?.success) {
      dispatch(updatePublishDate(newDate));
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader className="bg-success text-white">Publish New Date</ModalHeader>
      <ModalBody>
        <div className="mb-2 pb-2 border-2 border-bottom border-success">
          Please confirm the date & following changes to publish:
        </div>
        <ErrorResponse message={errorMessage} />
        <div className="p-2">
          <span className="mb-2 font-weight-bold">Publish Date</span>
          <div className="d-flex flex-row justify-content-center">
            <Input type="date" name="oldPublishDate" value={originalDate} disabled className="w-50" />
            <div className="align-bottom">
              <Icon icon={faChevronRight} className="align-bottom ms-2 me-2" />
            </div>
            <DateInput
              type="date"
              id="newPublishDate"
              name="newPublishDate"
              value={newDate}
              onChange={dateUpdate}
              disabled={isLoading}
              className="w-50"
            />
          </div>
        </div>

        <div className="mt-4 mx-2">
          <Row>
            <Col sm="6">Unsaved Changes:</Col>
            <Col>{changesCount}</Col>
          </Row>
          <Row>
            <Col sm="6">Affected Care Workers:</Col>
            <Col>
              {isLoading && <Spinner type="border" size="sm" color="info" />}
              {!isLoading && <span>{users ?? '-'}</span>}
            </Col>
          </Row>
          <Row>
            <Col sm="6">Affected Appointments:</Col>
            <Col>
              {isLoading && <Spinner type="border" size="sm" color="info" />}
              {!isLoading && <span>{appointments ?? '-'}</span>}
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn ms-2 px-4"
          color="success"
          disabled={isLoading || newDate === '' || hasError}
          onClick={onPublish}
        >
          {isLoading ? <Spinner type="grow" size="sm" /> : <span>Publish</span>}
        </Button>
        <Button className="btn ms-2 px-4" onClick={onClose} disabled={isLoading}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Publish;
