import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import AsyncSelect from 'react-select/async';

import Button from '~components/Button';
import { InputProps } from '~components/FormFields';
import Icon, { faRefresh } from '~components/Icon';

interface Option {
  value: string | number | undefined;
  label: string | undefined;
}

const SelectLazy: React.FC<InputProps> = ({
  id,
  value,
  disabled,
  placeholder,
  selectorOptions,
  selectorActions,
  selectorParams,
  onChange,
  clearable = true,
  manualReload = false,
}) => {
  const dispatch = useAppDispatch();
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchedSelectorOptions = useAppSelector(selectorOptions || (() => []));
  const options = fetchedSelectorOptions.map(({ id, name }) => ({ value: id, label: name }) as Option);

  const loadOptions = async (inputValue: string) => {
    const updatedOptions = options.filter((option) => option.label?.toLowerCase().includes(inputValue.toLowerCase()));
    return updatedOptions;
  };

  const refreshOptions = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);

    try {
      if (selectorActions) {
        await dispatch(selectorActions(selectorParams));
        setOptionsLoaded(true);
      }
    } catch (error: any) {
      console.log(`Error loading options for ${id} : ${error}`);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleFocus = () => {
    if (!optionsLoaded) {
      refreshOptions();
    }
  };

  return (
    <div className="align-items-center row" data-cy={`${id}-selectlazy`}>
      <div className="col">
        <AsyncSelect
          id={id}
          name={id}
          classNamePrefix={'form-input-select'}
          isDisabled={disabled}
          placeholder={placeholder ?? '-'}
          isClearable={clearable}
          value={options.find((option) => option.value === value)}
          loadOptions={loadOptions}
          onFocus={handleFocus}
          onChange={(selected, event) => {
            onChange && onChange(event.name ?? '', selected?.value);
          }}
          defaultOptions={options}
          cacheOptions
        />
      </div>
      {manualReload && (
        <div className="col-sm-1 ms-2">
          <Button size="sm" color="success" onClick={refreshOptions} disabled={isRefreshing}>
            <Icon icon={faRefresh}></Icon>
          </Button>
        </div>
      )}
    </div>
  );
};

export default SelectLazy;
