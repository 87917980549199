import React from 'react';

import Icon, { faQuestionCircle } from '~components/Icon';

const PageNotFound: React.FC = () => (
  <div className="d-flex flex-column align-items-center justify-content-center h-100">
    <Icon icon={faQuestionCircle} size="4x" />
    <h1 className="mt-4">404 - Page Not Found</h1>
    <p className="mt-4">
      The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
    </p>
  </div>
);

export default PageNotFound;
