import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Layout, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import ReactGridLayout from 'react-grid-layout';

import Button from '~components/Button';
import Icon, { faCheckCircle, faXmarkCircle } from '~components/Icon';
import ModalCard from '~components/ModalCard';
import Snapshot from '~dashboard/components/Snapshot';

import { getAll, getLayout, getSnapshot, upsertLayout } from '~dashboard/actions/snapshots';

import { revertLayout, setLayout } from '~dashboard/reducers/snapshots/preferences';

import {
  selectIsLayoutModified,
  selectLayout,
  selectLayoutLoading,
  selectSnapshotData,
  selectSnapshots,
  selectSnapshotsLoading,
} from '~dashboard/selectors/snapshots';

const GridLayout = WidthProvider(ReactGridLayout);

const Snapshots: React.FC = () => {
  const dispatch = useAppDispatch();
  const snapshots = useAppSelector(selectSnapshots);
  const layout = useAppSelector(selectLayout);
  const snapshotsData = useAppSelector(selectSnapshotData);

  const snapshotsLoading = useAppSelector(selectSnapshotsLoading);
  const layoutLoading = useAppSelector(selectLayoutLoading);
  const isLayoutModified = useAppSelector(selectIsLayoutModified);
  const loading = snapshotsLoading === 'pending' || layoutLoading === 'pending';

  useEffect(() => {
    dispatch(getLayout());
    dispatch(getAll());
  }, []);

  useEffect(() => {
    if (snapshots.length > 0) {
      snapshots.forEach(({ name }) => {
        dispatch(getSnapshot({ id: name }));
      });
    }
  }, [snapshots]);

  const onLayoutChange = (newLayout: Layout[]) => {
    if (newLayout.length) dispatch(setLayout(newLayout));
  };

  const saveConfig = async () => {
    if (Array.isArray(layout)) {
      await dispatch(upsertLayout({ layout }));
      dispatch(getLayout());
    } else {
      console.error('Layout is not an array:', layout);
    }
  };

  const revertChanges = async () => {
    dispatch(revertLayout(null));
  };

  return (
    <ModalCard
      className="border-0"
      headerClassName="d-flex flex-row justify-content-between ps-3 pe-3 header"
      header={
        <>
          <h3>Snapshots</h3>
          {isLayoutModified && (
            <div>
              <Button size="sm" color="danger" className="ms-3" onClick={revertChanges}>
                <Icon className="me-2" icon={faXmarkCircle} size="lg" />
                Discard Changes
              </Button>
              <Button size="sm" color="success" className="ms-3" onClick={saveConfig}>
                <Icon className="me-2" icon={faCheckCircle} size="lg" />
                Save Changes
              </Button>
            </div>
          )}
        </>
      }
      isOpen={true}
      allowOpen={false}
      allowHeaderOpen={false}
    >
      <div className="snapshots">
        {loading || !Array.isArray(layout) ? (
          <div>Loading...</div>
        ) : (
          <GridLayout
            className="layout"
            measureBeforeMount={false}
            layout={layout}
            cols={12}
            rowHeight={40}
            onLayoutChange={onLayoutChange}
            isResizable
            isDraggable
          >
            {snapshots.map(({ id, label }) => {
              const snapshot = snapshotsData[id];
              return (
                <div key={id} className="snapshot-card">
                  <Snapshot label={label} value={snapshot?.value} loading={snapshot?.loading} error={snapshot?.error} />
                </div>
              );
            })}
          </GridLayout>
        )}
      </div>
    </ModalCard>
  );
};

export default Snapshots;
