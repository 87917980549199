import React from 'react';

import { InputProps } from '~components/FormFields';

const LabelDuration: React.FC<InputProps> = ({ value, className }) => {
  const getValidation = (value: number) => {
    if (value < 1) {
      return <span className="text-danger"> Less than 1 mins</span>;
    }

    if (value > 5) {
      return <span className="text-success"> OK</span>;
    }
    return null;
  };

  return (
    <div className={`${className !== undefined ? className : ''}`}>
      {value}
      {getValidation(value)}
    </div>
  );
};

export default LabelDuration;
