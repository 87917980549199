import { apiWeeklyPlanner } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks } from '~libs/reduxUtils';

export const typePrefix = 'weekly-planner-clients';

const config = [{ actionType: 'getAll', apiPath: `${apiWeeklyPlanner}/clients`, method: get }];

export const { getAll } = createThunks(typePrefix, config);
