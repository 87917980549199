import React, { useState } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, Accordion as RSAccordion } from 'reactstrap';
import classNames from 'classnames';

interface Item {
  header: string;
  body: React.ReactNode;
}

interface ComponentProps {
  className?: string;
  items: Item[];
  allowMultiple?: boolean;
}

const Accordion: React.FC<ComponentProps> = ({ className, items, allowMultiple = false }) => {
  const [open, setOpen] = useState<string | string[]>('');

  const toggle = (target: string) => {
    if (!allowMultiple) return setOpen(target !== open ? target : '');
    toggleMultiple(target);
  };

  const toggleMultiple = (target: string) => {
    if (!Array.isArray(open)) return setOpen([target]);

    const exists = open.find((id) => target === id);
    if (exists) return setOpen(open.filter((id) => target !== id));
    return setOpen([...open, target]);
  };

  const isOpen = (id: string) => {
    if (allowMultiple) return open?.includes(id);
    return open === id;
  };

  const renderItems = (items: Item[]) => {
    return items.map(({ header, body }, index) => {
      const id = String(index);
      const className = classNames({
        expanded: open === id,
      });

      return (
        <AccordionItem key={id} className={className}>
          <AccordionHeader targetId={id}>{header}</AccordionHeader>
          <AccordionBody accordionId={id}>{isOpen(id) && body}</AccordionBody>
        </AccordionItem>
      );
    });
  };

  return (
    <RSAccordion open={open} toggle={toggle} className={className}>
      {renderItems(items)}
    </RSAccordion>
  );
};

export default Accordion;
