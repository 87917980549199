import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Button from 'tsx/components/Button';
import { InputProps } from 'tsx/components/FormFields';
import * as FormInput from 'tsx/components/FormFields/Inputs';
import { formatTimeString } from 'tsx/libs/dayjs';

function FormInputActualTimes({ id, value = {}, disabled, onChange }: InputProps) {
  const [override, setOverride] = useState(false);

  const { start_time_actual, end_time_actual } = value;

  const displayStart = formatTimeString(start_time_actual);
  const displayEnd = formatTimeString(end_time_actual);

  const labelSm = 6;

  return (
    <>
      <Row className="align-items-center">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>Start</Col>
          <Col className="d-flex align-items-center py-2">
            {override ? (
              <FormInput.Time
                id="start_time_actual"
                name="start_time_actual"
                type="time"
                value={start_time_actual}
                disabled={disabled}
                placeholder="Start"
                onChange={onChange}
              />
            ) : (
              <FormInput.ReadOnly
                type="readonly"
                id={id}
                name={id}
                value={displayStart ?? 'Not entered'}
                dataCy="start_time"
              />
            )}
          </Col>
        </Col>
        <Col>
          {!override && (
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={() => setOverride(!override)}
              data-cy={`${id}-override-time-button`}
            >
              Override
            </Button>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm={labelSm} className="d-flex align-items-center">
          <Col>End</Col>
          <Col className="d-flex align-items-center">
            {override ? (
              <FormInput.Time
                id="end_time_actual"
                name="end_time_actual"
                type="time"
                value={end_time_actual}
                disabled={disabled}
                placeholder="End"
                onChange={onChange}
              />
            ) : (
              <FormInput.ReadOnly
                type="readonly"
                id={id}
                name={id}
                value={displayEnd ?? 'Not entered'}
                dataCy="end_time"
              />
            )}
          </Col>
        </Col>
        <Col />
      </Row>
    </>
  );
}

export default FormInputActualTimes;
