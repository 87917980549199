import { buildSlice, CommonState, Row } from '~libs/reduxUtils';
import { Worker } from '~weekly-planner/lib/common';

import { getAll, getAwardAlerts, getRunningLate, typePrefix } from '~weekly-planner/actions/users';

interface State extends CommonState<Worker> {
  awardRows: Row[];
  awardCount?: number;
  params: { [key: string]: any };
  filterOptions: { [key: string]: any };
  orderBy: {
    availability: false;
    distance: false;
  };
  runningLate: {
    appointmentsNow: Row[];
    appointmentsBeforeNow: Row[];
    notLoggedIn: Row[];
  };
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  awardRows: [],
  params: {},
  rows: [],
  filterOptions: {
    is_qualified: null,
    is_available: null,
    is_preferred: null,
    is_historical: null,
  },
  orderBy: {
    availability: false,
    distance: false,
  },
  runningLate: {
    appointmentsBeforeNow: [],
    appointmentsNow: [],
    notLoggedIn: [],
  },
};

const slice = buildSlice<State, Worker>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getAwardAlerts.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { data } = action.payload;
      const { rows, total } = data;
      state.awardRows = rows;
      state.awardCount = total;
      state.hasLoaded = true;
    });
    builder.addCase(getRunningLate.fulfilled, (state, action) => {
      state.loading = 'fulfilled';

      const {
        not_logged_in: notLoggedIn = [],
        appointments_before_now: appointmentsBeforeNow = [],
        appointments_now: appointmentsNow = [],
      } = action.payload.data || {}; // Default to an empty object if action.payload.data is undefined

      state.runningLate = { notLoggedIn, appointmentsBeforeNow, appointmentsNow };
    });
  },
  customActions: {
    filterUsers(state, action) {
      const { payload } = action;

      if ('key' in payload && 'value' in payload) {
        state.filterOptions[payload.key] = payload.value;
      } else {
        Object.entries(payload).forEach(([key, value]) => {
          state.filterOptions[key] = value;
        });
      }
    },
    orderUsers(state, action) {
      const { payload } = action;
      state.orderBy = payload;
    },
  },
  commonConfig: { getAll },
});

export const { clear, updateParams, filterUsers, orderUsers } = slice.actions;
export default slice.reducer;
