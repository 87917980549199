/**
 * Returns an object with the appropriate verb forms and pronoun based on the count and noun provided.
 *
 * @param  count - The number to determine singular or plural.
 * @param  noun - The noun to be pluralized if count is not 1.
 * @returns  An object containing the correct verb forms and pronoun:
 * - verbBe: 'is' if count is 1, otherwise 'are'.
 * -  verbHas: 'has' if count is 1, otherwise 'have'.
 * -  proNoun: 'it' if count is 1, otherwise 'them'.
 * -  pluralNoun: The noun itself if count is 1, otherwise the noun in its plural form.
 */
export const pluralize = (count: number, noun: string) => {
  const verbBe = count === 1 ? 'is' : 'are';
  const verbHas = count === 1 ? 'has' : 'have';
  const proNoun = count === 1 ? 'it' : 'them';
  const pluralNoun = count === 1 ? noun : `${noun}s`;

  return { verbBe, verbHas, pluralNoun, proNoun };
};
