import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { LoadingState } from '~libs/reduxUtils';

import {
  getClientPackageSchedule,
  getClientPackageSchedules,
  typePrefix,
} from '../../actions/clients/clientPackageSchedules';

interface ClientPackageScheduleRow {
  id: number;
  start_date: string;
  end_date: string;
  package: { id: number; name: string };
}

interface ClientPackageScheduleState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<ClientPackageScheduleRow>;
  row?: ClientPackageScheduleRow;
}

const initialState: ClientPackageScheduleState = {
  loading: 'idle',
  error: null,
  rows: [],
};

// Main slice, connecting API actions to redux state.
export const clientPackageSchedulesSlice = createSlice({
  name: 'client-package-schedules',
  initialState,
  reducers: {
    clearClientPackageSchedules(state) {
      state.rows = initialState.rows;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientPackageSchedules.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getClientPackageSchedule.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export const { clearClientPackageSchedules } = clientPackageSchedulesSlice.actions;

export default clientPackageSchedulesSlice.reducer;
