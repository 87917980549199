import React from 'react';

import { HeaderCellProps } from '~weekly-planner/lib/common';

const ClientCell: React.FC<HeaderCellProps> = ({ data: client, style }) => {
  const { full_name } = client;
  return (
    <div className="worker-cell-parent">
      <div className="worker-cell" style={style}>
        <div className="d-flex flex-column justify-content-between w-100">
          <div>
            <div className="px-2 mb-2">
              <div className="d-flex align-items-center">
                <div>{full_name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCell;
