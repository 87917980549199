import React from 'react';
import { useAppSelector } from 'hooks';

import { InputProps } from '~components/FormFields';
import * as FormInput from '~components/FormFields/Inputs';

import { selectCurrentAppointmentSignature } from '~appointments/selectors/appointments';

function FormInputSignature({ id }: InputProps) {
  const signature = useAppSelector(selectCurrentAppointmentSignature);
  return (
    <>
      {!signature && (
        <FormInput.ReadOnly
          type={'readonly'}
          name={id}
          id={id}
          value="None collected"
          className="ps-2 pe-2"
          dataCy={id}
        />
      )}
      {signature && <img className="p-2 border rounded" src={signature} />}
    </>
  );
}

export default FormInputSignature;
