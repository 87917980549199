import { buildSlice, LoadingState } from '~libs/reduxUtils';

import { getProspectStatistics, typePrefix } from '~dashboard/actions/clientProspects';

interface State {
  loading: LoadingState;
  error: string | null | undefined;
  rows: [];
  row: {
    id: number;
    unassigned_count: number;
    current_prospects_count: number;
    uncontacted_prospects_count: number;
  };
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  row: {
    id: 1,
    current_prospects_count: 0,
    unassigned_count: 0,
    uncontacted_prospects_count: 0,
  },
};

// Main slice, connecting API actions to redux state.
const slice = buildSlice<State>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getProspectStatistics.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
  },
});

export default slice.reducer;
