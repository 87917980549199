import { RootState } from 'store';

const UserState = (state: RootState) => state.weeklyPlanner.users;

export const selectAll = (state: RootState) => UserState(state).rows;
export const selectLoading = (state: RootState) => UserState(state).loading;
export const selectHasLoaded = (state: RootState) => UserState(state).hasLoaded;
export const selectFilterOptions = (state: RootState) => UserState(state).filterOptions;
export const selectErrorResponse = (state: RootState) => UserState(state).error;
export const selectRunningLate = (state: RootState) => UserState(state).runningLate;
export const selectParams = (state: RootState) => UserState(state).params;
export const selectAllAwards = (state: RootState) => UserState(state).awardRows;
export const selectAwardCount = (state: RootState) => UserState(state).awardCount;
