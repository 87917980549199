import { Layout } from 'react-grid-layout';
import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { RootState } from 'store';

const SnapshotsState = (state: RootState) => state.dashboard.snapshots;
const ConfigState = (state: RootState) => state.dashboard.preferences;

export const selectSnapshots = (state: RootState) => SnapshotsState(state).rows;
export const selectSnapshotsLoading = (state: RootState) => SnapshotsState(state).loading;

export const selectLayout = (state: RootState) => ConfigState(state).layout || [];
export const selectLayoutLoading = (state: RootState) => ConfigState(state).loading;

export const selectIsLayoutModified = createSelector(
  (state: RootState) => ConfigState(state).layout,
  (state: RootState) => ConfigState(state).serverLayout,
  (layout, serverLayout) => {
    const normalizedLayout = normalizeLayout(layout);
    const normalizedServerLayout = normalizeLayout(serverLayout);
    return !isEqual(normalizedLayout, normalizedServerLayout);
  },
);

export const selectSnapshotData = (state: RootState) => SnapshotsState(state).snapshotsData;

const normalizeLayout = (layout: Layout[]) => {
  return layout.map(({ x, y, w, h, i }) => ({ x, y, w, h, i }));
};
