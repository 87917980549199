import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { Appointment, Client, Event, Worker } from '~weekly-planner/lib/common';

import { Cell } from '~weekly-planner/components/Main/Daily';

interface ComponentProps {
  headerCell: ReactNode;
  data: Worker | Client;
  date: Dayjs;
  events: Event[];
  getAppointmentTitle: (appointment: Appointment) => string;
  style?: CSSProperties;
}

const Row: React.FC<ComponentProps> = ({ headerCell, data, date, events, style, getAppointmentTitle }) => {
  const { id } = data;

  const className = classNames('worker-row', {});

  return (
    <div id={id} className={className} key={`${id}`} data-date={date.format(dateInputFieldFormat)}>
      {headerCell}
      <Cell
        data={data}
        date={date}
        events={events}
        style={style}
        startDate={date.weekday(0)}
        getAppointmentTitle={getAppointmentTitle}
      />
    </div>
  );
};

export default Row;
