import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { typePrefix } from '~dashboard/actions/alerts';
import { getAll } from '~dashboard/actions/notifications';

export interface Notification {
  is_acknowledged: boolean;
  id: number;
  company_id: number;
  appointment_id: number;
  date: string;
  time: string;
  deleted: boolean;
}

interface State extends CommonState<Row> {
  notifications?: Notification;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  count: 0,
};

const slice = buildSlice<State>(typePrefix, initialState, { commonConfig: { getAll } });

export default slice.reducer;
