import dayjs from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { get, set } from '~libs/localstorage';
import { buildSlice, CommonState, Row } from '~libs/reduxUtils';
import { Appointment } from '~weekly-planner/lib/common';

import { getPublishEstimates, typePrefix } from '~weekly-planner/actions';

interface State extends CommonState<Row> {
  params: {
    week_start: string;
    limit: number;
    [key: string]: any;
  };
  date: string;
  viewType: 'Users' | 'Clients';
  rangeType: 'Weekly' | 'Daily';
  options: { [key: string]: boolean };
  openAppointmentDetailsId: number | null;
  focusedWorkerId: number | null;
  focused: {
    appointment: Appointment | null;
    isBiddingView: boolean;
    newBidders: string[];
  };
  focusOptions: {
    availabilityFilter: 'All' | 'Available' | 'Not Available';
    qualifiedFilter: 'All' | 'Yes' | 'No';
    highlightType: 'Times' | 'Distance';
    preferenceFilter: Array<'Preferred' | 'Historical'>;
    sortBy: 'Alphabetical' | 'Bidding';
  };
  orderBy: {
    availability: boolean;
    distance: boolean;
  };
  publishDate: string;
  publishEstimates: {
    users?: number | null;
    appointments?: number | null;
  };
  importedAppointments?: {
    id?: number | null;
    user_id?: number;
    date?: string;
    parent_repeat_id?: number;
  };
}

const initialState: State = {
  loading: 'idle',
  hasLoaded: false,
  error: null,
  rows: [],
  params: {
    week_start: dayjs().weekday(1).format(dateInputFieldFormat),
    limit: 30,
  },
  date: dayjs().format(dateInputFieldFormat),
  viewType: get('weekly-planner-view-type') ?? 'Users',
  rangeType: get('weekly-planner-range-type') ?? 'Weekly',
  options: {
    workerAvailability: false,
    workerQualifications: false,
    appointmentFlexibility: false,
    showBreaks: true,
    showTravel: true,
    showServiceType: true,
    showCancellations: true,
    showPayableCancellations: true,
    workerAwardAlerts: false,
    totalSimple: false,
    totalAppointments: false,
    totalPredicted: true,
    totalDistance: true,
    ...get('weekly-planner-view-options'),
  },
  openAppointmentDetailsId: null,
  focusedWorkerId: null,
  focused: {
    appointment: null,
    isBiddingView: false,
    newBidders: [],
  },
  focusOptions: {
    qualifiedFilter: 'Yes',
    availabilityFilter: 'Available',
    preferenceFilter: [],
    highlightType: 'Times',
    sortBy: 'Alphabetical',
  },
  orderBy: {
    availability: false,
    distance: false,
  },
  publishDate: '',
  publishEstimates: {},
  importedAppointments: {},
};

const slice = buildSlice<State>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getPublishEstimates.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.publishEstimates = action.payload.data;
    });
  },
  customActions: {
    toggleOption(state, action) {
      const { key, toggle } = action.payload;
      state.options[key] = toggle;
      set('weekly-planner-view-options', state.options);
    },
    openAppointmentDetails(state, action) {
      state.openAppointmentDetailsId = action.payload;
    },
    focusWorker(state, action) {
      state.focusedWorkerId = action.payload;
    },
    focusAppointment(state, action) {
      const focused = action.payload;
      state.focused = focused;
      if (!focused) {
        delete state.params.filter_by?.focused;
      }
    },
    toggleBidding(state, action) {
      const { isBiddingView } = action.payload;
      state.focused = { ...state.focused, isBiddingView: isBiddingView };
    },

    updateFocusOptions(state, action) {
      const options = action.payload;

      for (const key of Object.keys(options)) {
        state.focusOptions = {
          ...state.focusOptions,
          [key]: options[key],
        };
      }
    },
    clearFocusOptions(state) {
      state.focusOptions = initialState.focusOptions;
    },
    update(state, action) {
      const options = action.payload;

      for (const key of Object.keys(options)) {
        state[key] = options[key];
      }
    },
    updatePublishDate(state, action) {
      state.publishDate = action.payload;
    },
    updateWithPersistence(state, action) {
      const options = action.payload;

      for (const key of Object.keys(options)) {
        state[key] = options[key];
        set(`weekly-planner-${key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()}`, state[key]);
      }
    },
    updateBiddingWorkers(state, action) {
      //TODO: rename func for clarity - updateUnsavedBiddingWorkers ?
      const { workerIds, isChecked } = action.payload;

      if (!state.focused.appointment) return;

      const newBidderIds = state.focused.newBidders || [];

      const mergedBidderIds = isChecked
        ? [...new Set([...newBidderIds, ...workerIds])]
        : newBidderIds.filter((id: any) => !workerIds.includes(id));

      state.focused = {
        ...state.focused,
        newBidders: mergedBidderIds,
      };
    },
    mergeBiddingWorkers(state) {
      const { focused } = state;
      const { appointment, newBidders } = focused || {};
      const existingBidders = appointment?.bidding_user_ids ?? [];
      const mergedBidders = Array.from(new Set([...existingBidders, ...newBidders]));

      const updatedAppointment: Partial<Appointment> = {
        ...appointment,
        is_bidding: true,
        bidding_user_ids: mergedBidders,
      };

      state.focused = { ...focused, appointment: updatedAppointment as Appointment, newBidders: [] };
    },
  },
});

export const {
  clear,
  clearFocusOptions,
  update,
  updateFocusOptions,
  updateParams,
  updatePublishDate,
  toggleOption,
  openAppointmentDetails,
  focusWorker,
  focusAppointment,
  toggleBidding,
  updateBiddingWorkers,
  mergeBiddingWorkers,
  updateWithPersistence,
} = slice.actions;

export default slice.reducer;
