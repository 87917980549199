import React from 'react';
import { useAppSelector } from 'hooks';
import ReactSelect from 'react-select';

import { InputProps } from '~components/FormFields';

interface ComponentProps extends InputProps {
  ignorePlaceholder?: boolean;
}

interface Option {
  value: string | number;
  label: string;
}

const MultiSelect: React.FC<ComponentProps> = ({
  id,
  value,
  placeholder,
  ignorePlaceholder = false,
  className,
  disabled = false,
  options,
  selectorOptions,
  setFieldValue,
}) => {
  let selectOptions: Option[] = [];
  if (options) {
    selectOptions = options.map(({ id, name }) => ({ value: id, label: name }));
  } else if (selectorOptions) {
    const rows = useAppSelector(selectorOptions);
    selectOptions = rows.map(({ id, name }) => ({ value: id, label: name }));
  }

  return (
    <ReactSelect
      isMulti
      closeMenuOnSelect={false}
      id={id}
      name={id}
      value={selectOptions.filter((option) => value?.includes(option.value))}
      isDisabled={disabled}
      options={selectOptions}
      className={className}
      classNamePrefix={'form-input-multi-select'}
      onChange={(options) =>
        setFieldValue &&
        setFieldValue(
          id,
          options.map((option) => option.value),
        )
      }
      placeholder={!ignorePlaceholder ? placeholder : undefined}
    />
  );
};

export default MultiSelect;
