import React, { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import { remove as removeLocalStorageItem } from '~libs/localstorage';

import { removeAuthenticated } from '~main/reducers/login';

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(removeAuthenticated());
    removeLocalStorageItem('dashboard-visited');
  }, []);

  return (
    <div className="d-flex justify-content-center mt-5 login">
      <Card className="rounded">
        <CardHeader className="d-flex justify-content-center bg-menu-background">
          <img src="/assets/tpassist_logo.png" alt="Logo" />
        </CardHeader>
        <CardBody className="d-flex flex-column text-center justify-content-center">
          <h3 data-cy={'logout-header'}>You are now logged out</h3>
          <Button onClick={() => navigate('/login')} className="w-25 mx-auto mt-3" color="success">
            Log back in
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default Logout;
