import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { CommonState, Row } from '~libs/reduxUtils';

import { getAll, typePrefix } from '~appointments/actions/cancellationReasons';

const initialState: CommonState<Row> = {
  loading: 'idle',
  error: null,
  rows: [],
  row: { id: -1 },
};

// Main slice, connecting API actions to redux state.
export const appointmentCancellationReasonsSlice = createSlice({
  name: 'appointment-cancellation-reasons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export default appointmentCancellationReasonsSlice.reducer;
