import { combineReducers } from '@reduxjs/toolkit';

import alerts from './alerts';
import notifications from './notifications';
import prospects from './prospects';
import * as snapshotsReducers from './snapshots';

export const dashboard = {
  alerts,
  notifications,
  ...snapshotsReducers,
  prospects,
};

export default combineReducers(dashboard);
