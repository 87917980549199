import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row } from 'reactstrap';
import { LocationInfo } from '~types/dashboard';

import Icon, { faEye } from '~components/Icon';

interface ComponentProps {
  userList: LocationInfo[];
  clientList?: LocationInfo[];
  onSelect: (data: LocationInfo) => void;
}

export const Sidebar: React.FC<ComponentProps> = ({ userList, clientList, onSelect }) => {
  const [openAccordionId, setOpenAccordion] = useState<string>('');

  const activeUserList = () => {
    return userList
      .filter(
        (user) =>
          user.appointments &&
          user.appointments.some(
            (appointment) =>
              appointment.type?.toLowerCase() === 'upcoming' || appointment.type?.toLowerCase() === 'active',
          ),
      )
      .map((user) => {
        return (
          <Row key={user.id}>
            <Col className="name">{user.full_name}</Col>
            <Col xs={1} className="view-eye" onClick={() => onSelect(user)}>
              <Icon icon={faEye} />
            </Col>
          </Row>
        );
      });
  };

  const activeClientList = () => {
    return clientList
      ?.filter(
        (client) =>
          client.appointments &&
          client.appointments.some(
            (appointment) =>
              appointment.type?.toLowerCase() === 'upcoming' || appointment.type?.toLowerCase() === 'active',
          ),
      )
      .map((client) => {
        return (
          <Row key={client.id}>
            <Col className="name">{client.full_name}</Col>
            <Col xs={1} className="view-eye" onClick={() => onSelect(client)}>
              <Icon icon={faEye} />
            </Col>
          </Row>
        );
      });
  };

  const renderAccordionItem = (id: string, header: string, body: React.ReactNode) => (
    <AccordionItem>
      <AccordionHeader targetId={id} onClick={() => toggle(id)}>
        {header}
      </AccordionHeader>
      <AccordionBody accordionId={id}>{openAccordionId === id && body}</AccordionBody>
    </AccordionItem>
  );

  const toggle = (accordianId: string) => {
    setOpenAccordion(() => (openAccordionId === accordianId ? '' : accordianId));
  };

  return (
    <div className="sidebar">
      <Accordion open={openAccordionId} toggle={toggle}>
        {renderAccordionItem('1', 'Active Workers', activeUserList())}
        {renderAccordionItem('2', 'Active Appointments', activeClientList())}
      </Accordion>
    </div>
  );
};

export default Sidebar;
