import { RootState } from 'store';

const AppointmentsState = (state: RootState) => state.appointments.appointments;
export const selectAppointments = (state: RootState) => AppointmentsState(state).rows;
export const selectAppointmentsTotal = (state: RootState) => AppointmentsState(state).total;
export const selectAppointmentRowEstimate = (state: RootState) => AppointmentsState(state).estimate;
export const selectCurrentAppointment = (state: RootState) => AppointmentsState(state).row;
export const selectCopyAppointment = (state: RootState) => AppointmentsState(state).copyRow;
export const selectChildAppointment = (state: RootState) => AppointmentsState(state).childRow;
export const selectCurrentAppointmentSignature = (state: RootState) => AppointmentsState(state).rowSignature;
export const selectErrorResponse = (state: RootState) => AppointmentsState(state).error;
export const selectAppointmentMessage = (state: RootState) => AppointmentsState(state).message;
export const selectAppointmentLoading = (state: RootState) => AppointmentsState(state).loading;
export const selectStatistics = (state: RootState) => AppointmentsState(state).statistics;
