import { apiClients } from '~constants/api';

import { get } from '~libs/genericAction';
import { createThunks, Params } from '~libs/reduxUtils';

export const typePrefix = 'clients';

const config = [
  {
    actionType: 'getAll',
    apiPath: apiClients,
    method: get,
    actionParams: {
      disabled: false,
      attributes: ['id', 'full_name', 'default_package_id', 'non_chargeable', 'address', 'preferences', 'description'],
      sort: ['non_chargeable.desc', 'full_name'],
      limit: 0,
    },
  },
  { actionType: 'getLocations', apiPath: `${apiClients}/locations`, method: get },
  { actionType: 'getOne', apiPath: (params: Params) => `${apiClients}/${params.id}`, method: get },
  { actionType: 'getHoldPeriods', apiPath: `${apiClients}/hold-periods`, method: get },
];

export const { getAll, getLocations, getOne, getHoldPeriods } = createThunks(typePrefix, config);
