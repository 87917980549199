import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { getOutstanding, typePrefix } from '~dashboard/actions/alerts';

export interface AlertStats {
  publish_alert?: {
    days_to_publish: number;
    publish_date: string;
  };
  users_running_late?: {
    not_started: number;
    not_completed: number;
  };
  pending_completion?: Array<{
    date: string;
    count: number;
  }>;
}

interface State extends CommonState<Row> {
  alertStats: AlertStats;
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  alertStats: {},
};

const slice = buildSlice<CommonState<Row>>(typePrefix, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getOutstanding.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.alertStats = action.payload.data;
    });
  },
});

export default slice.reducer;
