import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { LoadingState } from '~libs/reduxUtils';

import { getAll, getOne, typePrefix } from '~care-workers/actions/userPayGroups';

interface UserPayGroupRow {
  id: number;
  name?: string;
  company_id?: number;
  priority?: number;
  deleted?: boolean;
  code?: string;
}

interface UserPayGroupsState {
  loading: LoadingState;
  error: string | null | undefined;
  rows: Array<UserPayGroupRow>;
  row?: UserPayGroupRow;
}

const initialState: UserPayGroupsState = {
  loading: 'idle',
  error: null,
  rows: [],
};

// Main slice, connecting API actions to redux state.
export const userPayGroupsSlice = createSlice({
  name: 'user-pay-groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.rows = action.payload.data;
    });
    builder.addCase(getOne.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      state.row = action.payload.data;
    });
    // Default matching for loading cases, pending when action is being called
    builder.addMatcher(isPending, (state, { type }) => {
      if (type.startsWith(`${typePrefix}/`)) state.loading = 'pending';
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (action.type.startsWith(`${typePrefix}/`)) {
        state.loading = 'declined';
        state.error = action.error.message;
      }
    });
  },
});

export default userPayGroupsSlice.reducer;
