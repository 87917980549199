import { createSlice } from '@reduxjs/toolkit';

import { get, set } from '~libs/localstorage';
import { LoadingState } from '~libs/reduxUtils';

interface State {
  loading: LoadingState;
  error: string | null | undefined;
  quickMenuNarrow: boolean;
  viewType?: 'feature' | null;
  isDesktopView: boolean;
  announcementConfig?: { [key: string]: any };
  overdueInvoiceAnnouncement?: { [key: string]: any };
}

const initialState: State = {
  loading: 'idle',
  error: null,
  quickMenuNarrow: false,
  isDesktopView: true,
  announcementConfig: get('system-config'),
  overdueInvoiceAnnouncement: get('overdue-announcement-config'),
};

// Main slice, connecting API actions to redux state.
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleQuickMenu(state, action) {
      const { payload } = action;
      state.quickMenuNarrow = payload;
    },
    setViewType(state, action) {
      const { payload } = action;
      state.viewType = payload;
    },
    setIsDesktopView(state, action) {
      const { payload } = action;
      state.isDesktopView = payload;
    },
    updateConfig(state, action) {
      const { payload } = action;
      // update local storage
      set('system-config', payload);
      state.announcementConfig = payload;
    },
    updateOverdueInvoiceAnnouncement(state, action) {
      const { payload } = action;
      // update local storage
      set('overdue-announcement-config', payload);
      state.overdueInvoiceAnnouncement = payload;
    },
  },
});

export const { toggleQuickMenu, setViewType, setIsDesktopView, updateConfig, updateOverdueInvoiceAnnouncement } =
  appSlice.actions;

export default appSlice.reducer;
