import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { AuthTags } from '~constants/authTags';
import { AppointmentStatuses } from '~constants/maps';

import { dateInputFieldFormat } from '~libs/dayjs';
import { useSearch } from '~libs/searchParams';

import Button from '~components/Button';
import ContainerHeader from '~components/ContainerHeader';
import ErrorResponse from '~components/ErrorResponse';
import ExportDialog from '~components/ExportDialog';
import { SearchBar } from '~components/FormFields/Search';
import Icon, { faBusinessTime, faFileExcel, faMagnifyingGlass, faMinus, faPlus } from '~components/Icon';
import TableView from '~components/TableView';

import exportFields from '~appointments/forms/repeats/export';
import columns from '~appointments/forms/repeats/list';
import searchFields from '~appointments/forms/repeats/search';

import { exportAll, getAll, getEstimate } from '~appointments/actions/appointments';
import { getAll as getRepeatFrequencies } from '~appointments/actions/repeatFrequencies';
import { getAll as getServiceTypes } from '~appointments/actions/serviceTypes';
import { getAll as getStatuses } from '~appointments/actions/statuses';
import { getAll as getClientPackages } from '~main/actions/clients/clientPackages';
import { getAll as getClientReferrers } from '~main/actions/clients/clientReferrers';
import { setTitle } from '~main/actions/login';

import { clearAppointments, clearMessage } from '~appointments/reducers/appointments';

import {
  selectAppointmentLoading,
  selectAppointmentRowEstimate,
  selectAppointments,
  selectAppointmentsTotal,
  selectErrorResponse,
} from '~appointments/selectors/appointments';

const RepeatAppointments: React.FC = () => {
  const dispatch = useAppDispatch();

  const REDIRECT_URL = process.env.REACT_APP_TPONE_URL;

  const initialSortState: SortingState = [
    {
      id: 'last_updated',
      desc: true,
    },
  ];

  const initialSearchState: any = {
    repeat_end: { gte: dayjs().format(dateInputFieldFormat) },
    status_id: Object.entries(AppointmentStatuses)
      .map(([, value]) => (value < 8 ? value : false))
      .filter(Boolean),
  };

  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearch('search', initialSearchState);
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);

  const [params, setParams] = useState({
    repeat: true,
    sort: initialSortState.map(({ id, desc }) => `${id}.${desc ? 'desc' : 'asc'}`),
    'client.disabled': false,
    limit: 100,
  });

  useEffect(() => {
    setTitle('Repeat Appointments');
    // Get repeat appointments on mount
    load();

    // Get lookups on mount
    dispatch(
      getServiceTypes({
        deleted: {
          or: [true, false],
        },
        sort: ['deleted', 'name'],
      }),
    );
    dispatch(getStatuses());
    dispatch(getRepeatFrequencies());
    dispatch(getClientPackages({ sort: ['name'], deleted: false }));
    dispatch(getClientReferrers({ sort: ['name'], deleted: false }));

    // Clean up component on unmount, clear reducer, etc.
    return () => {
      dispatch(clearAppointments());
      dispatch(clearMessage());
    };
  }, []);

  useEffect(() => {
    if (searchParams !== initialSearchState) load();
  }, [searchParams]);

  const load = (loadParams: { [key: string]: any } = {}) => {
    const newParams = {
      ...params,
      ...loadParams,
    };

    // Keep search and load params separate as search can be cleared. Don't want to retain values when nothing is passed back.
    dispatch(getAll({ ...newParams, ...searchParams }));
    setParams(newParams);
  };

  const sort = (params: SortingState = []) => {
    load({ sort: params.map(({ id, desc }) => `${id}.${desc ? 'desc' : 'asc'}`) });
  };

  const download = (params: { [key: string]: any }, onProgress: (value: any) => void) =>
    exportAll({ params: { repeat: true, ...params }, onProgress });

  const search = (values: { [key: string]: any }) => {
    setSearchParams(values);
  };

  const estimate = async (values: { [key: string]: any }) =>
    dispatch(
      getEstimate({
        repeat: true,
        ...values,
      }),
    );

  const appointments = useAppSelector(selectAppointments);
  const appointmentsTotal = useAppSelector(selectAppointmentsTotal);
  const appointmentRowEstimate = useAppSelector(selectAppointmentRowEstimate);
  const errorResponse = useAppSelector(selectErrorResponse);
  const appointmentLoading = useAppSelector(selectAppointmentLoading);
  const isLoading = appointmentLoading === 'pending';
  const authTag = AuthTags.APPOINTMENTS.UPDATE;

  return (
    <div className="p-2">
      <ContainerHeader icon={faBusinessTime} iconSize="1x" className="warning text-white">
        Repeat Appointments
      </ContainerHeader>
      <ErrorResponse message={errorResponse} />
      <div className="nav d-flex justify-content-between bg-light p-2 mt-2 mb-2">
        <div>
          <Button authTag={authTag} size="sm" color="warning" className="ms-2 me-2" href={'/appointments/repeats/new'}>
            <Icon className="me-2" icon={faPlus} />
            Add Repeat Appointment
          </Button>
          <Link to={`${REDIRECT_URL}/appointment-repeat-grouped.asp`}>
            <Button size="sm" color="success" className="ms-2 me-2">
              <Icon className="me-2" icon={faMinus} />
              Repeats Calendar
            </Button>
          </Link>
          <Link to={`${REDIRECT_URL}/repeats-week-counts.asp`}>
            <Button size="sm" color="success" className="ms-2 me-2">
              <Icon className="me-2" icon={faMinus} />
              Repeat Weeks
            </Button>
          </Link>
        </div>
        <div>
          <Button size="sm" className="me-2" onClick={() => setSearchBarOpen(!searchBarOpen)}>
            <Icon className="me-2" icon={faMagnifyingGlass} />
            {searchBarOpen ? 'Hide' : 'Search'}
          </Button>
          <Button size="sm" className="me-2" onClick={() => setExportDialogOpen(!exportDialogOpen)}>
            <Icon className="me-2" icon={faFileExcel} />
            Export
          </Button>
        </div>
      </div>
      <ExportDialog
        isOpen={exportDialogOpen}
        fields={exportFields}
        estimate={appointmentRowEstimate}
        openingValues={searchParams}
        onClose={() => setExportDialogOpen(false)}
        onEstimate={estimate}
        onDownload={(params, onProgress) => download(params, onProgress)}
      />
      <Collapse isOpen={searchBarOpen}>
        <SearchBar
          fields={searchFields}
          initialValues={searchParams}
          storageKey="repeat-appointments"
          onSubmit={search}
        />
      </Collapse>
      <TableView
        id="repeat-appointments"
        data={appointments as []}
        columns={columns}
        isLoading={isLoading}
        emptyIcon={faBusinessTime}
        emptyMessage={'No appointments found, please add one!'}
        allowSort={true}
        manualSort={true}
        onSort={sort}
        allowPagination={true}
        onLoadMore={() => load({ limit: params.limit + 50 })}
        showCount={true}
        totalCount={appointmentsTotal}
      />
    </div>
  );
};

export default RepeatAppointments;
