import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import { buildAssignChange } from '~weekly-planner/lib/simulate';

import Button from '~components/Button';
import { MultiCheckbox } from '~components/FormFields/Inputs';
import Modal from '~components/Modal';

import { simulate } from '~weekly-planner/actions/unsaved';

import { selectParams as selectGlobalParams } from '~weekly-planner/selectors';
import { selectSimulatedClientChanges, selectUnallocated } from '~weekly-planner/selectors/simulate';

type Props = {
  isOpen?: boolean;
  setIsOpen: (toggle: boolean) => void;
};

const ConfirmBulkAssign: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  const appointments = useAppSelector(selectUnallocated);
  const weeklyPlannerData = useAppSelector(selectSimulatedClientChanges);
  const globalParams = useAppSelector(selectGlobalParams);

  const { week_start } = globalParams;

  const [values, setValues] = useState<string[]>([]);

  const id = 'confirm-bulk-assign';
  const ignoreAvailability = 'ignore-availability';
  const ignoreQualified = 'ignore-qualified';
  const options = [
    { id: ignoreAvailability, name: 'Ignore Availability Checks' },
    { id: ignoreQualified, name: 'Ignore Qualification Checks' },
  ];

  const onBulkAssign = () => {
    const targets = appointments.filter(({ user_id, user }) => {
      if (!user_id || !user) return false;
      const { is_qualified, is_available } = user;
      if (!values.includes(ignoreAvailability) && !is_available) return false;
      if (!values.includes(ignoreQualified) && !is_qualified) return false;
      return true;
    });

    const change = buildAssignChange(weeklyPlannerData, targets);
    dispatch(simulate({ ...change, week_start }));
  };

  const onConfirm = () => {
    onBulkAssign();
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerClassName="bg-success text-white"
      header="Bulk Assign Workers"
      footer={
        <>
          <Button size="sm" color="success" className="ps-4 pe-4 me-2" onClick={onConfirm}>
            Confirm
          </Button>
          <Button size="sm" className="ps-4 pe-4" onClick={() => setIsOpen(false)}>
            Back
          </Button>
        </>
      }
    >
      <div className="mb-4 pb-2 border-2 border-bottom">
        Please select below options for assigning workers <b>in bulk</b>
      </div>
      <MultiCheckbox
        type="multi-checkbox"
        id={id}
        name={id}
        value={values}
        onChange={(_, value) => setValues(value)}
        options={options}
        colSize={2}
      />
    </Modal>
  );
};

export default ConfirmBulkAssign;
