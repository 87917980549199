import { Layout } from 'react-grid-layout';

import { buildSlice, CommonState, Row } from '~libs/reduxUtils';

import { getLayout, typePrefix } from '~dashboard/actions/snapshots';

const sliceName = `${typePrefix}-preferences`;

interface State extends CommonState<Row> {
  layout: Layout[];
  serverLayout: Layout[];
}

const initialState: State = {
  loading: 'idle',
  error: null,
  rows: [],
  layout: [],
  serverLayout: [],
};

const slice = buildSlice<State>(sliceName, initialState, {
  extraReducers: (builder) => {
    builder.addCase(getLayout.fulfilled, (state, action) => {
      state.loading = 'fulfilled';
      const { layout } = action.payload.data;

      if (!layout) return;

      state.layout = layout;
      state.serverLayout = layout;
    });
  },
  customActions: {
    revertLayout(state) {
      state.layout = state.serverLayout;
    },
    setLayout(state, action) {
      state.layout = action.payload;
    },
  },
});

export const { clear, revertLayout, setLayout } = slice.actions;
export default slice.reducer;
