import React, { CSSProperties, ReactNode } from 'react';
import { useAppSelector } from 'hooks';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { dateInputFieldFormat } from '~libs/dayjs';
import { Appointment, Client, Worker, WorkerAvailability } from '~weekly-planner/lib/common';

import Spinner from '~components/Spinner';

import { selectRowLoading } from '~weekly-planner/selectors/unsaved';

import Cell from './Cell';

interface ComponentProps {
  headerCell: ReactNode;
  data: Worker | Client;
  days: Dayjs[];
  date: Dayjs;
  style?: CSSProperties;
  getAppointmentTitle: (appointment: Appointment) => string;
}

const Row: React.FC<ComponentProps> = ({ headerCell, data, days, date, style, getAppointmentTitle }) => {
  const loading = useAppSelector((state) => selectRowLoading(state, parseInt(data.id)));

  const getDayAvailability = (day: Dayjs) => {
    if (!('availability' in data) || !day || !data?.availability) return null;

    const dayName = day.format('dddd');
    return data?.availability[dayName as keyof WorkerAvailability] ?? {};
  };

  const className = classNames('worker-row', {});
  const isLoading = loading === 'pending';

  return (
    <div className={className}>
      {isLoading && <Spinner loading={isLoading} style={style} />}
      {headerCell}
      {days.map((day, index) => (
        <Cell
          style={{ ...style, gridColumn: index + 2 }}
          key={`${data.id}-${index}`}
          id={`${data.id}-${index}`}
          events={data.events[day.format(dateInputFieldFormat)]}
          availability={getDayAvailability(day)}
          getAppointmentTitle={getAppointmentTitle}
          date={day.format(dateInputFieldFormat)}
          startDate={date.weekday(0)}
        />
      ))}
    </div>
  );
};

export default Row;
