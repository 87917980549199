import { AuthTags } from '~constants/authTags';



import AlertBanners from '~dashboard/components/AlertBanners';
import { MapWrapper } from '~dashboard/components/map';
import Notifications from '~dashboard/components/Notifications';
import { ProspectStatistics } from '~dashboard/components/ProspectStatistics';
import Snapshots from '~dashboard/components/Snapshots';
import UpcomingAppointments from '~dashboard/components/UpcomingAppointments';
import WelcomeMessage from '~dashboard/components/WelcomeMessage';





export const widgets = [
  {
    id: 'alerts',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: AlertBanners,
  },
  {
    id: 'welcome',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: WelcomeMessage,
  },
  {
    id: 'notifications',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: Notifications,
  },
  {
    id: 'prospectStatistics',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: ProspectStatistics,
  },
  {
    id: 'upcoming',
    authTag: AuthTags.DASHBOARD.UPCOMINGAPPOINTMENTS.VIEW,
    component: UpcomingAppointments,
  },
  {
    id: 'dashboard',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: Snapshots,
  },

  {
    id: 'workerMap',
    authTag: AuthTags.DASHBOARD.VIEW,
    component: MapWrapper,
  },
];