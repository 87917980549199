import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import classNames from 'classnames';

import { WeeklyPlannerDateRangeOptions as RangeOptions } from '~constants/maps';

import { Appointment, getGridColumn } from '~weekly-planner/lib/common';
import { onUnallocatedDragEnd } from '~weekly-planner/lib/drag-drop';

import Button from '~components/Button';
import { DraggableItem, DroppableArea } from '~components/DragAndDrop';
import Icon, { faChevronLeft, faChevronRight, faMagnifyingGlass, faUserPen } from '~components/Icon';
import InfiniteScroll from '~components/InfiniteScroll';
import Spinner from '~components/Spinner';
import UnallocatedCard from '~weekly-planner/components/Card/UnallocatedCard';
import ConfirmBulkAssign from '~weekly-planner/components/Modal/ConfirmBulkAssign';
import Search from '~weekly-planner/components/SideBar/UnallocatedAppointments/Search';
import UnallocatedViewMenu from '~weekly-planner/components/SideBar/UnallocatedAppointments/UnallocatedViewMenu';

import { getAll as getClients } from '~main/actions/clients';
import { getAssignableUsers } from '~weekly-planner/actions/appointments';
import { getAll as getUnallocated } from '~weekly-planner/actions/appointments/unallocated';

import { clear, updateParams } from '~weekly-planner/reducers/appointments/unallocated';
import { focusAppointment, update } from '~weekly-planner/reducers/weeklyPlanner';

import { selectFocusedAppointment, selectParams as selectGlobalParams } from '~weekly-planner/selectors';
import { selectParams } from '~weekly-planner/selectors/appointments/unallocated';
import {
  selectSimulatedWorkerChanges as selectSimulatedChanges,
  selectUnallocated,
  selectUnallocatedCount,
} from '~weekly-planner/selectors/simulate';

interface Filters {
  client_id: number | null;
  user_id: number | string | null;
  suburb: string | null;
  date: string | null;
}

const UnallocatedAppointments: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(selectParams);
  const globalParams = useAppSelector(selectGlobalParams);

  const appointments = useAppSelector(selectUnallocated);
  const weeklyPlannerData = useAppSelector(selectSimulatedChanges);
  const focused = useAppSelector(selectFocusedAppointment);
  const count = useAppSelector(selectUnallocatedCount);

  const { week_start } = globalParams;
  const { limit } = params;

  const [openAppointment, setOpenAppointment] = useState<number | null>(null);
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    client_id: null,
    user_id: null,
    suburb: null,
    date: null,
  });
  const [isOver, setIsOver] = useState(false);
  const [isOrigin, setIsOrigin] = useState(false);
  const [isBulkAssignOpen, setBulkAssignOpen] = useState(false);

  useEffect(() => {
    // Clear all data on unmount
    return () => {
      dispatch(clear([]));
    };
  }, []);

  // Update unallocated params to include any date changes
  useEffect(() => {
    dispatch(
      updateParams({
        ...params,
        week_start,
      }),
    );
  }, [week_start]);

  // Refresh data on any param changes
  useEffect(() => {
    load();
  }, [params]);

  useMemo(() => {
    if (!focused && openAppointment) setOpenAppointment(null);
  }, [focused]);

  const handleDragOverChange = (isOver: boolean, current?: any) => {
    setIsOver(isOver);
    setIsOrigin(current?.containerId === 'unallocated-appointments');
  };

  const load = () => {
    if (params.week_start) dispatch(getUnallocated(params));
    dispatch(getClients());
  };

  const { suburb, ...remainingFilters } = filters;
  useEffect(() => {
    dispatch(
      updateParams({
        ...params,
        week_start,
        ['client.suburb']: {
          like: suburb ? `%${suburb}%` : undefined,
        },

        ...remainingFilters,
      }),
    );
  }, [filters]);

  const onUnallocatedAppointmentFocus = (key: number | null) => {
    if (key) {
      if (openAppointment === key) {
        dispatch(focusAppointment(null));
      } else {
        dispatch(getAssignableUsers({ id: key }));
        const appointment = appointments.find((appt) => appt.key === key);
        dispatch(
          focusAppointment({
            appointment,
            isAvailabilityView: true,
            isBiddingView: appointment?.is_bidding,
          }),
        );
        dispatch(update({ date: appointment?.date, rangeType: RangeOptions.DAILY }));
      }
      setOpenAppointment((prevKey) => (prevKey === key ? null : key));
    }
  };

  const renderAppointments = (appointments: Appointment[]) => {
    if (appointments.length > 0) {
      return appointments.map((appointment, index) => {
        const { key } = appointment;
        if (!key) return <></>;

        const style: CSSProperties = {
          gridRow: 1,
          ...getGridColumn(appointment.start_time, appointment.end_time, 1),
        };

        return (
          <div key={index} className="unallocated-row">
            <div className="unallocated-card-container">
              <DraggableItem
                id={key}
                data={{
                  appointment,
                  containerId: 'unallocated-appointments',
                  containerStyle: style,
                  onItemDragEnd: onUnallocatedDragEnd,
                  weeklyPlannerData,
                  week_start,
                  dispatch,
                }}
              >
                <UnallocatedCard
                  appointment={appointment}
                  isActionMenuOpen={openAppointment === appointment.key}
                  anyActionMenuOpen={!!openAppointment}
                />
              </DraggableItem>
            </div>
            <div className="action-menu-container">
              <div className="action-toggle" onClick={() => onUnallocatedAppointmentFocus(appointment.key)}>
                <Icon icon={openAppointment === appointment.key ? faChevronLeft : faChevronRight} />
              </div>
            </div>
          </div>
        );
      });
    }
    return <p className="no-result">No results found.</p>;
  };

  // Filter appointments using search filters

  const className = classNames('card-container unallocated-appointments inner-content limited', {
    'is-dragging-over': isOver,
    'is-dragging-origin': isOrigin,
  });

  const loader = <Spinner loading={true} className="p-5" />;
  const endMessage = <p className="no-result">No more unallocated appointments found.</p>;

  const onLoadMore = () => {
    const newLimit = limit + 30;
    dispatch(updateParams({ ...params, limit: newLimit }));
  };

  return (
    <>
      <ConfirmBulkAssign isOpen={isBulkAssignOpen} setIsOpen={setBulkAssignOpen} />
      <DroppableArea id="unallocated-appointments" onDragOverChange={handleDragOverChange} className={className}>
        <div className="nav card-header filter-menu ">
          <Button size="md" className="ms-2" type="submit" onClick={() => setSearchOpen(!isSearchOpen)}>
            <Icon icon={faMagnifyingGlass} className="me-2" title="Search Appointments" />
            Search
          </Button>
          <Button size="md" className="ms-2" type="submit" onClick={() => setBulkAssignOpen(true)}>
            <Icon icon={faUserPen} className="me-2" title="Assign Preferred Workers" />
            Bulk Assign
          </Button>
          <UnallocatedViewMenu />
        </div>
        <Search isOpen={isSearchOpen} weekStart={week_start} setFilters={setFilters} />
        <InfiniteScroll
          dataLength={appointments.length}
          loadMore={onLoadMore}
          hasMore={count !== appointments.length}
          loader={loader}
          endMessage={endMessage}
          scrollableTarget="unallocated-appointments"
        >
          {renderAppointments(appointments)}
        </InfiniteScroll>
      </DroppableArea>
    </>
  );
});

UnallocatedAppointments.displayName = 'UnallocatedAppointments';
export default UnallocatedAppointments;
