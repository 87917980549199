import React from 'react';
import { useLocation } from 'react-router-dom';

import Icon, { faExternalLink } from '~components/Icon';

import ContainerHeader from './ContainerHeader';

/**
 *  Redirect-To generic container.
 *  Will require a url-encoded link (ex: https%3A%2F%2Fgoogle.com  => instead of https://google.com)
 *  else routing may get broken. Use `encodeURIComponent(YOUR_URL)` to encode the url.
 */
const RedirectTo: React.FC = () => {
  const location = useLocation();

  return (
    <div className="p-2">
      <ContainerHeader>301 - User Redirection</ContainerHeader>
      <div className="d-flex flex-column align-items-center justify-content-center m-4">
        <div className="d-flex mb-2">
          <Icon icon={faExternalLink} size="2x" className="me-2" />
          <h4>You are about to navigate to a URL outside of our application.</h4>
        </div>
        <h5>Reference link: {location.pathname}</h5>
      </div>
    </div>
  );
};

export default RedirectTo;
