import {
  faBuildingUser,
  faBusinessTime,
  faBusSimple,
  faChalkboardUser,
  faCommentDollar,
  faFileContract,
  faFileInvoiceDollar,
  faFileLines,
  faGears,
  faPersonThroughWindow,
  faSun,
  faUsers,
  IconProp,
} from 'tsx/components/Icon';
import { AuthTags } from 'tsx/constants/authTags';

import { getTP1Urls } from './routes-tp1';

export type QuickMenuItem = {
  caption: string;
  link?: string;
  icon?: IconProp;
  iconTag?: string;
  children?: Array<QuickMenuItem>;
  authTag?: string;
  isFlyout?: boolean;
};

const quickMenus = (user: any, settings: any, companyDefaults: any): Array<QuickMenuItem> => {
  const tp1urls = getTP1Urls(user, settings, companyDefaults);
  return [
    {
      caption: 'Appointments',
      icon: faBusinessTime,
      iconTag: 'appointments',
      children: [
        {
          caption: 'Appointments >>',
          link: '/appointments',
          isFlyout: true,
          children: [
            {
              caption: 'View All',
              link: '/appointments',
              authTag: AuthTags.APPOINTMENTS.VIEWALL,
            },
            {
              caption: 'Add Appointment (once off)',
              link: '/appointments/new',
              authTag: AuthTags.APPOINTMENTS.UPDATE,
            },
            {
              caption: 'Add Support Coordination Entry',
              link: tp1urls.APPOINTMENTS.AddSupportCoordination,
              authTag: AuthTags.APPOINTMENTS.SUPPORTCOORDINATION.VIEW,
            },
            {
              caption: 'Add Leave Entry',
              link: tp1urls.APPOINTMENTS.AddLeaveEntry,
              authTag: AuthTags.APPOINTMENTS.LEAVEENTRIES.VIEW,
            },
            {
              caption: 'Add Repeat',
              link: '/appointments/repeats/new',
              authTag: AuthTags.APPOINTMENTS.UPDATE,
            },
            {
              caption: 'Add Transport Appointment',
              link: '/appointments/transport/new',
              authTag: AuthTags.APPOINTMENTS.TRANSPORT.VIEW,
            },
            {
              caption: 'Add Group Session',
              link: tp1urls.APPOINTMENTS.AddGroupSession,
              authTag: AuthTags.APPOINTMENTS.GROUPSESSIONS.VIEW,
            },
            {
              caption: 'Add Group Appointment',
              link: tp1urls.APPOINTMENTS.AddGroupAppointment,
              authTag: AuthTags.APPOINTMENTS.GROUPS.VIEW,
            },
            {
              caption: 'Add Group Repeat',
              link: tp1urls.APPOINTMENTS.AddGroupRepeat,
              authTag: AuthTags.APPOINTMENTS.GROUPS.VIEW,
            },
            {
              caption: 'View Group Appointments',
              link: tp1urls.APPOINTMENTS.ViewGroupAppointments,
              authTag: AuthTags.APPOINTMENTS.GROUPS.VIEW,
            },
          ],
        },
        { caption: 'Repeat Appointments', link: `/appointments/repeats` },
        { caption: 'Repeats Calendar', link: tp1urls.APPOINTMENTS.RepeatsCalendar },
        { caption: 'Overlaps', link: tp1urls.APPOINTMENTS.Overlaps },
        { caption: 'Compliance', link: tp1urls.APPOINTMENTS.Compliance },
        { caption: 'Timesheets (staff view)', link: tp1urls.APPOINTMENTS.Timesheets },
        { caption: 'Printable Schedule', link: tp1urls.APPOINTMENTS.PrintableSchedule },
        {
          caption: 'Email Schedules',
          link: tp1urls.APPOINTMENTS.EmailSchedule,
          authTag: AuthTags.APPOINTMENTS.EMAILSCHEDULES.VIEW,
        },
        {
          caption: 'Client Schedules',
          link: tp1urls.APPOINTMENTS.ClientSchedule,
          authTag: AuthTags.APPOINTMENTS.CLIENTSCHEDULES.VIEW,
        },
        { caption: 'Weekly Planner', link: tp1urls.APPOINTMENTS.WeeklyPlanner },
        { caption: 'Next Week', link: tp1urls.APPOINTMENTS.NextWeek },
        {
          caption: 'Home - Care Worker',
          link: tp1urls.APPOINTMENTS.CareworkerDashboard,
          authTag: AuthTags.DASHBOARDS.CAREWORKERS.VIEW,
        },
        {
          caption: 'Repeats VS Actuals',
          link: tp1urls.APPOINTMENTS.RepeatsVsActuals,
          authTag: AuthTags.APPOINTMENTS.REPORTS.REPEATVSACTUALS.VIEW,
        },
      ],
    },
    {
      caption: 'Budgets/Statements',
      icon: faFileLines,
      iconTag: 'budgets-statements',
      authTag: AuthTags.BUDGETS.VIEW,
      children: [
        {
          caption: 'Residential',
          link: tp1urls.BUDGETSSTATEMENTS.Residential,
          authTag: AuthTags.STATEMENTS.RESIDENTIAL.VIEW,
        },
        {
          caption: 'Home Care (IPA)',
          link: tp1urls.BUDGETSSTATEMENTS.HomeCareIPA,
          authTag: AuthTags.STATEMENTS.HOMECAREIPA.VIEW,
          children: [
            { caption: 'Claims', link: tp1urls.BUDGETSSTATEMENTS.Claims },
            { caption: 'ITF Balances', link: tp1urls.BUDGETSSTATEMENTS.ITFBalances },
            { caption: 'Pre-IPA Statements', link: tp1urls.BUDGETSSTATEMENTS.PreIPAStatements },
            { caption: 'Unspent Funds', link: tp1urls.BUDGETSSTATEMENTS.UnspentFunds },
            { caption: 'Low Balances', link: tp1urls.BUDGETSSTATEMENTS.LowBalances },
          ],
        },
        { caption: 'CHSP', link: tp1urls.BUDGETSSTATEMENTS.CHSP, authTag: AuthTags.STATEMENTS.CHSP.VIEW },
        { caption: 'HACC', link: tp1urls.BUDGETSSTATEMENTS.HACC, authTag: AuthTags.STATEMENTS.HACC.VIEW },
        { caption: 'NATSIFAC', link: tp1urls.BUDGETSSTATEMENTS.NATSIFAC, authTag: AuthTags.STATEMENTS.NATSIFAC.VIEW },
        { caption: 'NDIS', link: tp1urls.BUDGETSSTATEMENTS.NDIS, authTag: AuthTags.STATEMENTS.NDIS.VIEW },
        {
          caption: 'NDIS Quotes',
          link: tp1urls.BUDGETSSTATEMENTS.NDISQuotes,
          authTag: AuthTags.STATEMENTS.NDISQUOTES.VIEW,
        },
        {
          caption: 'NDIS Invoice Progress',
          link: tp1urls.BUDGETSSTATEMENTS.NDISInvoiceProgress,
          authTag: AuthTags.STATEMENTS.NDISINVOICES.VIEW,
        },
        {
          caption: 'Brokerage',
          link: tp1urls.BUDGETSSTATEMENTS.Brokerage,
          authTag: AuthTags.STATEMENTS.BROKERAGE.VIEW,
        },
        {
          caption: 'Fee For Service',
          link: tp1urls.BUDGETSSTATEMENTS.FeeForService,
          authTag: AuthTags.STATEMENTS.PRIVATE.VIEW,
        },
        {
          caption: 'CDC Res Budget',
          link: tp1urls.BUDGETSSTATEMENTS.CDCResBudget,
          authTag: AuthTags.STATEMENTS.RESIDENTIALCDCBUDGET.VIEW,
        },
        {
          caption: 'Statement Item Export',
          link: tp1urls.BUDGETSSTATEMENTS.StatementItemExport,
          authTag: AuthTags.STATEMENTS.ITEMEXPORT.VIEW,
        },
        { caption: 'Items - Grouped', link: tp1urls.BUDGETSSTATEMENTS.ItemsGrouped },
        { caption: 'Statement Archive', link: tp1urls.BUDGETSSTATEMENTS.StatementArchive },
        { caption: 'Statement Item Search', link: tp1urls.BUDGETSSTATEMENTS.StatementItemSearch },
        { caption: 'All Budgets', link: tp1urls.BUDGETSSTATEMENTS.AllBudgets },
      ],
    },
    {
      caption: 'Prospects',
      icon: faPersonThroughWindow,
      iconTag: 'prospects',
      authTag: AuthTags.PROSPECTS.VIEW,
      children: [
        { caption: 'Status By C.M', link: tp1urls.PROSPECTS.StatusByCM },
        { caption: 'Add Prospect', link: tp1urls.PROSPECTS.AddProspect },
        { caption: 'Follow-up Report', link: tp1urls.PROSPECTS.FollowUpReport },
        { caption: 'Note Count', link: tp1urls.PROSPECTS.NoteCount },
        { caption: 'Prospect Search', link: tp1urls.PROSPECTS.ProspectSearch },
        { caption: 'Exit Point', link: tp1urls.PROSPECTS.ExitPoint },
        { caption: 'Exit Reason', link: tp1urls.PROSPECTS.ExitReason },
      ],
    },
    {
      caption: 'Transport',
      icon: faBusSimple,
      iconTag: 'transport',
      authTag: AuthTags.TRANSPORT.VIEW,
      children: [
        { caption: 'Transport Appt', link: tp1urls.TRANSPORT.TransportAppointment },
        { caption: 'Group Trips', link: tp1urls.TRANSPORT.GroupTrips, authTag: AuthTags.TRANSPORT.TRIPS.VIEW },
        { caption: 'Trip Points', link: tp1urls.TRANSPORT.TripPoints },
      ],
    },
    {
      caption: 'Care Workers',
      icon: faUsers,
      iconTag: 'care-workers',
      authTag: AuthTags.USERS.VIEWALL,
      children: [
        { caption: 'Care Workers', link: tp1urls.CAREWORKERS.CareWorkers },
        {
          caption: 'Standard Availability',
          link: tp1urls.CAREWORKERS.StandardAvailability,
          authTag: AuthTags.USERS.AVAILABILITY.VIEW,
        },
        {
          caption: 'Availability Entries',
          link: tp1urls.CAREWORKERS.AvailabilityEntries,
          authTag: AuthTags.USERS.AVAILABILITY.VIEW,
        },
        { caption: 'Bulk SMS', link: tp1urls.CAREWORKERS.BulkSms, authTag: AuthTags.USERS.SMS.BULKSEND },
        { caption: 'User Admin', link: tp1urls.CAREWORKERS.UserAdmin, authTag: AuthTags.USERS.ADMIN.VIEW },
        {
          caption: 'Volunteer Matching',
          link: tp1urls.CAREWORKERS.VolunteerMatching,
          authTag: AuthTags.USERS.VOLUNTEERS.VIEW,
        },
        {
          caption: 'Volunteer Search',
          link: tp1urls.CAREWORKERS.VolunteerSearch,
          authTag: AuthTags.USERS.VOLUNTEERS.VIEW,
        },
        { caption: 'Teams', link: tp1urls.CAREWORKERS.Teams, authTag: AuthTags.USERS.TEAMS.VIEW },
      ],
    },
    {
      caption: 'Company CRM',
      icon: faBuildingUser,
      iconTag: 'company-crm',
      children: [
        { caption: 'Clients', link: tp1urls.COMPANYCRM.Clients },
        // { caption: 'Budgets', link: '/clients/budgets', authTag: AuthTags.CLIENTS.BUDGETS.VIEW },
        { caption: 'Add Note', link: tp1urls.COMPANYCRM.AddNote, authTag: AuthTags.COMPANY.CLIENTNOTES.VIEW },
        { caption: 'Notes Search', link: tp1urls.COMPANYCRM.NotesSearch, authTag: AuthTags.COMPANY.CLIENTNOTES.VIEW },
        {
          caption: 'On Hold Periods',
          link: tp1urls.COMPANYCRM.OnHoldPeriods,
          authTag: AuthTags.COMPANY.CLIENTHOLDPERIODS.VIEW,
        },
        { caption: 'Package Schedules', link: tp1urls.COMPANYCRM.PackageSchedules },
        { caption: 'Custom Forms', link: tp1urls.COMPANYCRM.CustomForms, authTag: AuthTags.COMPANY.CUSTOMFORMS.VIEW },
        { caption: 'Forms', link: tp1urls.COMPANYCRM.Forms, authTag: AuthTags.COMPANY.FORMS.VIEW },
        {
          caption: 'Incidents / Hazards',
          link: tp1urls.COMPANYCRM.IncidentsHazards,
          authTag: AuthTags.COMPANY.INCIDENTS.VIEW,
        },
        {
          caption: 'Bulk SMS - Clients',
          link: tp1urls.COMPANYCRM.BulkSmsClients,
          authTag: AuthTags.COMPANY.SMS.BULKSEND,
        },
        { caption: 'Portal Users', link: tp1urls.COMPANYCRM.PortalUsers, authTag: AuthTags.COMPANY.CLIENTPORTAL.VIEW },
        {
          caption: 'Portal Notifications',
          link: tp1urls.COMPANYCRM.PortalNotifications,
          authTag: AuthTags.COMPANY.CLIENTPORTAL.VIEW,
        },
        {
          caption: 'Portal Users List',
          link: tp1urls.COMPANYCRM.PortalUsersList,
          authTag: AuthTags.COMPANY.CLIENTPORTAL.VIEW,
        },
      ],
    },
    {
      caption: 'Leave Requests',
      icon: faSun,
      iconTag: 'leave-requests',
      authTag: AuthTags.LEAVEREQUESTS.VIEW,
      children: [
        { caption: 'New Request', link: tp1urls.LEAVEREQUESTS.NewRequest },
        { caption: 'Pending Requests', link: tp1urls.LEAVEREQUESTS.PendingRequests },
        { caption: 'All Requests', link: tp1urls.LEAVEREQUESTS.AllRequests },
      ],
    },
    {
      caption: 'Invoices',
      icon: faFileInvoiceDollar,
      iconTag: 'invoices',
      authTag: AuthTags.INVOICES.VIEW,
      children: [
        { caption: 'Invoice Export', link: tp1urls.INVOICES.InvoiceExport },
        { caption: 'Client Invoices', link: tp1urls.INVOICES.ClientInvoices },
        {
          caption: 'Supplier Invoices',
          link: tp1urls.INVOICES.SupplierInvoices,
          authTag: AuthTags.INVOICES.SUPPLIERS.VIEW,
        },
        { caption: 'Generate', link: tp1urls.INVOICES.SupplierGenerate, authTag: AuthTags.INVOICES.SUPPLIERS.VIEW },
        { caption: 'Suppliers', link: tp1urls.INVOICES.Suppliers, authTag: AuthTags.INVOICES.SUPPLIERS.VIEW },
        { caption: 'Appointment Status', link: tp1urls.INVOICES.AppointmentStatus },
        {
          caption: 'Supplier Invoices Workflow',
          link: tp1urls.INVOICES.SupplierInvoicesWorkflow,
          authTag: AuthTags.INVOICES.SUPPLIERSWORKFLOW.VIEW,
        },
        {
          caption: 'NDIS Invoices',
          link: tp1urls.INVOICES.NDISInvoices,
          authTag: AuthTags.INVOICES.NDIS.VIEW,
          children: [{ caption: 'Generate', link: tp1urls.INVOICES.GenerateNDISInvoices }],
        },
      ],
    },
    {
      caption: 'Payroll',
      icon: faCommentDollar,
      iconTag: 'payroll',
      authTag: AuthTags.PAYROLL.VIEW,
      children: [
        { caption: 'Payroll Export', link: tp1urls.PAYROLL.PayrollExport },
        { caption: 'Payroll Categories', link: tp1urls.PAYROLL.PayrollCategories },
        { caption: 'Pay Groups', link: tp1urls.PAYROLL.PayGroups },
        { caption: 'Groups - Custom', link: tp1urls.PAYROLL.GroupsCustom },
        { caption: 'Appointment Status', link: tp1urls.PAYROLL.AppointmentStatus },
        { caption: 'T-Connect', link: tp1urls.PAYROLL.TConnect, authTag: AuthTags.PAYROLL.TCONNECT.VIEW },
      ],
    },
    {
      caption: 'Documents',
      icon: faFileContract,
      iconTag: 'documents',
      authTag: AuthTags.DOCUMENTS.VIEW,
      children: [
        { caption: 'All Documents', link: tp1urls.DOCUMENTS.AllDocuments },
        { caption: 'New Document', link: tp1urls.DOCUMENTS.NewDocument },
        { caption: 'Folder Admin', link: tp1urls.DOCUMENTS.FolderAdmin },
      ],
    },
    {
      caption: 'Reports',
      icon: faChalkboardUser,
      iconTag: 'reports',
      authTag: AuthTags.REPORTS.VIEW,
      children: [
        { caption: 'COVID Vaccines', link: tp1urls.REPORTS.CovidVaccines },
        { caption: 'VHC Portal (DVA)', link: tp1urls.REPORTS.VHCPortal, authTag: AuthTags.REPORTS.DVA.VIEW },
        {
          caption: 'DVA Nursing (Medicare)',
          link: tp1urls.REPORTS.DVANursing,
          authTag: AuthTags.REPORTS.DVANURSING.VIEW,
        },
        { caption: 'HACC - PYP (Victoria)', link: tp1urls.REPORTS.HACCVIC, authTag: AuthTags.REPORTS.HACCVIC.VIEW },
        { caption: 'MDS Format (HACC)', link: tp1urls.REPORTS.MDSFormatHACC, authTag: AuthTags.REPORTS.HACC.VIEW },
        {
          caption: 'Allied Health (Medicare)',
          link: tp1urls.REPORTS.AlliedHealth,
          authTag: AuthTags.REPORTS.ALLIEDHEALTH.VIEW,
        },
        { caption: 'SOAP Notes', link: tp1urls.REPORTS.SOAPNotes, authTag: AuthTags.REPORTS.SOAP.VIEW },
        { caption: 'DEX (DSS)', link: tp1urls.REPORTS.DEX },
        { caption: 'ABF (WA)', link: tp1urls.REPORTS.ABF, authTag: AuthTags.REPORTS.ABF.VIEW },
        { caption: 'Document Checklist', link: tp1urls.REPORTS.DocumentChecklist },
        { caption: 'Photo Search', link: tp1urls.REPORTS.PhotoSearch },
        { caption: 'Schedules Chart', link: tp1urls.REPORTS.SchedulesChart },
        { caption: 'Time Variance Review', link: tp1urls.REPORTS.TimeVarianceReview },
        { caption: 'Travel Variance Review', link: tp1urls.REPORTS.TravelVarianceReview },
        { caption: 'Qualifications Review', link: tp1urls.REPORTS.QualificationsReview },
        { caption: 'System Activity', link: tp1urls.REPORTS.SystemActivity },
        { caption: 'Mail Merge', link: tp1urls.REPORTS.MailMerge },
        { caption: 'Client Review Report', link: tp1urls.REPORTS.ClientReviewReport },
        { caption: 'Client Preferences - All', link: tp1urls.REPORTS.ClientPreferencesAll },
        { caption: 'Grouped Summaries', link: tp1urls.REPORTS.GroupedSummaries },
      ],
    },
    {
      caption: 'Other / Config',
      icon: faGears,
      iconTag: 'other-config',
      authTag: AuthTags.SETTINGS.VIEW,
      children: [
        { caption: 'Tech Support', link: tp1urls.SETTINGSCONFIG.TechSupport },
        { caption: 'Events Admin', link: tp1urls.SETTINGSCONFIG.EventsAdmin, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        {
          caption: 'Billing Groups',
          link: tp1urls.SETTINGSCONFIG.BillingGroups,
          authTag: AuthTags.SETTINGS.ADMIN.VIEW,
        },
        {
          caption: 'Qualifications',
          link: tp1urls.SETTINGSCONFIG.Qualifications,
          authTag: AuthTags.SETTINGS.ADMIN.VIEW,
        },
        { caption: 'Task Types', link: tp1urls.SETTINGSCONFIG.TaskTypes, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Packages', link: tp1urls.SETTINGSCONFIG.Packages, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Service Types', link: tp1urls.SETTINGSCONFIG.ServiceTypes, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        {
          caption: 'Billing / Pay Rules',
          link: tp1urls.SETTINGSCONFIG.BillingPayRules,
          authTag: AuthTags.SETTINGS.ADMIN.VIEW,
        },
        { caption: 'Budget Items', link: tp1urls.SETTINGSCONFIG.BudgetItems, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Referrers', link: tp1urls.SETTINGSCONFIG.Referrers, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Stock Levels', link: tp1urls.SETTINGSCONFIG.StockLevels, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        { caption: 'Medications', link: tp1urls.SETTINGSCONFIG.Medications, authTag: AuthTags.SETTINGS.ADMIN.VIEW },
        {
          caption: 'DEX Configuration',
          link: tp1urls.SETTINGSCONFIG.DEXConfiguration,
          authTag: AuthTags.SETTINGS.DEX.VIEW,
        },
        {
          caption: 'DEX - All Clients',
          link: tp1urls.SETTINGSCONFIG.DEXAllClients,
          authTag: AuthTags.SETTINGS.DEX.VIEW,
        },
        { caption: 'Info Sheet - All Clients', link: tp1urls.SETTINGSCONFIG.InfoSheetAllClients },
        {
          caption: 'HACC - All Clients',
          link: tp1urls.SETTINGSCONFIG.HACCAllClients,
          authTag: AuthTags.SETTINGS.HACC.VIEW,
        },
        {
          caption: 'Bank Details - All Clients',
          link: tp1urls.SETTINGSCONFIG.BankDetailsAllClients,
          authTag: AuthTags.SETTINGS.BANKDETAILS.VIEW,
        },
        {
          caption: 'DVA Nursing Settings',
          link: tp1urls.SETTINGSCONFIG.DVANursingSettings,
          authTag: AuthTags.SETTINGS.DVANURSING.VIEW,
        },
        {
          caption: 'HR Code Matrix',
          link: tp1urls.SETTINGSCONFIG.HRCodeMatrix,
          authTag: AuthTags.SETTINGS.HRMATRIX.VIEW,
        },
        {
          caption: 'Billing Code Matrix',
          link: tp1urls.SETTINGSCONFIG.BillingCodeMatrix,
          authTag: AuthTags.SETTINGS.BILLINGMATRIX.VIEW,
        },
        {
          caption: 'Package Restrictions',
          link: tp1urls.SETTINGSCONFIG.PackageRestrictions,
          authTag: AuthTags.SETTINGS.PACKAGERESTRICTIONS.VIEW,
        },
        {
          caption: 'Accounts Locking',
          link: tp1urls.SETTINGSCONFIG.AccountsLock,
          authTag: AuthTags.SETTINGS.ACCOUNTLOCK.VIEW,
        },
        { caption: 'Notifications', link: tp1urls.SETTINGSCONFIG.Notifications },
        { caption: 'Process Guides', link: tp1urls.SETTINGSCONFIG.ProcessGuides },
        { caption: 'System Configuration', link: tp1urls.SETTINGSCONFIG.SystemConfiguration },
        { caption: 'Knowledge Base', link: tp1urls.SETTINGSCONFIG.KnowledgeBase },
        { caption: 'System Info', link: tp1urls.SETTINGSCONFIG.SystemInfo },
      ],
    },
  ];
};

export default quickMenus;
